
import { Component, Vue } from "vue-property-decorator";
import { version } from "../../../../package.json";
import { LOCAL_STORAGE_VERSION_KEY } from "@/constants/Constants";

@Component
export default class App extends Vue {
  isShown = false;
  appVersion = version;

  mounted(): void {
    const storedVersion = window.localStorage.getItem(
      LOCAL_STORAGE_VERSION_KEY
    );

    if (storedVersion?.substring(0, 3) !== this.appVersion?.substring(0, 3)) {
      this.isShown = true;
    }

    window.localStorage.setItem(LOCAL_STORAGE_VERSION_KEY, this.appVersion);
  }

  close(): void {
    this.isShown = false;
  }
}
