import { ExportableState } from "@/store/main/state";
import ISOCountry from "@/models/ISOCountry";
import NACESector from "@/models/NACESector";
import Activity from "@/models/activity/Activity";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import ScenarioConfig from "@/models/ScenarioConfig";
import Subscope from "@/models/Subscope";
import Gva from "@/models/activity/Gva";
import ScopeEmissions from "@/models/activity/ScopeEmissions";
import SubscopeEmissions from "@/models/activity/SubscopeEmissions";
import Strategy from "@/models/strategy/Strategy";
import YearRange from "@/models/strategy/YearRange";
import GVARate from "@/models/strategy/GvaRate";
import ScopeRate from "@/models/strategy/ScopeRate";
import SubscopeRate from "@/models/strategy/SubscopeRate";
import Provider from "@/models/Provider";
import SSP from "@/models/SSP";
import { PROVIDERS } from "@/constants/Providers";
import { SSPs } from "@/constants/SSPs";
import GrowthProjection from "@/models/strategy/GrowthProjection";
import { GrowthType } from "@/models/strategy/GrowthType";
import Extension from "@/models/Extension";
import { EXTENSION_2100 } from "@/constants/Extension2100s";
import { COUNTRIES } from "@/constants/ISOCountries";
import Config from "@/constants/Config";

/*
  This class exists because if you simply use JSON.parse() and a type
  you will get the correct object parsed, i.e. they respect the interface
  defined by the type.
  However, the object will only contain the values and no methods or functions
  which means they can only be used as DTOs.
  By doing the parsing by hand we guarantee that they have the necessary
  methods and are a full-fledged object.
 */
export default class SessionImportParser {
  static parseLoadedSessionObject(jsonString: string): ExportableState {
    const receivedState: ExportableState = JSON.parse(jsonString);

    const countryFormCode = COUNTRIES.filter(
      (country) => country.code === receivedState.country.code
    );

    const parsedCountry =
      countryFormCode.length === 0
        ? new ISOCountry(
            receivedState.country.fullName,
            receivedState.country.code
          )
        : countryFormCode[0];
    const parsedNACESector = new NACESector(
      receivedState.naceSector.code,
      receivedState.naceSector.name,
      receivedState.naceSector.isDisabled
    );

    const parsedProvider = this.getProvider(receivedState);

    const parsedSSP = this.getSSP(receivedState);

    const parsedExtension2100 = this.getExtension2100(receivedState);

    const parsedActivities = this.parseAllActivities(
      receivedState.activities,
      receivedState.baseYear
    );

    const parsedActivityBaseline = new BaselineXDC(
      receivedState.totalActivityXDC.name,
      receivedState.totalActivityXDC.xdcTotal,
      receivedState.totalActivityXDC.xdcScope1,
      receivedState.totalActivityXDC.xdcScope2,
      receivedState.totalActivityXDC.xdcScope3
    );

    const parsedScenarioConfigs = receivedState.scenarios.map(
      (s) => new ScenarioConfig(s.id, s.name, s.selectedActivityToStrategyMap)
    );

    // [SubScopeFeatureToggle] remove if subscopes not re-enabled
    const parsedSelectedSubScope1 = this.parseSelectedSubscopes(
      receivedState.selectedSubScopes1
    );
    const parsedSelectedSubScope2 = this.parseSelectedSubscopes(
      receivedState.selectedSubScopes2
    );
    const parsedSelectedSubScope3 = this.parseSelectedSubscopes(
      receivedState.selectedSubScopes3
    );

    return <ExportableState>{
      companyName: receivedState.companyName,
      baseYear: receivedState.baseYear,
      targetYear: receivedState.targetYear,
      country: parsedCountry,
      naceSector: parsedNACESector,
      provider: parsedProvider,
      ssp: parsedSSP,
      extension2100: parsedExtension2100,
      activities: parsedActivities,
      totalActivityXDC: parsedActivityBaseline,
      scenarios: parsedScenarioConfigs,
      // [SubScopeFeatureToggle] remove if subscopes not re-enabled
      selectedSubScopes1: parsedSelectedSubScope1,
      selectedSubScopes2: parsedSelectedSubScope2,
      selectedSubScopes3: parsedSelectedSubScope3,
    };
  }

  static getProvider(receivedState: ExportableState): Provider {
    if (receivedState.provider) {
      return new Provider(receivedState.provider.title);
    }

    // Return the default value (same as default in store)
    return PROVIDERS[0];
  }

  static getSSP(receivedState: ExportableState): SSP {
    if (receivedState.ssp) {
      const foundSSP = SSPs.find(
        (ssp: SSP) => ssp.name === receivedState.ssp.name
      );

      if (foundSSP) {
        return foundSSP;
      }
    }

    // Return the default value (same as default in store)
    return SSPs[2];
  }

  static getExtension2100(receivedState: ExportableState): Extension {
    if (receivedState.extension2100) {
      return new Extension(
        receivedState.extension2100.name,
        receivedState.extension2100.display
      );
    }

    // Return the default value (same as default in store)
    return EXTENSION_2100[2];
  }

  private static parseAllActivities(
    activities: Array<Activity>,
    baseYear: number
  ): Activity[] {
    return activities.map((activity) => {
      const gva = new Gva(
        this.sanitizeNull(activity.gva.ebitda),
        this.sanitizeNull(activity.gva.personnelCosts)
      );
      const scope1Emissions = this.parseScopeEmission(activity.scope1);
      const scope2Emissions = this.parseScopeEmission(activity.scope2);
      const scope3Emissions = this.parseScopeEmission(activity.scope3);
      const strategies = this.parseAllStrategies(activity.strategies);

      const cleanStrategies = this.cleanStrategies(baseYear, strategies);

      return new Activity(
        activity.id,
        activity.name,
        gva,
        scope1Emissions,
        scope2Emissions,
        scope3Emissions,
        cleanStrategies
      );
    });
  }

  private static parseScopeEmission(scope: ScopeEmissions): ScopeEmissions {
    // [SubScopeFeatureToggle] rewrite to only use total if subscopes not re-enabled
    const subscopes: SubscopeEmissions[] = scope.subscopes.map(
      (s) => new SubscopeEmissions(s.id, s.name, this.sanitizeNull(s.value))
    );

    return new ScopeEmissions(scope.other, subscopes);
  }

  private static parseAllStrategies(strategies: Array<Strategy>): Strategy[] {
    return strategies.map((strategy) => {
      const yearRanges = strategy.yearRanges.map(
        (yr) => new YearRange(yr.id, yr.startYear, yr.endYear)
      );
      const gvaRate = strategy.gvaRate.map(
        (gr) =>
          new GVARate(
            this.sanitizeNull(gr.initialEbitda),
            this.sanitizeNull(gr.ebitdaRate),
            this.sanitizeNull(gr.initialPersonnelCost),
            this.sanitizeNull(gr.personnelCostsRate)
          )
      );

      const scope1Rate = this.parseScopeRate(strategy.scope1Rate);
      const scope2Rate = this.parseScopeRate(strategy.scope2Rate);
      const scope3Rate = this.parseScopeRate(strategy.scope3Rate);

      const growthProjection: GrowthProjection[] = strategy.yearRanges.map(
        (yr, idx) =>
          new GrowthProjection(
            gvaRate[idx].ebitdaRate,
            GrowthType.PERCENTAGE,
            gvaRate[idx].personnelCostsRate,
            scope1Rate[idx].otherRate,
            scope2Rate[idx].otherRate,
            scope3Rate[idx].otherRate,
            yr.startYear - 1,
            yr.endYear
          )
      );

      return new Strategy(
        strategy.id,
        strategy.name,
        strategy.parentActivityId,
        yearRanges,
        gvaRate,
        scope1Rate,
        scope2Rate,
        scope3Rate,
        growthProjection
      );
    });
  }

  private static parseScopeRate(scopeRates: ScopeRate[]): ScopeRate[] {
    return scopeRates.map((sr) => {
      const subscopeRates = sr.subscopeRates.map(
        (ssr) =>
          new SubscopeRate(
            ssr.id,
            ssr.name,
            this.sanitizeNull(ssr.rateValue),
            this.sanitizeNull(ssr.initialEmissions)
          )
      );

      return new ScopeRate(
        this.sanitizeNull(sr.otherRate),
        this.sanitizeNull(sr.initialOtherEmissions),
        subscopeRates
      );
    });
  }

  // This is used to make sure the strategies are starting on the correct date according to the baseYear
  static cleanStrategies(baseYear: number, strategies: Strategy[]): Strategy[] {
    const strategyBaseYear = baseYear + 1;

    const strategiesWithWrongStartingBaseYear = strategies.filter(
      (s) => s.yearRanges[0].startYear !== strategyBaseYear
    );

    // If everything is correct we return early
    if (strategiesWithWrongStartingBaseYear.length === 0) {
      return strategies;
    }

    return strategies.map((strategy) => {
      // Initial range can fit the change without problem
      if (strategy.yearRanges[0].endYear >= strategyBaseYear) {
        strategy.yearRanges[0].startYear = strategyBaseYear;
      } else {
        // Range gets affected
        // Find first range which can be suitable and keep track of index
        // drop the equivalent on the other lists
        const idx = strategy.yearRanges.findIndex(
          (range) => range.endYear >= strategyBaseYear
        );

        if (idx !== -1) {
          strategy.yearRanges = strategy.yearRanges.splice(idx);
          strategy.yearRanges[0].startYear = strategyBaseYear;

          strategy.gvaRate = strategy.gvaRate.splice(idx);
          strategy.scope1Rate = strategy.scope1Rate.splice(idx);
          strategy.scope2Rate = strategy.scope2Rate.splice(idx);
          strategy.scope3Rate = strategy.scope3Rate.splice(idx);
        }
      }

      return strategy;
    });
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  private static parseSelectedSubscopes(
    selectedSubScopes: Array<Subscope>
  ): Subscope[] {
    if (Config.SubScopeFeatureToggle) {
      return selectedSubScopes.map((s) => new Subscope(s.id, s.name));
    }

    return [];
  }

  private static sanitizeNull(value: number): number {
    return value === null ? 0.0 : value;
  }
}
