var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "flex-grow-1" },
            [
              _c("div", { staticClass: "text-h5" }, [
                _vm._v("Business Activity Trajectories"),
              ]),
              _c("Assumptions"),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex reload flex-grow-1" },
            [
              _vm._v(" Absolute Value "),
              _c("v-switch", {
                staticClass: "switch",
                attrs: { inset: "", label: "Relative Value" },
                model: {
                  value: _vm.percentMode,
                  callback: function ($$v) {
                    _vm.percentMode = $$v
                  },
                  expression: "percentMode",
                },
              }),
              _c("ReloadButton", {
                attrs: {
                  type: "curves",
                  "reload-tooltip-text": "Reload trajectories",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.selectedCurvesGroup,
                    callback: function ($$v) {
                      _vm.selectedCurvesGroup = $$v
                    },
                    expression: "selectedCurvesGroup",
                  },
                },
                _vm._l(_vm.curvesGroups, function (group) {
                  return _c(
                    "v-tab",
                    {
                      key: `${_vm.activityName(
                        group[0].parentActivityId
                      )}-group`,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.activityName(group[0].parentActivityId)) +
                          " "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.selectedCurvesGroup,
                    callback: function ($$v) {
                      _vm.selectedCurvesGroup = $$v
                    },
                    expression: "selectedCurvesGroup",
                  },
                },
                _vm._l(_vm.curvesGroups, function (group) {
                  return _c(
                    "v-tab-item",
                    {
                      key: `${_vm.activityName(
                        group[0].parentActivityId
                      )}-group`,
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "scrollable curve-table-wrapper" },
                        _vm._l(group, function (strategyGroup) {
                          return _c("TrajectoriesSelectionTable", {
                            key: `${_vm.activityName(
                              strategyGroup.parentActivityId
                            )}${strategyGroup.parentStrategyId}-strategy`,
                            attrs: { "curve-group": strategyGroup },
                          })
                        }),
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: true,
            value:
              _vm.isNaceFilled ||
              _vm.isLoadingCurves ||
              _vm.hasBigEmissionsError,
          },
        },
        [
          _vm.isNaceFilled
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.HEADING) + " "),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm.isLoadingCurves
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [_vm._v("Loading all curves...")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "progress-info" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "text progress-info-text" },
                                [
                                  _vm._v(" Getting another "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.curveFetchCount)),
                                  ]),
                                  _vm._v(
                                    " curve " +
                                      _vm._s(
                                        _vm.curveFetchCount === 1
                                          ? "group"
                                          : "groups"
                                      ) +
                                      " for you "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.hasBigEmissionsError
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "Business Activity Trajectories could not be calculated for the given input"
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.BIG_EMISSIONS.PART1) + " "),
                    _c("br"),
                    _vm._v(" > " + _vm._s(_vm.BIG_EMISSIONS.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }