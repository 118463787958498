
import { Component, Prop, Vue } from "vue-property-decorator";
import SessionItem from "@/models/session/SessionItem";
import LocaleSetting from "@/models/preferences/LocaleSetting";

@Component
export default class SessionItemEntry extends Vue {
  @Prop({ required: true }) item!: SessionItem;

  showMenu = false;
  x = 0;
  y = 0;

  showContextMenu(e: MouseEvent): void {
    e.preventDefault();

    if (this.currentSessionId !== this.item.sessionId) {
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }

  clickHandler(ev: Event): void {
    this.$emit("click", ev);
  }

  clickDelete(ev: Event): void {
    this.$emit("delete", ev);
  }

  clickExport(ev: Event): void {
    this.$emit("export", ev);
  }

  get currentSessionId(): string {
    return this.$store.state.sessionStore.currentSessionId;
  }

  get currentLocale(): LocaleSetting {
    return this.$store.state.configStore.locale;
  }

  formatDate(dateString: string): string {
    return new Date(dateString).toLocaleString(this.currentLocale.shortCode);
  }
}
