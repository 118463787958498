
import { Component, Vue } from "vue-property-decorator";
import LoginRequest from "@/auth/models/LoginRequest";
import AuthGateWay from "@/auth/services/AuthGateway";
import { mixins } from "vue-class-component";
import CheckEmailMixin from "@/auth/components/CheckEmailMixin";

@Component
export default class LoginPage extends mixins(Vue, CheckEmailMixin) {
  password = "";
  valid = true;

  get errorMessage(): string {
    return this.$store.state.routeStore.errorMessage;
  }

  hasErrorMessage(): boolean {
    return this.$store.state.routeStore.errorMessage !== "";
  }

  passwordRequired(): boolean | string {
    return this.password !== "" || "Field Required";
  }

  formOk(): void {
    const allValid = (
      this.$refs.form as Vue & {
        validate: () => boolean;
      }
    ).validate();

    if (allValid) {
      this.login();
    }
  }

  login(): void {
    this.$store.commit("routeStore/setErrorMessage", "");
    this.$store.dispatch(
      "auth/login",
      new LoginRequest(
        this.email,
        this.password,
        AuthGateWay.AUTH_APPLICATION_ID
      )
    );
  }
}
