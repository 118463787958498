
import { Component, Prop, Vue } from "vue-property-decorator";
import Activity from "@/models/activity/Activity";
import SubscopeEmissions from "@/models/activity/SubscopeEmissions";
import XDCValueDisplay from "@/pages/shared/components/XDCValueDisplay.vue";
import NumericField from "@/pages/company/components/NumericField.vue";

@Component({
  components: { NumericField, XDCValueDisplay },
})
export default class ActivityInfoRow extends Vue {
  @Prop({ required: true }) activity!: Activity;
  @Prop({ required: true }) listIndex!: number;
  showMenu = false;
  x = 0;
  y = 0;

  showContextMenu(e: MouseEvent): void {
    e.preventDefault();
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }

  recalculateXDC(): void {
    this.$store.dispatch(
      "recalculateActivityStrategiesInitialValues",
      this.activity
    );
    this.$store.dispatch("makeXDCRequest", [this.activity]);
    this.$store.commit("curvesNeedReload", true);

    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("sessionStore/saveSession");
    }
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope1Subscopes(): SubscopeEmissions[] {
    return this.activity.scope1.subscopes;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope2Subscopes(): SubscopeEmissions[] {
    return this.activity.scope2.subscopes;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope3Subscopes(): SubscopeEmissions[] {
    return this.activity.scope3.subscopes;
  }

  deleteThisActivity(): void {
    this.$store.commit("removeActivity", this.activity);
    this.$store.dispatch("makeXDCRequest", []);
    this.$store.commit("curvesNeedReload", true);
  }

  copyThisActivity(): void {
    this.$store.commit("copyActivity", this.activity);
    this.$store.dispatch("makeXDCRequest", []);
    this.$store.commit("curvesNeedReload", true);
  }
}
