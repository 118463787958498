var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v("Basic Information"),
            ]),
          ]),
        ],
        1
      ),
      _c("BasicInformation"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v("Business Activities"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _vm.subScopeSelectionFeatureToggle
                ? _c("SubscopeSelection")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", { staticClass: "scrollable" }, [_c("ActivityInfoTable")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }