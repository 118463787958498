import ConfigStoreState from "@/store/configuration/ConfigStoreState";
import LocaleSetting from "@/models/preferences/LocaleSetting";
import { SUPPORTED_LOCALES } from "@/constants/Locales";
import SettingsGateway from "@/services/SettingsGateway";

export default {
  namespaced: true,
  state: {
    engineVersion: "",
    locale: SUPPORTED_LOCALES[0],
    decimalSeparator: ",",
    separator: ".",
    autoSave: false,
  },
  mutations: {
    setLocale(state: ConfigStoreState, locale: LocaleSetting): void {
      state.locale = locale;
      state.decimalSeparator = locale.decimalSeparator;
      state.separator = locale.separator;
    },
    setAutoSave(state: ConfigStoreState, autoSave: boolean): void {
      state.autoSave = autoSave;
    },
    setEngineVersion(state: ConfigStoreState, version: string): void {
      state.engineVersion = version;
    },
    setLoadedState(state: ConfigStoreState, newState: ConfigStoreState): void {
      Object.assign(state, newState);

      // So that it will be correctly displayed
      const locale = SUPPORTED_LOCALES.find(
        (l) => l.shortCode === newState.locale.shortCode
      );

      if (locale) {
        state.locale = locale;
      }
    },
  },
  actions: {
    getXDCVersion({ commit }: { commit: any }): void {
      SettingsGateway.getXDCVersion().then((version) =>
        commit("setEngineVersion", version)
      );
    },
    saveConfig({ state }: { state: ConfigStoreState }): void {
      const jsonState = JSON.stringify(state);
      SettingsGateway.saveConfig(jsonState).then();
    },
    loadConfig({ commit }: { commit: any }): void {
      SettingsGateway.loadConfig().then((jsonState) => {
        if (jsonState !== "") {
          commit("setLoadedState", JSON.parse(jsonState));
        }
      });
    },
  },
};
