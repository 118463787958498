import SSP from "@/models/SSP";

export const SSPs: SSP[] = [
  new SSP("SSP1_BASELINE", "SSP1", "Sustainability (Taking the Green Road)"),
  new SSP("SSP2_BASELINE", "SSP2", "Middle of the Road (Baseline)"),
  new SSP("SSP2_RCP60", "SSP2 RCP60", "Middle of the Road (RCP60)"),
  new SSP("SSP3_BASELINE", "SSP3", "Regional Rivalry (A Rocky Road)"),
  new SSP("SSP4_BASELINE", "SSP4", "Inequality (A Road divided)"),
  new SSP(
    "SSP5_BASELINE",
    "SSP5",
    "Fossil-fueled Development (Taking the Highway)"
  ),
];
