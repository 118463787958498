var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "tr",
    {
      class: _vm.currentSessionId === _vm.item.sessionId ? "current" : "",
      on: { click: _vm.clickHandler, contextmenu: _vm.showContextMenu },
    },
    [
      _c("td", [
        _vm.currentSessionId === _vm.item.sessionId
          ? _c("span", { staticClass: "dot" })
          : _vm._e(),
        _vm._v(_vm._s(_vm.item.companyName) + " "),
      ]),
      _c("td", [_vm._v(_vm._s(_vm.formatDate(_vm.item.createdAt)))]),
      _c("td", [_vm._v(_vm._s(_vm.formatDate(_vm.item.modifiedAt)))]),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.x,
            "position-y": _vm.y,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.clickExport } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-file-export")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Export Session")]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.clickDelete } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-delete")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Delete Session")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }