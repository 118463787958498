import BaselineXDC from "@/models/xdc/BaselineXDC";
import GVARate from "@/models/strategy/GvaRate";
import ScopeRate from "@/models/strategy/ScopeRate";
import YearRange from "@/models/strategy/YearRange";
import Subscope from "@/models/Subscope";
import Activity from "@/models/activity/Activity";
import { cloneDeep } from "lodash";
import GrowthProjection from "@/models/strategy/GrowthProjection";
import { GrowthType } from "@/models/strategy/GrowthType";

export default class Strategy {
  id: number;
  name: string;
  parentActivityId: number;
  yearRanges: YearRange[];
  gvaRate: GVARate[];
  scope1Rate: ScopeRate[];
  scope2Rate: ScopeRate[];
  scope3Rate: ScopeRate[];
  growthProjections: GrowthProjection[];
  baselineXDC: BaselineXDC;

  constructor(
    id: number,
    name: string,
    parentActivityId: number,
    yearRanges: YearRange[],
    gvaRate: GVARate[],
    scope1Rate: ScopeRate[],
    scope2Rate: ScopeRate[],
    scope3Rate: ScopeRate[],
    growthProjection: GrowthProjection[]
  ) {
    this.id = id;
    this.name = name;
    this.parentActivityId = parentActivityId;
    this.yearRanges = yearRanges;
    this.gvaRate = gvaRate;
    this.scope1Rate = scope1Rate;
    this.scope2Rate = scope2Rate;
    this.scope3Rate = scope3Rate;
    this.growthProjections = growthProjection;
    this.baselineXDC = new BaselineXDC();
  }

  addScope1Subscope(toBeAdded: Subscope): void {
    this.scope1Rate.forEach((sr) => sr.addSubscope(toBeAdded));
  }

  addScope2Subscope(toBeAdded: Subscope): void {
    this.scope2Rate.forEach((sr) => sr.addSubscope(toBeAdded));
  }

  addScope3Subscope(toBeAdded: Subscope): void {
    this.scope3Rate.forEach((sr) => sr.addSubscope(toBeAdded));
  }

  removeScope1Subscope(toBeRemoved: Subscope): void {
    this.scope1Rate.forEach((sr) => sr.removeSubscope(toBeRemoved));
  }

  removeScope2Subscope(toBeRemoved: Subscope): void {
    this.scope2Rate.forEach((sr) => sr.removeSubscope(toBeRemoved));
  }

  removeScope3Subscope(toBeRemoved: Subscope): void {
    this.scope3Rate.forEach((sr) => sr.removeSubscope(toBeRemoved));
  }

  addNewRange(range: YearRange): void {
    const lastIdx = this.gvaRate.length - 1;
    this.yearRanges.push(range);
    this.gvaRate.push(cloneDeep(this.gvaRate[lastIdx]));
    this.scope1Rate.push(cloneDeep(this.scope1Rate[lastIdx]));
    this.scope2Rate.push(cloneDeep(this.scope2Rate[lastIdx]));
    this.scope3Rate.push(cloneDeep(this.scope3Rate[lastIdx]));
    this.growthProjections.push(cloneDeep(this.growthProjections[lastIdx]));
  }

  addExistingRange(oldData: Strategy, idx: number, range: YearRange): void {
    this.yearRanges.push(range);
    this.gvaRate.push(oldData.gvaRate[idx]);
    this.scope1Rate.push(oldData.scope1Rate[idx]);
    this.scope2Rate.push(oldData.scope2Rate[idx]);
    this.scope3Rate.push(oldData.scope3Rate[idx]);
    this.growthProjections.push(oldData.growthProjections[idx]);
  }

  recalculateInitialValues(activity: Activity): void {
    for (let idx = 0; idx < this.yearRanges.length; idx++) {
      if (idx === 0) {
        this.gvaRate[idx].initialEbitda = activity.gva.ebitda;
        this.gvaRate[idx].initialPersonnelCost = activity.gva.personnelCosts;

        this.scope1Rate[idx].initialOtherEmissions = activity.scope1.other;
        for (let i = 0; i < this.scope1Rate[idx].subscopeRates.length; i++) {
          const subscopeRate = this.scope1Rate[idx].subscopeRates[i];
          subscopeRate.initialEmissions = activity.scope1.getSubscopeValue(
            subscopeRate.id
          );
        }

        this.scope2Rate[idx].initialOtherEmissions = activity.scope2.other;
        for (let i = 0; i < this.scope2Rate[idx].subscopeRates.length; i++) {
          const subscopeRate = this.scope2Rate[idx].subscopeRates[i];
          subscopeRate.initialEmissions = activity.scope2.getSubscopeValue(
            subscopeRate.id
          );
        }

        this.scope3Rate[idx].initialOtherEmissions = activity.scope3.other;
        for (let i = 0; i < this.scope3Rate[idx].subscopeRates.length; i++) {
          const subscopeRate = this.scope3Rate[idx].subscopeRates[i];
          subscopeRate.initialEmissions = activity.scope3.getSubscopeValue(
            subscopeRate.id
          );
        }
      } else {
        const numberYears = this.yearRanges[idx - 1].numberOfYears();
        this.gvaRate[idx].initialEbitda =
          this.gvaRate[idx - 1].calculateTargetEbitda(numberYears);
        this.gvaRate[idx].initialPersonnelCost =
          this.gvaRate[idx - 1].calculateTargetPersonnelCost(numberYears);

        this.scope1Rate[idx].initialOtherEmissions =
          this.scope1Rate[idx - 1].calculateTargetOtherRate(numberYears);
        for (let i = 0; i < this.scope1Rate[idx].subscopeRates.length; i++) {
          const lastSubscopeRate = this.scope1Rate[idx - 1].subscopeRates[i];
          const thisSubscopeRate = this.scope1Rate[idx].subscopeRates[i];

          thisSubscopeRate.initialEmissions =
            lastSubscopeRate.calculateTargetEmission(numberYears);
        }

        this.scope2Rate[idx].initialOtherEmissions =
          this.scope2Rate[idx - 1].calculateTargetOtherRate(numberYears);
        for (let i = 0; i < this.scope2Rate[idx].subscopeRates.length; i++) {
          const lastSubscopeRate = this.scope2Rate[idx - 1].subscopeRates[i];
          const thisSubscopeRate = this.scope2Rate[idx].subscopeRates[i];

          thisSubscopeRate.initialEmissions =
            lastSubscopeRate.calculateTargetEmission(numberYears);
        }

        this.scope3Rate[idx].initialOtherEmissions =
          this.scope3Rate[idx - 1].calculateTargetOtherRate(numberYears);
        for (let i = 0; i < this.scope3Rate[idx].subscopeRates.length; i++) {
          const lastSubscopeRate = this.scope3Rate[idx - 1].subscopeRates[i];
          const thisSubscopeRate = this.scope3Rate[idx].subscopeRates[i];

          thisSubscopeRate.initialEmissions =
            lastSubscopeRate.calculateTargetEmission(numberYears);
        }
      }

      // for idx = 0 GrowthProjection needs to start with the base year
      // for all idx > 0, the subsequent start year needs to match the previous end year
      this.growthProjections[idx] = new GrowthProjection(
        this.gvaRate[idx].ebitdaRate,
        GrowthType.PERCENTAGE,
        this.gvaRate[idx].personnelCostsRate,
        this.scope1Rate[idx].otherRate,
        this.scope2Rate[idx].otherRate,
        this.scope3Rate[idx].otherRate,
        this.yearRanges[idx].startYear - 1,
        this.yearRanges[idx].endYear
      );
    }
  }

  ebitdaGrowthRates(): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.gvaRate[idx].ebitdaRate;

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  personnelCostsGrowthRates(): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.gvaRate[idx].personnelCostsRate;

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope1OtherGrowthRates(): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope1Rate[idx].otherRate;

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope1SubscopeGrowthRates(subscopeId: string): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope1Rate[idx].getSubscopeValue(subscopeId);

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope2OtherGrowthRates(): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope2Rate[idx].otherRate;

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope2SubscopeGrowthRates(subscopeId: string): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope2Rate[idx].getSubscopeValue(subscopeId);

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope3OtherGrowthRates(): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope3Rate[idx].otherRate;

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }

  scope3SubscopeGrowthRates(subscopeId: string): number[] {
    const growths: number[] = [];

    this.yearRanges.forEach((range, idx) => {
      const yearCount = range.numberOfYears();

      const rate = this.scope3Rate[idx].getSubscopeValue(subscopeId);

      for (let i = 0; i < yearCount; i++) {
        growths.push(rate);
      }
    });

    return growths;
  }
}
