import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { ALL_ROLES } from "@/constants/Roles";
import { store } from "@/store/main";
import TokenStorage from "@/auth/services/TokenStorage";
import ScenarioPage from "@/pages/scenario/ScenarioPage.vue";
import EmissionsAndGvaPage from "@/pages/emissionsAndGva/EmissionsAndGvaPage.vue";
import PathwaysPage from "@/pages/pathways/PathwaysPage.vue";
import XDCsPage from "@/pages/xdcs/XDCsPage.vue";
import DataPage from "@/pages/data/DataPage.vue";
import SessionsPage from "@/pages/sessions/SessionsPage.vue";
import PreferencesPage from "@/pages/preferences/PreferencesPage.vue";
import FAQPage from "@/pages/faq/FAQPage.vue";
import LoginPage from "@/auth/pages/LoginPage.vue";
import ChangePasswordPage from "@/auth/pages/ChangePasswordPage.vue";
import ForgotPasswordPage from "@/auth/pages/ForgotPasswordPage.vue";
import CompanyPage from "@/pages/company/CompanyPage.vue";
import StrategyPage from "@/pages/strategy/StrategyPage.vue";
import {
  ChangePasswordRoute,
  CompanyRoute,
  DataRoute,
  EmissionAndGvaRoute,
  FallbackRoute,
  FAQRoute,
  ForgotPasswordRoute,
  LoginRoute,
  PathwaysRoute,
  PreferencesRoute,
  ScenarioRoute,
  SessionsRoute,
  StrategyRoute,
  UpdatePasswordRoute,
  XDCRoute,
} from "@/router/Routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: CompanyRoute.path,
    name: CompanyRoute.name,
    component: CompanyPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: StrategyRoute.path,
    name: StrategyRoute.name,
    component: StrategyPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: ScenarioRoute.path,
    name: ScenarioRoute.name,
    component: ScenarioPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: EmissionAndGvaRoute.path,
    name: EmissionAndGvaRoute.name,
    component: EmissionsAndGvaPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: PathwaysRoute.path,
    name: PathwaysRoute.name,
    component: PathwaysPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: XDCRoute.path,
    name: XDCRoute.name,
    component: XDCsPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: DataRoute.path,
    name: DataRoute.name,
    component: DataPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: SessionsRoute.path,
    name: SessionsRoute.name,
    component: SessionsPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: PreferencesRoute.path,
    name: PreferencesRoute.name,
    component: PreferencesPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: FAQRoute.path,
    name: FAQRoute.name,
    component: FAQPage,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
  {
    path: LoginRoute.path,
    name: LoginRoute.name,
    component: LoginPage,
  },
  {
    path: ChangePasswordRoute.path,
    name: ChangePasswordRoute.name,
    component: ChangePasswordPage,
  },
  {
    path: UpdatePasswordRoute.path,
    name: UpdatePasswordRoute.name,
    component: ChangePasswordPage,
  },
  {
    path: ForgotPasswordRoute.path,
    name: ForgotPasswordRoute.name,
    component: ForgotPasswordPage,
  },
  {
    path: FallbackRoute.path,
    redirect: CompanyRoute.path,
    meta: {
      requiresAuth: true,
      roles: ALL_ROLES,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  store
    .dispatch("auth/getAuth")
    .then(() => {
      to.matched.some((record) => {
        const requiredRolesForRoute = record.meta.roles;

        if (
          (record.meta && record.meta.roles == []) ||
          TokenStorage.hasRequiredRole(requiredRolesForRoute)
        ) {
          store.commit("routeStore/setPreviousRoute", from.path);
          next();
        } else {
          next({ name: "Login" });
        }
      });
    })
    .catch(() => next({ name: "Login" }));
});

export default router;
