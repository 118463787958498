import Vue from "vue";
import Vuex from "vuex";
import { authStoreModule } from "@/store/auth/auth";

import routeStore from "../route/routeStore";
import { mutations } from "@/store/main/mutations";
import { state } from "@/store/main/state";
import { actions } from "@/store/main/actions";
import configStore from "@/store/configuration/configStore";
import { getters } from "@/store/main/getters";
import sessionStore from "@/store/session/sessionStore";

Vue.use(Vuex);

export const storeOptions = {
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth: authStoreModule,
    routeStore,
    configStore,
    sessionStore,
  },
};

export const store = new Vuex.Store(storeOptions);
