var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "text-h5" }, [
                _vm._v("XDC of Pathways"),
              ]),
              _c("Assumptions"),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex reload" },
            [
              _c("ReloadButton", {
                attrs: { type: "xdc", reloadTooltipText: "Reload XDCs" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Name")]),
                  _c("th", { attrs: { colspan: "4" } }, [_vm._v("XDC")]),
                ]),
                _c("tr", { staticClass: "xdc-row" }, [
                  _c("th"),
                  _c("th", [_vm._v("Total")]),
                  _c("th", [_vm._v("Scope1")]),
                  _c("th", [_vm._v("Scope2")]),
                  _c("th", [_vm._v("Scope3")]),
                ]),
              ]),
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "section-title", attrs: { colspan: "5" } },
                      [_vm._v("Scenario")]
                    ),
                  ]),
                  _vm._l(_vm.allScenarioXDCs, function (xdcSet, idx) {
                    return _c(
                      "tr",
                      { key: `${idx}-scenario`, staticClass: "xdc-row" },
                      [
                        _c("td", [_vm._v(_vm._s(xdcSet.name))]),
                        _c(
                          "td",
                          [
                            _c("XDCValueDisplay", {
                              attrs: { "xdc-value": xdcSet.xdcTotal },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("XDCValueDisplay", {
                              attrs: { "xdc-value": xdcSet.xdcScope1 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("XDCValueDisplay", {
                              attrs: { "xdc-value": xdcSet.xdcScope2 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("XDCValueDisplay", {
                              attrs: { "xdc-value": xdcSet.xdcScope3 },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: true,
            value:
              _vm.isNaceFilled ||
              _vm.isLoadingCurves ||
              _vm.hasError ||
              _vm.hasServerError,
          },
        },
        [
          _vm.isNaceFilled
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.HEADING)),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm.isLoadingCurves
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [_vm._v("Loading data...")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "progress-info" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "text progress-info-text" },
                                [
                                  _vm._v(" Getting another "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.xdcFetchCount)),
                                  ]),
                                  _vm._v(
                                    " set of " +
                                      _vm._s(
                                        _vm.xdcFetchCount === 1 ? "XDC" : "XDCs"
                                      ) +
                                      " for you "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.hasServerError
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.SERVER_ERROR.HEADING) + " "),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.SERVER_ERROR.PART1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.SERVER_ERROR.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm.hasError
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      " XDCs could not be calculated for the given input "
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " We are sorry, something went wrong during the XDC calculation. "
                    ),
                    _c("br"),
                    _vm._v(" Please try again later or contact us. "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }