import jwt from "jsonwebtoken";

export default class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = "pmt-jwt";
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = "pmt-refreshToken";

  public static isTokenValid(token: string | null): boolean {
    if (token) {
      const tokenDecoded = jwt.decode(token);

      return tokenDecoded && tokenDecoded.exp * 1000 > new Date().getTime();
    }

    return false;
  }

  public static isAuthenticated(): boolean {
    return this.getToken() !== null && this.isTokenValid(this.getToken());
  }

  public static hasRequiredRole(requiredRoles: Array<string> | null): boolean {
    if (requiredRoles) {
      const userRoles = this.getRoles();

      return userRoles.some((role) => {
        return requiredRoles.includes(role);
      });
    } else {
      return true;
    }
  }

  public static getRoles(): Array<string> {
    if (this.isAuthenticated()) {
      const tokenDecoded = jwt.decode(this.getToken());

      return tokenDecoded && tokenDecoded.roles;
    } else {
      return [];
    }
  }

  public static getRefreshHeader(): Record<string, any> {
    return { refreshToken: this.getRefreshToken() };
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
