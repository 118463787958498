
import { Component, Vue } from "vue-property-decorator";
import GroupedXDC from "@/models/xdc/GroupedXDC";
import XDCValueDisplay from "@/pages/shared/components/XDCValueDisplay.vue";
import ReloadButton from "@/pages/shared/components/ReloadButton.vue";
import FetchCurvesMixin from "@/mixins/FetchCurvesMixin";
import { mixins } from "vue-class-component";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";
import { MISSING_INFORMATION, SERVER_ERROR } from "@/constants/ErrorMessages";
import { ERROR, SERVER_NOT_AVAILABLE } from "@/constants/RequestStatus";

@Component({
  computed: {
    SERVER_ERROR() {
      return SERVER_ERROR;
    },
    MISSING_INFORMATION() {
      return MISSING_INFORMATION;
    },
  },
  components: { Assumptions, XDCValueDisplay, ReloadButton },
})
export default class XDCsPage extends mixins(Vue, FetchCurvesMixin) {
  get hasError(): boolean {
    return this.allScenarioXDCs.some((xdc) => xdc.status === ERROR);
  }

  get hasServerError(): boolean {
    return this.allScenarioXDCs.some(
      (xdc) => xdc.status === SERVER_NOT_AVAILABLE
    );
  }

  get allScenarioXDCs(): GroupedXDC[] {
    let scenarioXDCs: GroupedXDC[] = [];

    // baseline scenario
    const baselineXDC: BaselineXDC = this.$store.state.totalActivityXDC;
    const baselineScenarioXdc = new GroupedXDC(
      "Company Baseline",
      baselineXDC.xdcTotal,
      baselineXDC.xdcScope1,
      baselineXDC.xdcScope2,
      baselineXDC.xdcScope3,
      baselineXDC.status,
      true,
      true,
      false
    );
    scenarioXDCs.push(baselineScenarioXdc);

    // all user-defined scenario xdcs
    const userDefinedScenarios: GroupedXDC[] =
      this.$store.state.emissionIntensityXDCs.filter(
        (xdc: GroupedXDC) => xdc.isScenario
      );
    scenarioXDCs = scenarioXDCs.concat(userDefinedScenarios);

    return scenarioXDCs;
  }

  get isLoadingCurves(): boolean {
    return this.xdcFetchCount !== 0;
  }

  get xdcFetchCount(): number {
    return this.$store.state.ongoingPathwaysXDCRequests;
  }

  get isNaceFilled(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  created(): void {
    if (this.$store.state.curvesReload && !this.isNaceFilled) {
      this.dispatchCurvesFetch();
    }
  }
}
