var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "text-h5" }, [_vm._v("Strategy")]),
              _c("Assumptions"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v("Business Development Strategy"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "d-flex mode-switch" },
            [
              _vm._v(" Yearly Percentage Growth "),
              _c("v-switch", {
                staticClass: "switch",
                attrs: { inset: "", label: "End of Range Year Absolute Value" },
                model: {
                  value: _vm.isAbsolute,
                  callback: function ($$v) {
                    _vm.isAbsolute = $$v
                  },
                  expression: "isAbsolute",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.selectedActivity,
                    callback: function ($$v) {
                      _vm.selectedActivity = $$v
                    },
                    expression: "selectedActivity",
                  },
                },
                _vm._l(_vm.activities, function (activity) {
                  return _c("v-tab", { key: activity.id }, [
                    _vm._v(" " + _vm._s(activity.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.selectedActivity,
                    callback: function ($$v) {
                      _vm.selectedActivity = $$v
                    },
                    expression: "selectedActivity",
                  },
                },
                _vm._l(_vm.activities, function (activity) {
                  return _c(
                    "v-tab-item",
                    { key: activity.id },
                    [
                      _c(
                        "div",
                        { staticClass: "scrollable" },
                        _vm._l(activity.strategies, function (strategy, idx) {
                          return _c("StrategyInfoTable", {
                            key: strategy.id,
                            attrs: {
                              strategy: strategy,
                              "activity-id": activity.id,
                              "list-index": idx,
                              "is-percent-growth": !_vm.isAbsolute,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-testid": "add-strategy-button",
                            color: "primary",
                            elevation: "2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addNewStrategy(activity.id)
                            },
                          },
                        },
                        [_vm._v("Add Strategy ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }