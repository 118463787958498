import ISOCountry from "@/models/ISOCountry";

export const COUNTRIES = [
  new ISOCountry("Afghanistan", "AFG"),
  new ISOCountry("Albania", "ALB"),
  new ISOCountry("Algeria", "DZA"),
  new ISOCountry("American Samoa", "ASM"),
  new ISOCountry("Andorra", "AND"),
  new ISOCountry("Angola", "AGO"),
  new ISOCountry("Anguilla", "AIA"),
  new ISOCountry("Antarctica", "ATA"),
  new ISOCountry("Antigua and Barbuda", "ATG"),
  new ISOCountry("Argentina", "ARG"),
  new ISOCountry("Armenia", "ARM"),
  new ISOCountry("Aruba", "ABW"),
  new ISOCountry("Australia", "AUS"),
  new ISOCountry("Austria", "AUT"),
  new ISOCountry("Azerbaijan", "AZE"),
  new ISOCountry("Bahamas (the)", "BHS"),
  new ISOCountry("Bahrain", "BHR"),
  new ISOCountry("Bangladesh", "BGD"),
  new ISOCountry("Barbados", "BRB"),
  new ISOCountry("Belarus", "BLR"),
  new ISOCountry("Belgium", "BEL"),
  new ISOCountry("Belize", "BLZ"),
  new ISOCountry("Benin", "BEN"),
  new ISOCountry("Bermuda", "BMU"),
  new ISOCountry("Bhutan", "BTN"),
  new ISOCountry("Bolivia (Plurinational State of)", "BOL"),
  new ISOCountry("Bonaire, Sint Eustatius and Saba", "BES"),
  new ISOCountry("Bosnia and Herzegovina", "BIH"),
  new ISOCountry("Botswana", "BWA"),
  new ISOCountry("Bouvet Island", "BVT"),
  new ISOCountry("Brazil", "BRA"),
  new ISOCountry("British Indian Ocean Territory (the)", "IOT"),
  new ISOCountry("Brunei Darussalam", "BRN"),
  new ISOCountry("Bulgaria", "BGR"),
  new ISOCountry("Burkina Faso", "BFA"),
  new ISOCountry("Burundi", "BDI"),
  new ISOCountry("Cabo Verde", "CPV"),
  new ISOCountry("Cambodia", "KHM"),
  new ISOCountry("Cameroon", "CMR"),
  new ISOCountry("Canada", "CAN"),
  new ISOCountry("Cayman Islands (the)", "CYM"),
  new ISOCountry("Central African Republic (the)", "CAF"),
  new ISOCountry("Chad", "TCD"),
  new ISOCountry("Chile", "CHL"),
  new ISOCountry("China", "CHN"),
  new ISOCountry("Christmas Island", "CXR"),
  new ISOCountry("Cocos (Keeling) Islands (the)", "CCK"),
  new ISOCountry("Colombia", "COL"),
  new ISOCountry("Comoros (the)", "COM"),
  new ISOCountry("Congo (the Democratic Republic of the)", "COD"),
  new ISOCountry("Congo (the)", "COG"),
  new ISOCountry("Cook Islands (the)", "COK"),
  new ISOCountry("Costa Rica", "CRI"),
  new ISOCountry("Croatia", "HRV"),
  new ISOCountry("Cuba", "CUB"),
  new ISOCountry("Curaçao", "CUW"),
  new ISOCountry("Cyprus", "CYP"),
  new ISOCountry("Czechia", "CZE"),
  new ISOCountry("Côte d'Ivoire", "CIV"),
  new ISOCountry("Denmark", "DNK"),
  new ISOCountry("Djibouti", "DJI"),
  new ISOCountry("Dominica", "DMA"),
  new ISOCountry("Dominican Republic (the)", "DOM"),
  new ISOCountry("Ecuador", "ECU"),
  new ISOCountry("Egypt", "EGY"),
  new ISOCountry("El Salvador", "SLV"),
  new ISOCountry("Equatorial Guinea", "GNQ"),
  new ISOCountry("Eritrea", "ERI"),
  new ISOCountry("Estonia", "EST"),
  new ISOCountry("Eswatini", "SWZ"),
  new ISOCountry("Ethiopia", "ETH"),
  new ISOCountry("Falkland Islands (the) [Malvinas]", "FLK"),
  new ISOCountry("Faroe Islands (the)", "FRO"),
  new ISOCountry("Fiji", "FJI"),
  new ISOCountry("Finland", "FIN"),
  new ISOCountry("France", "FRA"),
  new ISOCountry("French Guiana", "GUF"),
  new ISOCountry("French Polynesia", "PYF"),
  new ISOCountry("French Southern Territories (the)", "ATF"),
  new ISOCountry("Gabon", "GAB"),
  new ISOCountry("Gambia (the)", "GMB"),
  new ISOCountry("Georgia", "GEO"),
  new ISOCountry("Germany", "DEU"),
  new ISOCountry("Ghana", "GHA"),
  new ISOCountry("Gibraltar", "GIB"),
  new ISOCountry("Greece", "GRC"),
  new ISOCountry("Greenland", "GRL"),
  new ISOCountry("Grenada", "GRD"),
  new ISOCountry("Guadeloupe", "GLP"),
  new ISOCountry("Guam", "GUM"),
  new ISOCountry("Guatemala", "GTM"),
  new ISOCountry("Guernsey", "GGY"),
  new ISOCountry("Guinea", "GIN"),
  new ISOCountry("Guinea-Bissau", "GNB"),
  new ISOCountry("Guyana", "GUY"),
  new ISOCountry("Haiti", "HTI"),
  new ISOCountry("Heard Island and McDonald Islands", "HMD"),
  new ISOCountry("Holy See (the)", "VAT"),
  new ISOCountry("Honduras", "HND"),
  new ISOCountry("Hong Kong", "HKG"),
  new ISOCountry("Hungary", "HUN"),
  new ISOCountry("Iceland", "ISL"),
  new ISOCountry("India", "IND"),
  new ISOCountry("Indonesia", "IDN"),
  new ISOCountry("Iran (Islamic Republic of)", "IRN"),
  new ISOCountry("Iraq", "IRQ"),
  new ISOCountry("Ireland", "IRL"),
  new ISOCountry("Isle of Man", "IMN"),
  new ISOCountry("Israel", "ISR"),
  new ISOCountry("Italy", "ITA"),
  new ISOCountry("Jamaica", "JAM"),
  new ISOCountry("Japan", "JPN"),
  new ISOCountry("Jersey", "JEY"),
  new ISOCountry("Jordan", "JOR"),
  new ISOCountry("Kazakhstan", "KAZ"),
  new ISOCountry("Kenya", "KEN"),
  new ISOCountry("Kiribati", "KIR"),
  new ISOCountry("Korea (the Democratic People's Republic of)", "PRK"),
  new ISOCountry("Korea (the Republic of)", "KOR"),
  new ISOCountry("Kuwait", "KWT"),
  new ISOCountry("Kyrgyzstan", "KGZ"),
  new ISOCountry("Lao People's Democratic Republic (the)", "LAO"),
  new ISOCountry("Latvia", "LVA"),
  new ISOCountry("Lebanon", "LBN"),
  new ISOCountry("Lesotho", "LSO"),
  new ISOCountry("Liberia", "LBR"),
  new ISOCountry("Libya", "LBY"),
  new ISOCountry("Liechtenstein", "LIE"),
  new ISOCountry("Lithuania", "LTU"),
  new ISOCountry("Luxembourg", "LUX"),
  new ISOCountry("Macao", "MAC"),
  new ISOCountry("Madagascar", "MDG"),
  new ISOCountry("Malawi", "MWI"),
  new ISOCountry("Malaysia", "MYS"),
  new ISOCountry("Maldives", "MDV"),
  new ISOCountry("Mali", "MLI"),
  new ISOCountry("Malta", "MLT"),
  new ISOCountry("Marshall Islands (the)", "MHL"),
  new ISOCountry("Martinique", "MTQ"),
  new ISOCountry("Mauritania", "MRT"),
  new ISOCountry("Mauritius", "MUS"),
  new ISOCountry("Mayotte", "MYT"),
  new ISOCountry("Mexico", "MEX"),
  new ISOCountry("Micronesia (Federated States of)", "FSM"),
  new ISOCountry("Moldova (the Republic of)", "MDA"),
  new ISOCountry("Monaco", "MCO"),
  new ISOCountry("Mongolia", "MNG"),
  new ISOCountry("Montenegro", "MNE"),
  new ISOCountry("Montserrat", "MSR"),
  new ISOCountry("Morocco", "MAR"),
  new ISOCountry("Mozambique", "MOZ"),
  new ISOCountry("Myanmar", "MMR"),
  new ISOCountry("Namibia", "NAM"),
  new ISOCountry("Nauru", "NRU"),
  new ISOCountry("Nepal", "NPL"),
  new ISOCountry("Netherlands (the)", "NLD"),
  new ISOCountry("New Caledonia", "NCL"),
  new ISOCountry("New Zealand", "NZL"),
  new ISOCountry("Nicaragua", "NIC"),
  new ISOCountry("Niger (the)", "NER"),
  new ISOCountry("Nigeria", "NGA"),
  new ISOCountry("Niue", "NIU"),
  new ISOCountry("Norfolk Island", "NFK"),
  new ISOCountry("Northern Mariana Islands (the)", "MNP"),
  new ISOCountry("Norway", "NOR"),
  new ISOCountry("Oman", "OMN"),
  new ISOCountry("Pakistan", "PAK"),
  new ISOCountry("Palau", "PLW"),
  new ISOCountry("Palestine, State of", "PSE"),
  new ISOCountry("Panama", "PAN"),
  new ISOCountry("Papua New Guinea", "PNG"),
  new ISOCountry("Paraguay", "PRY"),
  new ISOCountry("Peru", "PER"),
  new ISOCountry("Philippines (the)", "PHL"),
  new ISOCountry("Pitcairn", "PCN"),
  new ISOCountry("Poland", "POL"),
  new ISOCountry("Portugal", "PRT"),
  new ISOCountry("Puerto Rico", "PRI"),
  new ISOCountry("Qatar", "QAT"),
  new ISOCountry("Republic of North Macedonia", "MKD"),
  new ISOCountry("Romania", "ROU"),
  new ISOCountry("Russian Federation (the)", "RUS"),
  new ISOCountry("Rwanda", "RWA"),
  new ISOCountry("Réunion", "REU"),
  new ISOCountry("Saint Barthélemy", "BLM"),
  new ISOCountry("Saint Helena, Ascension and Tristan da Cunha", "SHN"),
  new ISOCountry("Saint Kitts and Nevis", "KNA"),
  new ISOCountry("Saint Lucia", "LCA"),
  new ISOCountry("Saint Martin (French part)", "MAF"),
  new ISOCountry("Saint Pierre and Miquelon", "SPM"),
  new ISOCountry("Saint Vincent and the Grenadines", "VCT"),
  new ISOCountry("Samoa", "WSM"),
  new ISOCountry("San Marino", "SMR"),
  new ISOCountry("Sao Tome and Principe", "STP"),
  new ISOCountry("Saudi Arabia", "SAU"),
  new ISOCountry("Senegal", "SEN"),
  new ISOCountry("Serbia", "SRB"),
  new ISOCountry("Seychelles", "SYC"),
  new ISOCountry("Sierra Leone", "SLE"),
  new ISOCountry("Singapore", "SGP"),
  new ISOCountry("Sint Maarten (Dutch part)", "SXM"),
  new ISOCountry("Slovakia", "SVK"),
  new ISOCountry("Slovenia", "SVN"),
  new ISOCountry("Solomon Islands", "SLB"),
  new ISOCountry("Somalia", "SOM"),
  new ISOCountry("South Africa", "ZAF"),
  new ISOCountry("South Georgia and the South Sandwich Islands", "SGS"),
  new ISOCountry("South Sudan", "SSD"),
  new ISOCountry("Spain", "ESP"),
  new ISOCountry("Sri Lanka", "LKA"),
  new ISOCountry("Sudan (the)", "SDN"),
  new ISOCountry("Suriname", "SUR"),
  new ISOCountry("Svalbard and Jan Mayen", "SJM"),
  new ISOCountry("Sweden", "SWE"),
  new ISOCountry("Switzerland", "CHE"),
  new ISOCountry("Syrian Arab Republic", "SYR"),
  new ISOCountry("Taiwan (Province of China)", "TWN"),
  new ISOCountry("Tajikistan", "TJK"),
  new ISOCountry("Tanzania, United Republic of", "TZA"),
  new ISOCountry("Thailand", "THA"),
  new ISOCountry("Timor-Leste", "TLS"),
  new ISOCountry("Togo", "TGO"),
  new ISOCountry("Tokelau", "TKL"),
  new ISOCountry("Tonga", "TON"),
  new ISOCountry("Trinidad and Tobago", "TTO"),
  new ISOCountry("Tunisia", "TUN"),
  new ISOCountry("Turkey", "TUR"),
  new ISOCountry("Turkmenistan", "TKM"),
  new ISOCountry("Turks and Caicos Islands (the)", "TCA"),
  new ISOCountry("Tuvalu", "TUV"),
  new ISOCountry("Uganda", "UGA"),
  new ISOCountry("Ukraine", "UKR"),
  new ISOCountry("United Arab Emirates (the)", "ARE"),
  new ISOCountry(
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "GBR"
  ),
  new ISOCountry("United States Minor Outlying Islands (the)", "UMI"),
  new ISOCountry("United States of America (the)", "USA"),
  new ISOCountry("Uruguay", "URY"),
  new ISOCountry("Uzbekistan", "UZB"),
  new ISOCountry("Vanuatu", "VUT"),
  new ISOCountry("Venezuela (Bolivarian Republic of)", "VEN"),
  new ISOCountry("Viet Nam", "VNM"),
  new ISOCountry("Virgin Islands (British)", "VGB"),
  new ISOCountry("Virgin Islands (U.S.)", "VIR"),
  new ISOCountry("Wallis and Futuna", "WLF"),
  new ISOCountry("Western Sahara", "ESH"),
  new ISOCountry("Yemen", "YEM"),
  new ISOCountry("Zambia", "ZMB"),
  new ISOCountry("Zimbabwe", "ZWE"),
  new ISOCountry("Åland Islands", "ALA"),
];
