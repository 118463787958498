
import { Component, Prop, Vue } from "vue-property-decorator";
import Activity from "../../../models/activity/Activity";
import ScenarioConfig from "@/models/ScenarioConfig";
import Strategy from "@/models/strategy/Strategy";
import { STARTING_STRATEGY } from "@/constants/Constants";

@Component({
  components: {
    ActivityToStrategyRow,
  },
})
export default class ActivityToStrategyRow extends Vue {
  @Prop({ required: true }) activity!: Activity;
  @Prop({ required: true }) scenarioConfig!: ScenarioConfig;
  @Prop({ required: false, default: false }) readOnly!: boolean;

  get initialStrategy(): Strategy {
    const initialId = this.scenarioConfig.getStrategyIdOf(this.activity.id);

    const found = this.activity.strategies.find((s) => s.id === initialId);

    if (found) {
      return found;
    }

    //Should never happen
    return STARTING_STRATEGY;
  }

  set initialStrategy(newStrategy: Strategy) {
    this.scenarioConfig.upsertStrategyIdOf(this.activity.id, newStrategy.id);
    this.$store.commit("curvesNeedReload", true);
  }
}
