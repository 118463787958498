
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Navigation extends Vue {
  @Prop({ required: false, default: true }) isVisible!: boolean;

  showNewConfirmationDialog = false;

  confirmNew(): void {
    this.showNewConfirmationDialog = true;
  }

  get autoSaveOn(): boolean {
    return this.$store.state.configStore.autoSave;
  }

  newSession(): void {
    this.showNewConfirmationDialog = false;
    this.$store.commit("clearState");
    this.$router.push("Company");
    this.$store.dispatch("makeXDCRequest", this.$store.state.activities);
    this.$store.commit(
      "sessionStore/setNewSessionId",
      Vue.prototype.$uuid.v4()
    );
  }

  saveCurrentSession(): void {
    this.$store.dispatch("sessionStore/saveSession");
  }
}
