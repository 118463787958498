var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "text-h5" }, [
                _vm._v("Scenario Definition"),
              ]),
              _c("Assumptions"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.scenarios, function (scenario) {
        return _c(
          "v-row",
          { key: scenario.name },
          [
            _vm.isBaseline(scenario)
              ? _c(
                  "v-col",
                  [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v("Scenario: " + _vm._s(scenario.name)),
                    ]),
                    _vm._l(_vm.activities, function (activity) {
                      return _c("ActivityToStrategyRow", {
                        key: activity.id,
                        attrs: {
                          activity: activity,
                          "scenario-config": scenario,
                          "read-only": true,
                        },
                      })
                    }),
                  ],
                  2
                )
              : _c("ScenarioBlock", { attrs: { "scenario-config": scenario } }),
          ],
          1
        )
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.addNewScenario },
                },
                [_vm._v("Add New Scenario")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }