var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "settings" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Name", "hide-details": "auto" },
                on: {
                  input: function ($event) {
                    return _vm.setCompanyName(_vm.companyName)
                  },
                },
                model: {
                  value: _vm.companyName,
                  callback: function ($$v) {
                    _vm.companyName = $$v
                  },
                  expression: "companyName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c("v-select", {
                attrs: {
                  "data-testid": "base-year-select",
                  items: _vm.baseYears,
                  label: "Base Year",
                },
                on: {
                  input: function ($event) {
                    return _vm.setBaseYearSelection(_vm.baseYearSelection)
                  },
                },
                model: {
                  value: _vm.baseYearSelection,
                  callback: function ($$v) {
                    _vm.baseYearSelection = _vm._n($$v)
                  },
                  expression: "baseYearSelection",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.extension2100List,
                  label: "Extension 2100",
                  "item-text": "display",
                  "item-value": "extension2100Selection",
                  hint: `${_vm.extension2100Selection.display}`,
                  "persistent-hint": "",
                  "return-object": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.setExtension2100(_vm.extension2100Selection)
                  },
                },
                model: {
                  value: _vm.extension2100Selection,
                  callback: function ($$v) {
                    _vm.extension2100Selection = $$v
                  },
                  expression: "extension2100Selection",
                },
              }),
              _vm.targetYearFeatureToggle
                ? _c("v-select", {
                    attrs: { items: _vm.targetYears, label: "Target Year" },
                    on: {
                      input: function ($event) {
                        return _vm.setTargetYearSelection(
                          _vm.targetYearSelection
                        )
                      },
                    },
                    model: {
                      value: _vm.targetYearSelection,
                      callback: function ($$v) {
                        _vm.targetYearSelection = $$v
                      },
                      expression: "targetYearSelection",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "3" } }),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c("v-autocomplete", {
                ref: "naceSectorAutocomplete",
                attrs: {
                  items: _vm.naceList,
                  "item-disabled": "isDisabled",
                  hint: `${_vm.naceSector?.full ?? ""}`,
                  "persistent-hint": "",
                  label: "NACE Sector",
                  filter: _vm.searchNace,
                },
                on: {
                  input: function ($event) {
                    return _vm.setNaceSector(_vm.naceSector)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [_c("div", [_vm._v(_vm._s(data.item.code))])]
                    },
                  },
                  {
                    key: "item",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(data.item.full) +
                              " " +
                              _vm._s(
                                data.item.isDisabled
                                  ? "(currently not-supported)"
                                  : ""
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.naceSector,
                  callback: function ($$v) {
                    _vm.naceSector = $$v
                  },
                  expression: "naceSector",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.countries,
                  label: "Country",
                  "item-text": "fullName",
                  "item-value": "countrySelection",
                  hint: `(${_vm.countrySelection.code}) - ${_vm.countrySelection.fullName}`,
                  "persistent-hint": "",
                  "return-object": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.setCountrySelection(_vm.countrySelection)
                  },
                },
                model: {
                  value: _vm.countrySelection,
                  callback: function ($$v) {
                    _vm.countrySelection = $$v
                  },
                  expression: "countrySelection",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.providerList,
                  label: "Provider",
                  "item-text": "title",
                  "item-value": "providerSelection",
                  hint: `${_vm.providerSelection.title}`,
                  "persistent-hint": "",
                  "return-object": "",
                },
                on: {
                  input: function ($event) {
                    return _vm.setProvider(_vm.providerSelection)
                  },
                },
                model: {
                  value: _vm.providerSelection,
                  callback: function ($$v) {
                    _vm.providerSelection = $$v
                  },
                  expression: "providerSelection",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", sm: "12", md: "2", lg: "2", xl: "2" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.sspList,
                  hint: `(${_vm.sspSelection.display}) - ${_vm.sspSelection.full}`,
                  "persistent-hint": "",
                  label: "SSP",
                },
                on: {
                  input: function ($event) {
                    return _vm.setSSP(_vm.sspSelection)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [_c("div", [_vm._v(_vm._s(data.item.display))])]
                    },
                  },
                  {
                    key: "item",
                    fn: function (data) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${data.item.display} - ${data.item.full}`
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.sspSelection,
                  callback: function ($$v) {
                    _vm.sspSelection = $$v
                  },
                  expression: "sspSelection",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }