var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("table", [
    _c("thead", [
      _c("tr", { staticClass: "group-title" }, [
        _c("th", { attrs: { colspan: "2" } }),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("GVA (EUR)")]),
        _c("th", { attrs: { colspan: _vm.totalSubscopesCount + 3 } }, [
          _vm._v("Emissions (tCO2e)"),
        ]),
        _c("th", { attrs: { colspan: "4" } }, [_vm._v("Baseline XDC")]),
      ]),
      _c("tr", { staticClass: "col-title" }, [
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Title")]),
        _c("th", { attrs: { colspan: "2" } }),
        _c("th", { attrs: { colspan: _vm.subscopes1Size + 1 } }, [
          _vm._v("Scope 1"),
        ]),
        _c("th", { attrs: { colspan: _vm.subscopes2Size + 1 } }, [
          _vm._v("Scope 2"),
        ]),
        _c("th", { attrs: { colspan: _vm.subscopes3Size + 1 } }, [
          _vm._v("Scope 3"),
        ]),
        _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Total")]),
        _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 1")]),
        _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 2")]),
        _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 3")]),
      ]),
      _c(
        "tr",
        { staticClass: "col-subtitle" },
        [
          _c("th", { attrs: { colspan: "2" } }),
          _c("th", [_vm._v("EBITDA")]),
          _c("th", [_vm._v("Personnel Costs")]),
          _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel1))]),
          _vm._l(_vm.scope1Subscopes, function (subscope) {
            return _c("th", { key: `${subscope.id}-act-info-table` }, [
              _vm._v(" " + _vm._s(subscope.name) + " "),
            ])
          }),
          _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel2))]),
          _vm._l(_vm.scope2Subscopes, function (subscope) {
            return _c("th", { key: `${subscope.id}-act-info-table` }, [
              _vm._v(" " + _vm._s(subscope.name) + " "),
            ])
          }),
          _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel3))]),
          _vm._l(_vm.scope3Subscopes, function (subscope) {
            return _c("th", { key: `${subscope.id}-act-info-table` }, [
              _vm._v(" " + _vm._s(subscope.name) + " "),
            ])
          }),
          _c("th", { staticClass: "xdc-number-column" }),
          _c("th", { staticClass: "xdc-number-column" }),
          _c("th", { staticClass: "xdc-number-column" }),
          _c("th", { staticClass: "xdc-number-column" }),
        ],
        2
      ),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.activities, function (act, idx) {
        return _c("ActivityInfoRow", {
          key: `${act.id}-act-info-table-row`,
          attrs: { activity: act, listIndex: idx },
        })
      }),
      1
    ),
    _c("tfoot", [
      _c(
        "tr",
        [
          _c(
            "td",
            { staticClass: "add-activity-slot", attrs: { colspan: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", elevation: "2" },
                  on: { click: _vm.addNewActivity },
                },
                [_vm._v("Add Activity ")]
              ),
            ],
            1
          ),
          _c("td", { staticClass: "border-left border-top" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "read-only": "",
                    value: _vm.totalEbitda,
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("td", { staticClass: "border-top" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "read-only": "",
                    value: _vm.totalPersonnelCosts,
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("td", { staticClass: "border-left border-top" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "read-only": "",
                    value: _vm.totalScope1,
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._l(_vm.scope1Subscopes, function (subscope) {
            return _c(
              "td",
              {
                key: `${subscope.id}-act-info-table-sub`,
                staticClass: "input-column border-top",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-body2" },
                  [
                    _c("vue-numeric", {
                      staticClass: "number-input",
                      attrs: {
                        "read-only": "",
                        value: _vm.getTotalForSubscope(subscope.id),
                        "decimal-separator": _vm.decimalSeparator,
                        separator: _vm.thousandSeparator,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c("td", { staticClass: "border-left border-top" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "read-only": "",
                    value: _vm.totalScope2,
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._l(_vm.scope2Subscopes, function (subscope) {
            return _c(
              "td",
              {
                key: `${subscope.id}-act-info-table-sub`,
                staticClass: "input-column border-top",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-body2" },
                  [
                    _c("vue-numeric", {
                      staticClass: "number-input",
                      attrs: {
                        "read-only": "",
                        value: _vm.getTotalForSubscope(subscope.id),
                        "decimal-separator": _vm.decimalSeparator,
                        separator: _vm.thousandSeparator,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c("td", { staticClass: "border-left border-top" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "read-only": "",
                    value: _vm.totalScope3,
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._l(_vm.scope3Subscopes, function (subscope) {
            return _c(
              "td",
              {
                key: `${subscope.id}-act-info-table-sub`,
                staticClass: "input-column border-top",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-body2" },
                  [
                    _c("vue-numeric", {
                      staticClass: "number-input",
                      attrs: {
                        "read-only": "",
                        value: _vm.getTotalForSubscope(subscope.id),
                        "decimal-separator": _vm.decimalSeparator,
                        separator: _vm.thousandSeparator,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "td",
            { staticClass: "border-left border-top xdc-number-column" },
            [
              _c("XDCValueDisplay", {
                attrs: {
                  status: _vm.totalActivityXDC.status,
                  "xdc-value": _vm.totalActivityXDC.xdcTotal,
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { staticClass: "border-top xdc-number-column" },
            [
              _c("XDCValueDisplay", {
                attrs: {
                  status: _vm.totalActivityXDC.status,
                  "xdc-value": _vm.totalActivityXDC.xdcScope1,
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { staticClass: "border-top xdc-number-column" },
            [
              _c("XDCValueDisplay", {
                attrs: {
                  status: _vm.totalActivityXDC.status,
                  "xdc-value": _vm.totalActivityXDC.xdcScope2,
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            { staticClass: "border-top xdc-number-column" },
            [
              _c("XDCValueDisplay", {
                attrs: {
                  status: _vm.totalActivityXDC.status,
                  "xdc-value": _vm.totalActivityXDC.xdcScope3,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }