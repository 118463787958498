var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mt-2 d-flex flex-row" }, [
    _c("div", { staticClass: "mr-2 text-body-2" }, [
      _c("b", [_vm._v("Provider:")]),
      _vm._v(" " + _vm._s(_vm.provider)),
    ]),
    _c("div", { staticClass: "mr-2 text-body-2" }, [
      _c("b", [_vm._v("SSP:")]),
      _vm._v(" " + _vm._s(_vm.ssp)),
    ]),
    _c("div", { staticClass: "mr-2 text-body-2" }, [
      _c("b", [_vm._v("Extension2100:")]),
      _vm._v(" " + _vm._s(_vm.extension2100) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }