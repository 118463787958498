
import { Component, Vue } from "vue-property-decorator";
import Activity from "../../../models/activity/Activity";
import ActivityInfoRow from "@/pages/company/components/ActivityInfoRow.vue";
import { sumBy } from "lodash";
import SubscopeEmissions from "@/models/activity/SubscopeEmissions";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import XDCValueDisplay from "@/pages/shared/components/XDCValueDisplay.vue";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { XDCValueDisplay, ActivityInfoRow, VueNumeric },
})
export default class ActivityInfoTable extends Vue {
  get activities(): Activity[] {
    return this.$store.state.activities;
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  get totalOrRestLabel1(): string {
    if (this.activities.length === 0) {
      return "Total";
    }

    return this.activities[0].scope1.subscopes.length === 0 ? "Total" : "Other";
  }

  get totalOrRestLabel2(): string {
    if (this.activities.length === 0) {
      return "Total";
    }

    return this.activities[0].scope2.subscopes.length === 0 ? "Total" : "Other";
  }

  get totalOrRestLabel3(): string {
    if (this.activities.length === 0) {
      return "Total";
    }

    return this.activities[0].scope3.subscopes.length === 0 ? "Total" : "Other";
  }

  get totalEbitda(): number {
    return sumBy(this.activities, (act) => Number(act.gva.ebitda));
  }

  get totalPersonnelCosts(): number {
    return sumBy(this.activities, (act) => Number(act.gva.personnelCosts));
  }

  get totalScope1(): number {
    return sumBy(this.activities, (act) => Number(act.scope1.other));
  }

  get totalScope2(): number {
    return sumBy(this.activities, (act) => Number(act.scope2.other));
  }

  get totalScope3(): number {
    return sumBy(this.activities, (act) => Number(act.scope3.other));
  }

  getTotalForSubscope(searchId: string): number {
    if (searchId.startsWith("1")) {
      return this.activities
        .map((act) => {
          return act.scope1.subscopes
            .filter((s) => s.id === searchId)
            .map((s) => Number(s.value));
        })
        .reduce((a, b) => Number(a) + Number(b), 0);
    } else if (searchId.startsWith("2")) {
      return this.activities
        .map((act) => {
          return act.scope2.subscopes
            .filter((s) => s.id === searchId)
            .map((s) => Number(s.value));
        })
        .reduce((a, b) => Number(a) + Number(b), 0);
    } else {
      return this.activities
        .map((act) => {
          return act.scope3.subscopes
            .filter((s) => s.id === searchId)
            .map((s) => Number(s.value));
        })
        .reduce((a, b) => Number(a) + Number(b), 0);
    }
  }

  get scope1Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes1;
  }

  get scope2Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes2;
  }

  get scope3Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes3;
  }

  addNewActivity(): void {
    this.$store.commit("addNewActivity");
    this.$store.dispatch("makeXDCRequest", this.activities);
    this.$store.commit("curvesNeedReload", true);
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes1Size(): number {
    return this.scope1Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes2Size(): number {
    return this.scope2Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes3Size(): number {
    return this.scope3Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get totalSubscopesCount(): number {
    return this.subscopes1Size + this.subscopes2Size + this.subscopes3Size;
  }

  get totalActivityXDC(): BaselineXDC {
    return this.$store.state.totalActivityXDC;
  }
}
