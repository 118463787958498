var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h5" }, [_vm._v("Saved Sessions")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.allSessions.length > 0
                ? _c("v-simple-table", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Company name")]),
                                  _c("th", [_vm._v("Created")]),
                                  _c("th", [_vm._v("Modified")]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.allSessions, function (item) {
                                  return _c("SessionItemEntry", {
                                    key: item.sessionId,
                                    attrs: { item: item },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectSession(item.sessionId)
                                      },
                                      delete: function ($event) {
                                        _vm.showDeleteDialog = true
                                        _vm.selectedSessionId = item.sessionId
                                      },
                                      export: function ($event) {
                                        return _vm.exportSession(
                                          item.companyName,
                                          item.session
                                        )
                                      },
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2932441619
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h6" }, [_vm._v("Current Session")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.exportCurrentSession },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" mdi-file-export"),
                  ]),
                  _vm._v(" Export "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pl-5" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.onImportClick },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-file-import"),
                      ]),
                      _vm._v(" Import "),
                    ],
                    1
                  ),
                  _c("input", {
                    ref: "fileUpload",
                    staticClass: "d-none",
                    attrs: {
                      id: "contentFile",
                      type: "file",
                      accept: ".right",
                    },
                    on: { change: _vm.importSession },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "420" },
          model: {
            value: _vm.showLoadDialog,
            callback: function ($$v) {
              _vm.showLoadDialog = $$v
            },
            expression: "showLoadDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Are you sure you want to load this Session? "),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " If you confirm you will loose all unsaved Data on the current session! "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showLoadDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.loadSession },
                    },
                    [_vm._v(" Load")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "420" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function ($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Are you sure you want to delete this Session? "),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " If you confirm you will loose all Data on the selected session! "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteDialog = false
                          this.selectedSessionId = ""
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteSession },
                    },
                    [_vm._v(" DELETE")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasErrorMessage
        ? _c("div", {
            staticClass: "error-message red--text text-center",
            domProps: { innerHTML: _vm._s(_vm.errorMessage) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }