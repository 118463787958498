import Subscope from "@/models/Subscope";
import HighchartCurve from "@/models/highcharts/HighchartCurve";
import ISOCountry from "@/models/ISOCountry";
import Activity from "@/models/activity/Activity";
import BusinessActivityCurvesGroup from "@/models/trajectory/BusinessActivityCurvesGroup";
import {
  BASE_YEARS,
  STARTING_ACTIVITY,
  STARTING_COMPANY_NAME,
  STARTING_COUNTRY,
  STARTING_SCENARIO,
  TARGET_YEARS,
} from "@/constants/Constants";
import { cloneDeep } from "lodash";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import ScenarioConfig from "@/models/ScenarioConfig";
import NACESector from "@/models/NACESector";
import Provider from "@/models/Provider";
import GroupedXDC from "@/models/xdc/GroupedXDC";
import { PROVIDERS } from "@/constants/Providers";
import { SSPs } from "@/constants/SSPs";
import SSP from "@/models/SSP";
import Extension from "@/models/Extension";
import { EXTENSION_2100 } from "@/constants/Extension2100s";
import HighChartEei from "@/models/highcharts/HighChartEei";

export interface ExportableState {
  companyName: string;
  baseYear: number;
  targetYear: number;
  country: ISOCountry;
  naceSector: NACESector;
  provider: Provider;
  ssp: SSP;
  extension2100: Extension;
  activities: Array<Activity>;
  totalActivityXDC: BaselineXDC;
  scenarios: Array<ScenarioConfig>;
  // [SubScopeFeatureToggle] remove if subScopes not re-enabled
  selectedSubScopes1: Array<Subscope>;
  selectedSubScopes2: Array<Subscope>;
  selectedSubScopes3: Array<Subscope>;
}

export const getExportState = (): ExportableState => {
  return {
    companyName: state.companyName,
    baseYear: state.baseYear,
    targetYear: state.targetYear,
    country: state.country,
    naceSector: state.naceSector,
    provider: state.provider,
    ssp: state.ssp,
    extension2100: state.extension2100,
    activities: state.activities,
    totalActivityXDC: state.totalActivityXDC,
    scenarios: state.scenarios,
    // [SubScopeFeatureToggle] remove if subScopes not re-enabled
    selectedSubScopes1: state.selectedSubScopes1,
    selectedSubScopes2: state.selectedSubScopes2,
    selectedSubScopes3: state.selectedSubScopes3,
  };
};

export interface State {
  curvesReload: boolean;
  companyName: string;
  baseYear: number;
  targetYear: number;
  country: ISOCountry;
  provider: Provider;
  ssp: SSP;
  extension2100: Extension;
  activities: Array<Activity>;
  totalActivityXDC: BaselineXDC;
  scenarios: Array<ScenarioConfig>;
  // [SubScopeFeatureToggle] remove if subScopes not re-enabled
  selectedSubScopes1: Array<Subscope>;
  selectedSubScopes2: Array<Subscope>;
  selectedSubScopes3: Array<Subscope>;
  businessActivityCurves: Array<BusinessActivityCurvesGroup>;
  ongoingChartsRequest: number;
  selectedBusinessActivityCurves: Array<HighchartCurve>;
  highChartEei: HighChartEei;
  ongoingEmissionPathwaysRequests: number;
  selectedEmissionIntensityCurves: Array<HighchartCurve>;
  emissionIntensityXDCs: Array<GroupedXDC>;
  ongoingPathwaysXDCRequests: number;
  naceSector: NACESector;
  naceSectors: Array<NACESector>;
}

export const getInitialState = (): State => {
  return {
    curvesReload: true,
    companyName: STARTING_COMPANY_NAME,
    baseYear: BASE_YEARS[BASE_YEARS.length - 2],
    targetYear: TARGET_YEARS[TARGET_YEARS.length - 1],
    country: STARTING_COUNTRY,
    provider: PROVIDERS[0],
    ssp: SSPs[2],
    extension2100: EXTENSION_2100[2],
    activities: [cloneDeep(STARTING_ACTIVITY)],
    totalActivityXDC: new BaselineXDC(),
    scenarios: [cloneDeep(STARTING_SCENARIO)],
    // [SubScopeFeatureToggle] remove if subScopes not re-enabled
    selectedSubScopes1: Array<Subscope>(),
    selectedSubScopes2: Array<Subscope>(),
    selectedSubScopes3: Array<Subscope>(),
    businessActivityCurves: Array<BusinessActivityCurvesGroup>(),
    ongoingChartsRequest: 0,
    selectedBusinessActivityCurves: Array<HighchartCurve>(),
    highChartEei: new HighChartEei(),
    ongoingEmissionPathwaysRequests: 0,
    selectedEmissionIntensityCurves: Array<HighchartCurve>(),
    emissionIntensityXDCs: Array<GroupedXDC>(),
    ongoingPathwaysXDCRequests: 0,
    naceSector: new NACESector("", ""),
    naceSectors: [],
  };
};

export const state: State = getInitialState();
