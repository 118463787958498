import { READY } from "@/constants/RequestStatus";

export default class GroupedXDC {
  name: string;
  xdcTotal: number;
  xdcScope1: number;
  xdcScope2: number;
  xdcScope3: number;
  status: string;
  isCompany: boolean;
  isScenario: boolean;
  isActivity: boolean;

  constructor(
    name = "",
    baselineTotal = 0,
    baselineScope1 = 0,
    baselineScope2 = 0,
    baselineScope3 = 0,
    status: string = READY,
    isCompany = false,
    isScenario = false,
    isActivity = false
  ) {
    this.name = name;
    this.xdcTotal = baselineTotal;
    this.xdcScope1 = baselineScope1;
    this.xdcScope2 = baselineScope2;
    this.xdcScope3 = baselineScope3;
    this.status = status;
    this.isCompany = isCompany;
    this.isScenario = isScenario;
    this.isActivity = isActivity;
  }
}
