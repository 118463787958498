import { Component, Vue } from "vue-property-decorator";

@Component
export default class FetchCurvesMixin extends Vue {
  dispatchCurvesFetch(): void {
    this.$store.commit("curvesNeedReload", false);
    this.$store.dispatch("makeCompanyTrajectoriesRequest");
    this.$store.dispatch("makeIntensityPathwaysRequest");
  }
}
