import Config from "@/constants/Config";
import AuthGateway from "@/auth/services/AuthGateway";
import { successfulResponse } from "@/services/SuccesfulResponse";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import { ERROR, SERVER_NOT_AVAILABLE } from "@/constants/RequestStatus";
import { AxiosResponse } from "axios";
import ActivityStrategyCombo from "@/models/pathways/ActivityStrategyCombo";
import GroupedXDC from "@/models/xdc/GroupedXDC";
import GrowthProjection from "@/models/strategy/GrowthProjection";

export default class XDCGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v3/se/xdc";

  static getCompanyActivityBaseline(
    baseYear: number,
    countryCode: string,
    gvaValue: number,
    naceCode: string,
    provider: string,
    ssp: string,
    scope1Value: number,
    scope2Value: number,
    scope3Value: number
  ): Promise<BaselineXDC> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/baseline",
      {},
      {
        asset_id: "baseline xdc",
        baseYear: baseYear,
        countryCode: countryCode,
        gva: gvaValue,
        naceCode: naceCode,
        provider: provider,
        sspCode: ssp,
        scope1: scope1Value,
        scope2: scope2Value,
        scope3: scope3Value,
      }
    ).then((response) => handleXDCResponse(response));
  }

  static getStrategyXDC(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    initialEbitda: number,
    initialPersonnelCost: number,
    initialScope1: number,
    initialScope2: number,
    initialScope3: number,
    ssp: string,
    extension2100: string,
    growthProjections: GrowthProjection[]
  ): Promise<BaselineXDC> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/strategy",
      {},
      {
        baseYear: baseYear,
        countryCode: countryCode,
        naceCode: naceCode,
        provider: provider,
        initialEbitda: initialEbitda,
        initialPersonnelCost: initialPersonnelCost,
        initialScope1: initialScope1,
        initialScope2: initialScope2,
        initialScope3: initialScope3,
        sspCode: ssp,
        extension2100: extension2100,
        growthsScenarios: growthProjections,
      }
    ).then((response) => handleXDCResponse(response));
  }

  static getUserDefinedXDC(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    ssp: string,
    extension2100: string,
    activityStrategyCombinations: ActivityStrategyCombo[]
  ): Promise<GroupedXDC> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/user-defined",
      {},
      {
        baseYear: baseYear,
        countryCode: countryCode,
        naceCode: naceCode,
        provider: provider,
        sspCode: ssp,
        extension2100: extension2100,
        activityStrategyCombinations: activityStrategyCombinations,
      }
    ).then((response) => {
      if (response === undefined) {
        // Error due to server down?
        const groupedXDC = new GroupedXDC();
        groupedXDC.status = SERVER_NOT_AVAILABLE;
        groupedXDC.isScenario = true;

        return groupedXDC;
      }

      if (
        !successfulResponse(response) ||
        (response.data.status == "ERROR" && response.data.message)
      ) {
        const groupedXDC = new GroupedXDC();
        groupedXDC.status = ERROR;
        groupedXDC.isScenario = true;

        return groupedXDC;
      }

      const data = response.data as GroupedXDC;

      return new GroupedXDC(
        data.name,
        data.xdcTotal,
        data.xdcScope1,
        data.xdcScope2,
        data.xdcScope3,
        data.status,
        false,
        true,
        false
      );
    });
  }
}

function handleXDCResponse(response: AxiosResponse): BaselineXDC {
  if (response === undefined) {
    // Error due to server down?
    const baselineXDC = new BaselineXDC();
    baselineXDC.status = SERVER_NOT_AVAILABLE;

    return baselineXDC;
  }

  if (!successfulResponse(response) || response.data.error) {
    const baselineXDC = new BaselineXDC();
    baselineXDC.status = ERROR;

    return baselineXDC;
  }

  const data = response.data as BaselineXDC;

  return new BaselineXDC(
    data.name,
    data.xdcTotal,
    data.xdcScope1,
    data.xdcScope2,
    data.xdcScope3,
    data.status
  );
}
