var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-numeric", {
    staticClass: "number-input",
    attrs: {
      "decimal-separator": _vm.decimalSeparator,
      separator: _vm.thousandSeparator,
      "read-only": _vm.readonly,
    },
    on: { blur: _vm.onBlurHandler, focus: _vm.storeCurrentValue },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }