import Vue from "vue";
import "./plugins/axios";
import App from "./pages/main/App.vue";
import router from "./router";
import { store } from "./store/main";
import jsonwebtoken from "jsonwebtoken";
import vuetify from "./plugins/vuetify";
// import VueNumeric from "vue-numeric";
import CurvePainter from "./services/CurvePainter";
import VueUUID from "vue-uuid";
import JsonExcel from "vue-json-excel";
import exportingInit from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import dataExporting from "highcharts/modules/export-data";

Vue.config.productionTip = false;

// Vue.use(VueNumeric);
exportingInit(Highcharts);
dataExporting(Highcharts);

Vue.use(jsonwebtoken);
Vue.use(VueUUID);

Vue.component("downloadExcel", JsonExcel);

Vue.prototype.$curvePainter = new CurvePainter();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
