import CurveGroupDto from "@/models/trajectory/CurveGroupDto";
import HighchartCurveGroup from "@/models/highcharts/HighchartCurveGroup";
import { ERROR, SERVER_NOT_AVAILABLE } from "@/constants/RequestStatus";

export default class BusinessActivityCurvesGroup {
  parentActivityId: number;
  parentStrategyId: number;
  curves: HighchartCurveGroup[];

  constructor(
    parentActivityId: number,
    parentStrategyId: number,
    parentActivityName: string,
    parentStrategyName: string,
    baseYear: number,
    curveGroup: CurveGroupDto[]
  ) {
    this.parentActivityId = parentActivityId;
    this.parentStrategyId = parentStrategyId;
    this.curves = this.setCurves(curveGroup, baseYear);
  }

  setCurves(curves: CurveGroupDto[], baseYear: number): HighchartCurveGroup[] {
    const list = Array<HighchartCurveGroup>();

    curves.forEach((curveGroup) => {
      const hCurveGroup = new HighchartCurveGroup(
        `${this.parentActivityId}${this.parentStrategyId}`,
        baseYear,
        curveGroup.name,
        curveGroup.gvaPoints,
        curveGroup.totalEmissionPoints,
        curveGroup.scope1EmissionPoints,
        curveGroup.scope2EmissionPoints,
        curveGroup.scope3EmissionPoints,
        curveGroup.status
      );

      list.push(hCurveGroup);
    });

    return list;
  }

  updateCurvesChartLegend(activityName: string, strategyName: string): void {
    this.curves.forEach((curveGroup) => {
      curveGroup.updateCurvesChartLegend(activityName, strategyName);
    });
  }

  amountOfErrorStates(): number {
    return this.curves.filter((c) => this.isErrorState(c.status)).length;
  }

  private isErrorState(status: string): boolean {
    return status === ERROR || status === SERVER_NOT_AVAILABLE;
  }
}
