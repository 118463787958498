var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "350" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    { staticClass: "wrapper" },
                    [
                      _c("v-checkbox", {
                        attrs: { "input-value": _vm.isSelected },
                        on: { change: _vm.changed },
                      }),
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { staticClass: "color-square", style: _vm.cssVars },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Choose a Color")]),
              _c(
                "v-card-text",
                [
                  _c("v-color-picker", {
                    attrs: { elevation: "0" },
                    model: {
                      value: _vm.curve.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.curve, "color", $$v)
                      },
                      expression: "curve.color",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("OK")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }