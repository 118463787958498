var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.status === _vm.LOADING && !_vm.isNaceMissing
      ? _c(
          "div",
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", width: 3, color: "primary" },
            }),
          ],
          1
        )
      : _vm.status === _vm.ERROR ||
        _vm.status === _vm.SERVER_NOT_AVAILABLE ||
        _vm.isNaceMissing
      ? _c(
          "div",
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  icon: "",
                                  color: "error",
                                  disabled: _vm.isNaceMissing,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.reload()
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-cached")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("span", [
                  _vm._v(" It seems an error occurred..."),
                  _c("br"),
                  _vm._v(" Please click here to try-again"),
                  _c("br"),
                  _vm._v(" If the problem persists, please contact us "),
                ]),
              ]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "text-body1" },
          [
            _c("vue-numeric", {
              staticClass: "xdc-value",
              attrs: {
                value: _vm.xdcValue,
                "read-only": "",
                "decimal-separator": _vm.decimalSeparator,
                separator: _vm.thousandSeparator,
                precision: 2,
                "currency-symbol-position": "suffix",
                currency: "°C",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }