import { READY } from "@/constants/RequestStatus";

export default class CurveGroupDto {
  name: string;
  gvaPoints: number[] | undefined;
  totalEmissionPoints: number[];
  scope1EmissionPoints: number[] | undefined;
  scope2EmissionPoints: number[] | undefined;
  scope3EmissionPoints: number[] | undefined;
  status: string;

  constructor(
    name: string,
    gvaPoints: number[] | undefined = undefined,
    totalEmissionPoints: number[],
    scope1EmissionPoints: number[] | undefined = undefined,
    scope2EmissionPoints: number[] | undefined = undefined,
    scope3EmissionPoints: number[] | undefined = undefined
  ) {
    this.name = name;
    this.gvaPoints = gvaPoints;
    this.totalEmissionPoints = totalEmissionPoints;
    this.scope1EmissionPoints = scope1EmissionPoints;
    this.scope2EmissionPoints = scope2EmissionPoints;
    this.scope3EmissionPoints = scope3EmissionPoints;
    this.status = READY;
  }
}
