
import { Component, Prop, Vue } from "vue-property-decorator";
import SubscopeRate from "@/models/strategy/SubscopeRate";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { VueNumeric },
})
export default class SubScopeRateField extends Vue {
  @Prop({ required: true }) subscopeRate!: SubscopeRate;
  @Prop({ required: true }) isPercentGrowth!: boolean;
  @Prop({ required: true }) numberOfYears!: number;

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  get subscopeRateValue(): number {
    if (this.isPercentGrowth) {
      return this.subscopeRate.rateValue;
    } else {
      return this.subscopeRate.calculateTargetEmission(this.numberOfYears);
    }
  }

  set subscopeRateValue(newValue: number) {
    if (this.isPercentGrowth) {
      this.subscopeRate.rateValue = newValue;
    } else {
      this.subscopeRate.storePercentageEmissionRate(
        newValue,
        this.numberOfYears
      );
    }
  }

  onBlurHandler(e: Event): void {
    this.$emit("blur", e);
  }
}
