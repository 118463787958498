
import { Component, Vue } from "vue-property-decorator";
import Activity from "@/models/activity/Activity";
import ScenarioConfig from "@/models/ScenarioConfig";
import { BASELINE } from "@/constants/Constants";
import ScenarioBlock from "@/pages/scenario/components/ScenarioBlock.vue";
import ActivityToStrategyRow from "@/pages/scenario/components/ActivityToStrategyRow.vue";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";

@Component({
  components: {
    ScenarioBlock,
    ActivityToStrategyRow,
    Assumptions,
  },
})
export default class ScenarioPage extends Vue {
  get activities(): Activity[] {
    return this.$store.state.activities;
  }

  get scenarios(): ScenarioConfig[] {
    return this.$store.state.scenarios;
  }

  isBaseline(scenario: ScenarioConfig): boolean {
    return scenario.name === BASELINE;
  }

  addNewScenario(): void {
    this.$store.commit("createNewScenario");
    this.$store.commit("curvesNeedReload", true);
  }
}
