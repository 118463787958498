export default class YearRange {
  id: number;
  startYear: number;
  endYear: number;

  constructor(id: number, startYear: number, endYear: number) {
    this.id = id;
    this.startYear = startYear;
    this.endYear = endYear;
  }

  numberOfYears(): number {
    return this.endYear - this.startYear + 1;
  }
}
