import Config from "@/constants/Config";
import AuthGateway from "@/auth/services/AuthGateway";
import { successfulResponse } from "@/services/SuccesfulResponse";

export default class SettingsGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v3/se";

  static getXDCVersion(): Promise<string> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "get",
      "/xdc/engine-version",
      {},
      {}
    ).then((response) => {
      if (response === undefined || !successfulResponse(response)) {
        return "";
      }

      return response.data as string;
    });
  }

  static saveConfig(jsonState: string): Promise<boolean> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/configuration",
      {},
      {
        configuration: jsonState,
      }
    ).then((response) => {
      return !(response === undefined || !successfulResponse(response));
    });
  }

  static loadConfig(): Promise<string> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "get",
      "/configuration",
      {},
      {}
    ).then((response) => {
      if (response === undefined || !successfulResponse(response)) {
        return "";
      }

      return response.data.configuration as string;
    });
  }
}
