var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("table", [
    _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "name-col" }, [
          _vm._v(" " + _vm._s(_vm.parentStrategyName) + " "),
        ]),
        _c("th", [_vm._v("GVA")]),
        _c("th", [_vm._v("CO2e Total")]),
        _c("th", [_vm._v("CO2e Scope 1")]),
        _c("th", [_vm._v("CO2e Scope 2")]),
        _c("th", [_vm._v("CO2e Scope 3")]),
      ]),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.curveGroup.curves, function (cg) {
        return _c(
          "tr",
          { key: cg.name },
          [
            cg.isReady
              ? [
                  _c("td", { staticClass: "name-col" }, [
                    _vm._v(_vm._s(cg.name)),
                  ]),
                  _c(
                    "td",
                    [
                      cg.gvaPoints
                        ? _c("CheckboxWithColor", {
                            attrs: { curve: cg.gvaPoints },
                          })
                        : _c("v-checkbox", { attrs: { disabled: "" } }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      cg.totalEmissionPoints
                        ? _c("CheckboxWithColor", {
                            attrs: { curve: cg.totalEmissionPoints },
                          })
                        : _c("v-checkbox", { attrs: { disabled: "" } }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      cg.scope1EmissionPoints
                        ? _c("CheckboxWithColor", {
                            attrs: { curve: cg.scope1EmissionPoints },
                          })
                        : _c("v-checkbox", { attrs: { disabled: "" } }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      cg.scope2EmissionPoints
                        ? _c("CheckboxWithColor", {
                            attrs: { curve: cg.scope2EmissionPoints },
                          })
                        : _c("v-checkbox", { attrs: { disabled: "" } }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      cg.scope3EmissionPoints
                        ? _c("CheckboxWithColor", {
                            attrs: { curve: cg.scope3EmissionPoints },
                          })
                        : _c("v-checkbox", { attrs: { disabled: "" } }),
                    ],
                    1
                  ),
                ]
              : [
                  _c("td", { staticClass: "name-col" }, [
                    _vm._v(_vm._s(cg.name)),
                  ]),
                  _c(
                    "td",
                    { staticClass: "not-calculable", attrs: { colspan: "5" } },
                    [_vm._v("Not calculable")]
                  ),
                ],
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }