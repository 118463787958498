
import { Component, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import HighchartEeiCurveWrapper from "@/models/highcharts/HighchartEeiCurveWrapper";
import HighchartCurve from "@/models/highcharts/HighchartCurve";
import Highcharts from "highcharts";
import { groupBy } from "lodash";
import Activity from "@/models/activity/Activity";
import ReloadButton from "@/pages/shared/components/ReloadButton.vue";
import FetchCurvesMixin from "@/mixins/FetchCurvesMixin";
import { mixins } from "vue-class-component";
import CheckboxWithColor from "@/pages/shared/components/CheckboxWithColor.vue";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";
import { BIG_EMISSIONS_ERROR } from "@/constants/RequestStatus";
import { BIG_EMISSIONS, MISSING_INFORMATION } from "@/constants/ErrorMessages";

@Component({
  computed: {
    MISSING_INFORMATION() {
      return MISSING_INFORMATION;
    },
    BIG_EMISSIONS() {
      return BIG_EMISSIONS;
    },
  },
  components: {
    Assumptions,
    CheckboxWithColor,
    highcharts: Chart,
    ReloadButton,
  },
})
export default class PathwaysPage extends mixins(Vue, FetchCurvesMixin) {
  get chartOptions(): any {
    const decimalSep = this.$store.state.configStore.decimalSeparator;
    const thousandsSep = this.$store.state.configStore.separator;

    return {
      chart: {
        type: "line",
      },
      credits: {
        enabled: true,
        href: "",
        text: "right. based on science",
      },
      title: {
        text: "Emission Intensity Pathways",
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "vertical",
      },
      yAxis: [
        {
          title: {
            text: "Intensity [tCO2e/1 Mio. PPP USD]",
          },
        },
      ],
      series: this.selectedCurves,
      tooltip: {
        formatter: function (
          this: Highcharts.TooltipFormatterContextObject
        ): string {
          const yValue = this.point.y ? this.point.y : 0;

          return (
            this.point.series.name +
            "<br/>Year: " +
            this.point.x +
            "<br/>" +
            "Intensity: <b>" +
            Highcharts.numberFormat(yValue, 2, decimalSep, thousandsSep) +
            "</b> t CO2e / 1 Mio PPP USD"
          );
        },
      },
      exporting: {
        sourceWidth: 1920,
        sourceHeight: 800,
        scale: 2,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "separator",
              "downloadPNG",
              "downloadPDF",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
        csv: {
          decimalPoint: decimalSep,
          itemDelimiter: ";",
        },
      },
    };
  }

  selectedActivityGroup = null;

  get selectedCurves(): HighchartCurve[] {
    return this.$store.state.selectedEmissionIntensityCurves;
  }

  get isLoadingCurves(): boolean {
    return this.curveFetchCount !== 0;
  }

  get curveFetchCount(): number {
    return this.$store.state.ongoingEmissionPathwaysRequests;
  }

  get hasBigEmissionsError(): boolean {
    return this.$store.state.highChartEei.status == BIG_EMISSIONS_ERROR;
  }

  get allCurves(): HighchartEeiCurveWrapper[] {
    return this.$store.state.highChartEei.emissionIntensityCurves;
  }

  get allSectorCurves(): HighchartEeiCurveWrapper[] {
    return this.allCurves.filter((c) => c.isSectorCurve);
  }

  get allCompanyCurves(): HighchartEeiCurveWrapper[] {
    return this.allCurves.filter((c) => c.isCompanyCurve);
  }

  get allActivityGroups(): Record<string, HighchartEeiCurveWrapper[]> {
    const relevantCurves = this.allCurves.filter((c) => c.isActivityCurve);

    return groupBy(relevantCurves, "parentId");
  }

  nameForId(activityId: number): string {
    const found = this.$store.state.activities.find(
      (a: Activity) => a.id === activityId
    );

    if (found) {
      return found.name;
    }

    return "Activity";
  }

  get allScenarioCurves(): HighchartEeiCurveWrapper[] {
    return this.allCurves.filter((c) => c.isScenarioCurve);
  }

  get isNaceFilled(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  created(): void {
    if (this.$store.state.curvesReload && !this.isNaceFilled) {
      this.dispatchCurvesFetch();
    }
  }
}
