import Subscope from "@/models/Subscope";

export default class SubscopeEmissions extends Subscope {
  value: number;

  constructor(id: string, name: string, value: number) {
    super(id, name);
    this.value = value;
  }
}
