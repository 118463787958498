
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Assumptions extends Vue {
  get provider(): number {
    return this.$store.state.provider.title;
  }

  get ssp(): string {
    return this.$store.state.ssp.display;
  }

  get extension2100(): string {
    return this.$store.state.extension2100.display;
  }
}
