import YearRange from "@/models/strategy/YearRange";

export default class RangeUpdate {
  activityId: number;
  strategyId: number;
  updatedRanges: YearRange[];

  constructor(
    activityId: number,
    strategyId: number,
    updatedRanges: YearRange[]
  ) {
    this.activityId = activityId;
    this.strategyId = strategyId;
    this.updatedRanges = updatedRanges;
  }
}
