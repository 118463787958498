export default class NACESector {
  code: string;
  name: string;
  full: string;
  isDisabled: boolean;

  constructor(code: string, name: string, isDisabled = false) {
    this.code = code;
    this.name = name;
    this.full = `${code} - ${name}`;
    this.isDisabled = isDisabled;
  }
}
