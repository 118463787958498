import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckEmailMixin extends Vue {
  get email(): string {
    return this.$store.state.auth.email;
  }

  set email(newVal: string) {
    this.$store.commit("auth/setEmail", newVal);
  }

  emailIsFilled(): boolean | string {
    return this.email !== "" || "Field Required";
  }

  emailIsValid(): boolean | string {
    return this.emailTest(this.email) || "This is not a valid email";
  }

  emailTest(maybeEmail: string): boolean {
    const emailRegexPattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const matches = maybeEmail.match(emailRegexPattern);

    return (matches && matches.length > 0) || false;
  }
}
