import { COLORS } from "@/constants/ColorSwatches";
import { random } from "lodash";

export default class CurvePainter {
  private colorList = COLORS;

  private swatchCounter = random(0, COLORS.length);
  private counter = 0;

  nextColor(): string {
    const currentSwatchLength = this.colorList[this.swatchCounter].length;
    this.updateCount(currentSwatchLength);

    return this.colorList[this.swatchCounter][this.counter];
  }

  updateCount(swatchLength: number): void {
    const nextCount = this.counter + 1;

    if (nextCount >= swatchLength) {
      this.updateSwatchCounter();
      this.counter = 0;
    } else {
      this.counter = nextCount;
    }
  }

  updateSwatchCounter(): void {
    this.swatchCounter = (this.swatchCounter + 1) % COLORS.length;
  }

  finishedGroup(): void {
    this.counter = 0;
    this.updateSwatchCounter();
  }

  resetPalette(): void {
    this.counter = 0;
    this.swatchCounter = random(0, COLORS.length);
  }
}
