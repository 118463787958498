var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-col",
    { staticClass: "hoverable", on: { contextmenu: _vm.showContextMenu } },
    [
      _c(
        "div",
        { staticClass: "text-h6 d-flex align-center" },
        [
          _c("div", { staticClass: "scenario-title" }, [_vm._v("Scenario:")]),
          _c("v-text-field", {
            staticClass: "scenario-name",
            on: { blur: _vm.updateScenarioName },
            model: {
              value: _vm.localScenarioName,
              callback: function ($$v) {
                _vm.localScenarioName = $$v
              },
              expression: "localScenarioName",
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.activities, function (activity) {
        return _c("ActivityToStrategyRow", {
          key: activity.id,
          attrs: { activity: activity, "scenario-config": _vm.scenarioConfig },
        })
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.x,
            "position-y": _vm.y,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.copyThisScenario } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-content-copy")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Copy Scenario")]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.deleteThisScenario } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-delete")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Delete Scenario")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }