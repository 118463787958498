import { GrowthType } from "@/models/strategy/GrowthType";

export default class GrowthProjection {
  ebitda: number;
  growthType: GrowthType;
  personnelCost: number;
  scope1: number;
  scope2: number;
  scope3: number;
  startYear: number;
  endYear: number;

  constructor(
    ebitda: number,
    growthType: GrowthType,
    personnelCost: number,
    scope1: number,
    scope2: number,
    scope3: number,
    startYear: number,
    endYear: number
  ) {
    this.growthType = growthType;

    if (growthType === GrowthType.PERCENTAGE) {
      this.ebitda = ebitda / 100;
      this.personnelCost = personnelCost / 100;
      this.scope1 = scope1 / 100;
      this.scope2 = scope2 / 100;
      this.scope3 = scope3 / 100;
    } else {
      this.ebitda = ebitda;
      this.personnelCost = personnelCost;
      this.scope1 = scope1;
      this.scope2 = scope2;
      this.scope3 = scope3;
    }

    this.startYear = startYear;
    this.endYear = endYear;
  }

  calculateTargetValue(initialValue: number, numberOfYears: number): number {
    return initialValue * Math.pow(1 + this.ebitda / 100, numberOfYears);
  }
}
