var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "500px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        "data-testid": "edit-range-button",
                        color: "primary",
                        dark: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Edit Ranges ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Configure the Ranges")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "card-text" },
            [
              _c(
                "v-container",
                [
                  _vm._l(_vm.rangesCopy, function (range, idx) {
                    return _c(
                      "v-row",
                      {
                        key: range.startYear,
                        staticClass: "with-menu",
                        on: {
                          contextmenu: function ($event) {
                            return _vm.showContextMenu($event, idx)
                          },
                        },
                      },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              staticClass: "mt-0 pt-0 mx-auto",
                              staticStyle: { width: "60px" },
                              attrs: {
                                value: range.startYear,
                                "hide-details": "",
                                "single-line": "",
                                type: "number",
                                min: _vm.startYear,
                                max: _vm.targetYear,
                                disabled: range.startYear === _vm.startYear,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateRanges($event, idx)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", [_vm._v(" to")]),
                        _c("v-col", [
                          _vm._v(" " + _vm._s(range.endYear) + " "),
                        ]),
                      ],
                      1
                    )
                  }),
                  _vm.shouldBtnBeAdded
                    ? _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "data-testid": "add-range-button",
                                color: "primary",
                              },
                              on: { click: _vm.addRange },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-testid": "cancel-range-button",
                    color: "blue darken-1",
                    text: "",
                  },
                  on: { click: _vm.cancelSelection },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    "data-testid": "save-range-button",
                    color: "blue darken-1",
                    text: "",
                  },
                  on: { click: _vm.saveSelection },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.x,
            "position-y": _vm.y,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            [
              _vm.selectedIdx === 0
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Non-deletable (Initial Value)"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.removeRange(_vm.selectedIdx)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Delete Range")]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }