import { READY } from "@/constants/RequestStatus";

export default class BaselineXDC {
  name: string;
  xdcTotal: number;
  xdcScope1: number;
  xdcScope2: number;
  xdcScope3: number;
  status: string;

  constructor(
    name = "",
    baselineTotal = 0,
    baselineScope1 = 0,
    baselineScope2 = 0,
    baselineScope3 = 0,
    status = READY
  ) {
    this.name = name;
    this.xdcTotal = baselineTotal;
    this.xdcScope1 = baselineScope1;
    this.xdcScope2 = baselineScope2;
    this.xdcScope3 = baselineScope3;
    this.status = status;
  }
}
