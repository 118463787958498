export const BIG_EMISSIONS = {
  PART1:
    "Trying to follow IEA/NGFS reduction pathways necessitates an unrealistically high amount of negative emissions which lies outside of physically plausible processes.",
  PART2:
    "We therefore recommend to check a reduction path with OECM instead that does not use negative emissions in their scenarios.",
};

export const MISSING_INFORMATION = {
  HEADING: "Something missing...",
  PART1: "It seems that there are some necessary information missing!",
  PART2: "Please add a NACE Sector so we can show all the curves.",
};

export const SERVER_ERROR = {
  HEADING: "Connection Error",
  PART1: "We have trouble connecting to the server to get your result",
  PART2: "Please try again. Please contact us if the error does not go away.",
};
