export default class HighchartCurve {
  id: string;
  name: string;
  yAxis: number;
  data: Record<string, any>;
  color: string;

  constructor(
    id: string,
    name: string,
    yAxis = 0,
    data = {},
    color = "#00FF00"
  ) {
    this.id = id;
    this.name = name;
    this.yAxis = yAxis;
    this.data = data;
    this.color = color;
  }

  updateName(newName: string): void {
    this.name = newName;
  }
}
