export default class GVARate {
  initialEbitda: number;
  ebitdaRate: number;
  initialPersonnelCost: number;
  personnelCostsRate: number;

  constructor(
    initialEbitda = 0,
    ebitdaRate = 0,
    initialPersonnelCost = 0,
    personnelCostsRate = 0
  ) {
    this.initialEbitda = initialEbitda;
    this.ebitdaRate = ebitdaRate;
    this.initialPersonnelCost = initialPersonnelCost;
    this.personnelCostsRate = personnelCostsRate;
  }

  calculateTargetEbitda(numberOfYears: number): number {
    return (
      this.initialEbitda * Math.pow(1 + this.ebitdaRate / 100, numberOfYears)
    );
  }

  storePercentageEbitda(finalEbitda: number, numberOfYears: number): void {
    this.ebitdaRate =
      (Math.pow(finalEbitda / this.initialEbitda, 1 / numberOfYears) - 1) * 100;
  }

  calculateTargetPersonnelCost(numberOfYears: number): number {
    return (
      this.initialPersonnelCost *
      Math.pow(1 + this.personnelCostsRate / 100, numberOfYears)
    );
  }

  storePercentagePersonnelCost(
    finalPersonnelCost: number,
    numberOfYears: number
  ): void {
    this.personnelCostsRate =
      (Math.pow(
        finalPersonnelCost / this.initialPersonnelCost,
        1 / numberOfYears
      ) -
        1) *
      100;
  }
}
