
import { Component, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import BusinessActivityCurvesGroup from "@/models/trajectory/BusinessActivityCurvesGroup";
import HighchartCurve from "@/models/highcharts/HighchartCurve";
import { cloneDeep, groupBy } from "lodash";
import { ExtendedPoint } from "@/models/highcharts/ExtendedPoint";
import TrajectoriesSelectionTable from "@/pages/emissionsAndGva/components/TrajectoriesSelectionTable.vue";
import FetchCurvesMixin from "@/mixins/FetchCurvesMixin";
import { mixins } from "vue-class-component";
import ReloadButton from "@/pages/shared/components/ReloadButton.vue";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";
import { BIG_EMISSIONS_ERROR } from "@/constants/RequestStatus";
import { BIG_EMISSIONS, MISSING_INFORMATION } from "@/constants/ErrorMessages";

@Component({
  computed: {
    MISSING_INFORMATION() {
      return MISSING_INFORMATION;
    },
    BIG_EMISSIONS() {
      return BIG_EMISSIONS;
    },
  },
  components: {
    Assumptions,
    ReloadButton,
    TrajectoriesSelectionTable,
    highcharts: Chart,
  },
})
export default class EmissionsAndGvaPage extends mixins(Vue, FetchCurvesMixin) {
  get chartOptions(): any {
    const decimalSep = this.$store.state.configStore.decimalSeparator;
    const thousandsSep = this.$store.state.configStore.separator;
    const displayPercentMode = this.percentMode;

    return {
      chart: {
        type: "line",
      },
      credits: {
        enabled: true,
        href: "",
        text: "right. based on science",
      },
      title: {
        text: "Emissions and GVA of selected activities",
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "vertical",
      },
      yAxis: [
        {
          title: {
            text: "GVA [EUR]",
          },
          visible: !displayPercentMode,
        },
        {
          title: {
            text: "Emissions [tCO2e]",
          },
          opposite: true,
          visible: !displayPercentMode,
        },
        {
          title: {
            text: "Percent",
          },
          labels: {
            format: "{value}%",
          },
          visible: displayPercentMode,
        },
      ],
      series: this.selectedCurves,
      tooltip: {
        formatter: function (
          this: Highcharts.TooltipFormatterContextObject
        ): string {
          const initialString =
            this.point.series.name + "<br/>Year: " + this.point.x + "<br/>";

          const yAxis = displayPercentMode
            ? (this.point as unknown as ExtendedPoint).originalAxis
            : this.series.userOptions.yAxis;

          const yValue = displayPercentMode
            ? (this.point as unknown as ExtendedPoint).originalY
            : this.point.y
            ? this.point.y
            : 0;

          let finalString = "";

          if (yAxis === 0) {
            //GVA AXIS
            finalString =
              initialString +
              "GVA: <b>" +
              Highcharts.numberFormat(yValue, 2, decimalSep, thousandsSep) +
              "</b> EUR";
          } else if (yAxis === 1) {
            //EMISSIONS AXIS
            finalString =
              initialString +
              "Emissions: <b>" +
              Highcharts.numberFormat(yValue, 2, decimalSep, thousandsSep) +
              "</b> t CO2e";
          }

          if (displayPercentMode) {
            return (
              finalString +
              "<br/>Relative to base Year: <b>" +
              Highcharts.numberFormat(
                this.point.y ? this.point.y : 0,
                2,
                decimalSep,
                thousandsSep
              ) +
              "%</b>"
            );
          }

          return finalString;
        },
      },
      exporting: {
        sourceWidth: 1920,
        sourceHeight: 800,
        scale: 2,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "separator",
              "downloadPNG",
              "downloadPDF",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
        csv: {
          decimalPoint: decimalSep,
          itemDelimiter: ";",
        },
      },
    };
  }

  selectedCurvesGroup = this.curvesGroups;
  percentMode = false;

  hasBigEmissionsError = false;

  get curvesGroups(): Record<string, BusinessActivityCurvesGroup[]> {
    const curves: BusinessActivityCurvesGroup[] =
      this.$store.state.businessActivityCurves;
    this.hasBigEmissionsError = false;
    // Update legend to keep up with the name changes that might have happened
    curves.forEach((c) => {
      if (c.curves.some((x) => x.status === BIG_EMISSIONS_ERROR)) {
        this.hasBigEmissionsError = true;
      }

      const parentActivityName = this.activityName(c.parentActivityId);
      const parentStrategyName = this.strategyName(
        c.parentActivityId,
        c.parentStrategyId
      );

      c.updateCurvesChartLegend(parentActivityName, parentStrategyName);
    });

    return groupBy(
      curves,
      (c: BusinessActivityCurvesGroup) => c.parentActivityId
    );
  }

  get selectedCurves(): HighchartCurve[] {
    const selectedActCurves = cloneDeep(
      this.$store.state.selectedBusinessActivityCurves
    );

    if (this.percentMode) {
      return selectedActCurves.map(
        (curve: HighchartCurve) =>
          new HighchartCurve(
            curve.id,
            curve.name,
            2,
            curve.data.map((point: Record<string, any>) => {
              return {
                x: point.x,
                y: point.percent,
                originalY: point.y,
                originalAxis: curve.yAxis,
              };
            }),
            curve.color
          )
      );
    }

    return selectedActCurves;
  }

  activityName(id: number): string {
    return this.$store.getters.activityName(id);
  }

  strategyName(activityId: number, strategyId: number): string {
    return this.$store.getters.strategyName(activityId, strategyId);
  }

  get isLoadingCurves(): boolean {
    return this.curveFetchCount !== 0;
  }

  get curveFetchCount(): number {
    return this.$store.state.ongoingChartsRequest;
  }

  get isNaceFilled(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  mounted(): void {
    if (this.$store.state.curvesReload && !this.isNaceFilled) {
      this.dispatchCurvesFetch();
    }
  }
}
