
import { Component, Prop, Vue } from "vue-property-decorator";
import HighchartCurve from "@/models/highcharts/HighchartCurve";

@Component
export default class CheckboxWithColor extends Vue {
  @Prop({ required: true }) curve!: HighchartCurve;
  @Prop({ required: false, default: false, type: Boolean }) isEei!: boolean;

  dialog = false;

  get isSelected(): boolean {
    const selected = this.isEei
      ? this.$store.state.selectedEmissionIntensityCurves
      : this.$store.state.selectedBusinessActivityCurves;
    const index = selected.findIndex(
      (c: HighchartCurve) => c.id === this.curve.id
    );

    return index !== -1;
  }

  changed(checked: boolean): void {
    if (checked) {
      this.addToDisplayedCurves();
    } else {
      this.removeFromDisplayedCurves();
    }
  }

  addToDisplayedCurves(): void {
    const commitMethod = this.isEei ? "selectEeiCurve" : "selectChartCurve";

    this.$store.commit(commitMethod, this.curve);
  }

  removeFromDisplayedCurves(): void {
    const commitMethod = this.isEei ? "deselectEeiCurve" : "deselectChartCurve";

    this.$store.commit(commitMethod, this.curve);
  }

  get cssVars(): Record<string, string> {
    return {
      "--bg-color": this.curve.color,
    };
  }
}
