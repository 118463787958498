import { READY } from "@/constants/RequestStatus";

export default class EeiCurveDto {
  name: string;
  totalScopeEeiPathway: number[];
  status: string;

  constructor(name: string, totalScopeEeiPathway: number[]) {
    this.name = name;
    this.totalScopeEeiPathway = totalScopeEeiPathway;
    this.status = READY;
  }
}
