var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-numeric", {
    staticClass: "number-input",
    attrs: {
      "decimal-separator": _vm.decimalSeparator,
      separator: _vm.thousandSeparator,
      currency: _vm.isPercentGrowth ? "%" : "",
      "currency-symbol-position": "suffix",
      precision: _vm.isPercentGrowth ? 2 : 0,
      minus: true,
    },
    on: { blur: _vm.onBlurHandler },
    model: {
      value: _vm.subscopeRateValue,
      callback: function ($$v) {
        _vm.subscopeRateValue = $$v
      },
      expression: "subscopeRateValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }