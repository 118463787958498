export default class LocaleSetting {
  shortCode: string;
  displayName: string;
  decimalSeparator: string;
  separator: string;

  constructor(
    shortCode: string,
    displayName: string,
    decimalSeparator: string,
    separator: string
  ) {
    this.shortCode = shortCode;
    this.displayName = displayName;
    this.decimalSeparator = decimalSeparator;
    this.separator = separator;
  }
}
