var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "table",
    {
      staticClass: "strategy-table hoverable",
      attrs: { "data-testid": "strategy-info-table" },
      on: { contextmenu: _vm.showContextMenu },
    },
    [
      _c("thead", [
        _c("tr", { staticClass: "group-title" }, [
          _c("th"),
          _c("th", { attrs: { colspan: "2" } }),
          _c("th", { attrs: { colspan: "2" } }, [_vm._v("GVA (EUR)")]),
          _c("th", { attrs: { colspan: _vm.totalSubscopesCount + 3 } }, [
            _vm._v("Emissions (tCO2e)"),
          ]),
          _vm.isBaseline(_vm.listIndex)
            ? _c("th", { attrs: { colspan: "4" } }, [_vm._v("Baseline XDC")])
            : _c("th", { attrs: { colspan: "4" } }, [_vm._v("Scenario XDC")]),
        ]),
        _c("tr", { staticClass: "col-title" }, [
          _c(
            "th",
            { staticClass: "input-column" },
            [
              _vm.isBaseline(_vm.listIndex)
                ? _c("div", { staticClass: "strategy-name-input" }, [
                    _vm._v(" " + _vm._s(_vm.strategy.name) + " "),
                  ])
                : _c("v-text-field", {
                    staticClass: "strategy-name-input",
                    model: {
                      value: _vm.strategy.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.strategy, "name", $$v)
                      },
                      expression: "strategy.name",
                    },
                  }),
            ],
            1
          ),
          _c("th", [_vm._v("From")]),
          _c("th", [_vm._v("To")]),
          _c("th", { attrs: { colspan: "2" } }),
          _c("th", { attrs: { colspan: _vm.subscopes1Size + 1 } }, [
            _vm._v("Scope 1"),
          ]),
          _c("th", { attrs: { colspan: _vm.subscopes2Size + 1 } }, [
            _vm._v("Scope 2"),
          ]),
          _c("th", { attrs: { colspan: _vm.subscopes3Size + 1 } }, [
            _vm._v("Scope 3"),
          ]),
          _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Total")]),
          _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 1")]),
          _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 2")]),
          _c("th", { staticClass: "xdc-number-column" }, [_vm._v("Scope 3")]),
        ]),
        _c(
          "tr",
          { staticClass: "col-subtitle" },
          [
            _c("th"),
            _c("th", { attrs: { colspan: "2" } }),
            _c("th", [_vm._v("EBITDA")]),
            _c("th", [_vm._v("Personnel Costs")]),
            _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel1))]),
            _vm._l(_vm.scope1Subscopes, function (subscope) {
              return _c("th", { key: subscope.id }, [
                _vm._v(" " + _vm._s(subscope.name) + " "),
              ])
            }),
            _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel2))]),
            _vm._l(_vm.scope2Subscopes, function (subscope) {
              return _c("th", { key: subscope.id }, [
                _vm._v(" " + _vm._s(subscope.name) + " "),
              ])
            }),
            _c("th", [_vm._v(_vm._s(_vm.totalOrRestLabel3))]),
            _vm._l(_vm.scope3Subscopes, function (subscope) {
              return _c("th", { key: subscope.id }, [
                _vm._v(" " + _vm._s(subscope.name) + " "),
              ])
            }),
            _c("th", { staticClass: "xdc-number-column" }),
            _c("th", { staticClass: "xdc-number-column" }),
            _c("th", { staticClass: "xdc-number-column" }),
            _c("th", { staticClass: "xdc-number-column" }),
          ],
          2
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.strategy.yearRanges, function (range, idx) {
          return _c("StrategyInfoTableRangeRow", {
            key: range.startYear,
            attrs: {
              "is-baseline": _vm.isBaseline(_vm.listIndex),
              "is-percent-growth": _vm.isPercentGrowth,
              "range-index": idx,
              strategy: _vm.strategy,
            },
          })
        }),
        1
      ),
      _c("tfoot", [
        _c("tr", [
          _c(
            "td",
            [
              !_vm.isBaseline(_vm.listIndex)
                ? _c("RangeConfiguration", {
                    attrs: {
                      ranges: _vm.strategy.yearRanges,
                      "strategy-id": _vm.strategy.id,
                      "activity-id": _vm.activityId,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("td", { attrs: { colspan: "2" } }),
          _c("td", { staticClass: "border-left border-top" }),
          _c("td", { staticClass: "border-top" }),
          _c(
            "td",
            {
              staticClass: "border-left border-top weight-info",
              attrs: { colspan: _vm.subscopes1Size + 1 },
            },
            [_vm._v(" Weight: 100,00% ")]
          ),
          _c(
            "td",
            {
              staticClass: "border-left border-top weight-info",
              attrs: { colspan: _vm.subscopes2Size + 1 },
            },
            [_vm._v(" Weight: 50,00% ")]
          ),
          _c(
            "td",
            {
              staticClass: "border-left border-top weight-info",
              attrs: { colspan: _vm.subscopes3Size + 1 },
            },
            [_vm._v(" Weight: 50,00% ")]
          ),
          _c(
            "td",
            { staticClass: "border-left border-top xdc-number-column" },
            [
              _c(
                "div",
                { staticClass: "text-body2" },
                [
                  _c("vue-numeric", {
                    staticClass: "xdc-value",
                    attrs: {
                      "read-only": "",
                      "decimal-separator": _vm.decimalSeparator,
                      separator: _vm.thousandSeparator,
                      precision: 2,
                      "currency-symbol-position": "suffix",
                      currency: "°C",
                    },
                    model: {
                      value: _vm.strategy.baselineXDC.xdcTotal,
                      callback: function ($$v) {
                        _vm.$set(_vm.strategy.baselineXDC, "xdcTotal", $$v)
                      },
                      expression: "strategy.baselineXDC.xdcTotal",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("td", { staticClass: "border-top xdc-number-column" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "xdc-value",
                  attrs: {
                    "read-only": "",
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    precision: 2,
                    "currency-symbol-position": "suffix",
                    currency: "°C",
                  },
                  model: {
                    value: _vm.strategy.baselineXDC.xdcScope1,
                    callback: function ($$v) {
                      _vm.$set(_vm.strategy.baselineXDC, "xdcScope1", $$v)
                    },
                    expression: "strategy.baselineXDC.xdcScope1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("td", { staticClass: "border-top xdc-number-column" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "xdc-value",
                  attrs: {
                    "read-only": "",
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    precision: 2,
                    "currency-symbol-position": "suffix",
                    currency: "°C",
                  },
                  model: {
                    value: _vm.strategy.baselineXDC.xdcScope2,
                    callback: function ($$v) {
                      _vm.$set(_vm.strategy.baselineXDC, "xdcScope2", $$v)
                    },
                    expression: "strategy.baselineXDC.xdcScope2",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("td", { staticClass: "border-top xdc-number-column" }, [
            _c(
              "div",
              { staticClass: "text-body2" },
              [
                _c("vue-numeric", {
                  staticClass: "xdc-value",
                  attrs: {
                    "read-only": "",
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    precision: 2,
                    "currency-symbol-position": "suffix",
                    currency: "°C",
                  },
                  model: {
                    value: _vm.strategy.baselineXDC.xdcScope3,
                    callback: function ($$v) {
                      _vm.$set(_vm.strategy.baselineXDC, "xdcScope3", $$v)
                    },
                    expression: "strategy.baselineXDC.xdcScope3",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.x,
            "position-y": _vm.y,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _vm.isBaseline(_vm.listIndex)
            ? _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Non-deletable")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    { on: { click: _vm.copyStrategyIntoActivity } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-content-copy")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Copy into this Activity"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.copyStrategyToAll } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-note-multiple-outline")])],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v("Copy to all Activities"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.removeStrategy } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Delete Strategy")]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }