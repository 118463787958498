var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.readOnly
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.amount,
            expression: "amount",
          },
        ],
        ref: "numeric",
        attrs: {
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          type: "tel",
        },
        domProps: { value: _vm.amount },
        on: {
          blur: _vm.onBlurHandler,
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.amount = $event.target.value
            },
            _vm.onInputHandler,
          ],
          focus: _vm.onFocusHandler,
          change: _vm.onChangeHandler,
        },
      })
    : _c("span", { ref: "readOnly" }, [_vm._v(_vm._s(_vm.amount))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }