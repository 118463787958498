var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "text-h6" }, [_vm._v("Scenario Data")]),
              _c("Assumptions"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "primary", dark: "" },
                  model: {
                    value: _vm.selectedScenario,
                    callback: function ($$v) {
                      _vm.selectedScenario = $$v
                    },
                    expression: "selectedScenario",
                  },
                },
                _vm._l(_vm.scenarios, function (scenario) {
                  return _c("v-tab", { key: `${scenario.id}-scenario` }, [
                    _vm._v(" " + _vm._s(scenario.name) + " "),
                  ])
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.selectedScenario,
                    callback: function ($$v) {
                      _vm.selectedScenario = $$v
                    },
                    expression: "selectedScenario",
                  },
                },
                _vm._l(_vm.scenarios, function (scenario) {
                  return _c(
                    "v-tab-item",
                    { key: `${scenario.id}-scenario-data` },
                    [
                      _c("ActivityStrategyComboData", {
                        attrs: {
                          "activity-strategy-data":
                            _vm.activityStrategyData(scenario),
                        },
                      }),
                      _c("DataExportFab", {
                        attrs: {
                          "scenario-name": scenario.name,
                          "activity-strategy-data":
                            _vm.activityStrategyData(scenario),
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: true,
            value: _vm.isNaceFilled || _vm.isLoadingCurves,
          },
        },
        [
          _vm.isNaceFilled
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.HEADING) + " "),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm.isLoadingCurves
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [_vm._v("Loading data...")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "progress-info" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "text progress-info-text" },
                                [
                                  _vm._v(" Getting another "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.curveFetchCount)),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.curveFetchCount === 1
                                          ? "set"
                                          : "sets"
                                      ) +
                                      "of data for you "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }