var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-overlay",
    { attrs: { value: true } },
    [
      _c(
        "v-card",
        { staticClass: "elevation-12", attrs: { light: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [_c("v-toolbar-title", [_vm._v("Login form")])],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.valid && _vm.formOk()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "mdi-account",
                      name: "login",
                      label: "Email",
                      type: "text",
                      rules: [_vm.emailIsFilled, _vm.emailIsValid],
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      id: "password",
                      "prepend-icon": "mdi-lock",
                      name: "password",
                      label: "Password",
                      type: "password",
                      rules: [_vm.passwordRequired],
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c(
                    "router-link",
                    {
                      staticClass: "forgot-password-link",
                      attrs: { to: "/forgot-password" },
                    },
                    [_vm._v(" Forgot your Password? ")]
                  ),
                ],
                1
              ),
              _vm.hasErrorMessage
                ? _c(
                    "div",
                    { staticClass: "error-message red--text text-center" },
                    [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.valid },
                  on: { click: _vm.formOk },
                },
                [_vm._v("Login")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }