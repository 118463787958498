
import { Component, Vue } from "vue-property-decorator";
import Activity from "@/models/activity/Activity";
import StrategyInfoTable from "@/pages/strategy/components/StrategyInfoTable.vue";
import YearRange from "@/models/strategy/YearRange";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";

@Component({
  components: { Assumptions, StrategyInfoTable },
})
export default class StrategyPage extends Vue {
  isAbsolute = false;
  selectedActivity = this.activities[0];

  get activities(): Array<Activity> {
    return this.$store.state.activities;
  }

  get baseYear(): number {
    return this.$store.state.baseYear;
  }

  addNewStrategy(activityId: number): void {
    const baseYearRange = new YearRange(
      1,
      this.$store.state.baseYear + 1,
      this.$store.state.targetYear
    );
    this.$store.commit("addNewStrategy", {
      activityId: activityId,
      baseYearRange: baseYearRange,
    });
    this.$store.commit("curvesNeedReload", true);
  }
}
