import Subscope from "@/models/Subscope";

export default class SubscopeRate extends Subscope {
  initialEmissions: number;
  rateValue: number;

  constructor(id: string, name: string, rateValue = 0, initialEmissions = 0) {
    super(id, name);
    this.rateValue = rateValue;
    this.initialEmissions = initialEmissions;
  }

  calculateTargetEmission(numberOfYears: number): number {
    return (
      this.initialEmissions * Math.pow(1 + this.rateValue / 100, numberOfYears)
    );
  }

  storePercentageEmissionRate(
    finalEmission: number,
    numberOfYears: number
  ): void {
    const rate =
      (Math.pow(finalEmission / this.initialEmissions, 1 / numberOfYears) - 1) *
      100;

    if (!isNaN(rate)) {
      this.rateValue = rate;
    }
  }
}
