export default class Gva {
  ebitda: number;
  personnelCosts: number;

  constructor(ebitda: number, personnelCosts: number) {
    this.ebitda = ebitda;
    this.personnelCosts = personnelCosts;
  }

  total(): number {
    return Number(this.ebitda) + Number(this.personnelCosts);
  }
}
