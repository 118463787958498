var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "500px" },
      on: { "click:outside": _vm.cancelSelection },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", dark: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" Configure Emission Categories ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Configure Desired Categories")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "card-text" },
            [
              _c("div", { staticClass: "text-h6" }, [_vm._v("Scope 1")]),
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.scope1TotalRest,
                        callback: function ($$v) {
                          _vm.scope1TotalRest = $$v
                        },
                        expression: "scope1TotalRest",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Total Emissions",
                          value: "total1",
                          disabled: "",
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "Other Emissions",
                          value: "other1",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                _vm._l(_vm.subscopes1, function (scope) {
                  return _c("v-checkbox", {
                    key: `scope${scope.id}`,
                    attrs: { label: scope.name, value: scope, dense: "" },
                    model: {
                      value: _vm.selectedSubScopes1,
                      callback: function ($$v) {
                        _vm.selectedSubScopes1 = $$v
                      },
                      expression: "selectedSubScopes1",
                    },
                  })
                }),
                1
              ),
              _c("v-divider"),
              _c("div", { staticClass: "text-h6" }, [_vm._v("Scope 2")]),
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.scope2TotalRest,
                        callback: function ($$v) {
                          _vm.scope2TotalRest = $$v
                        },
                        expression: "scope2TotalRest",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Total Emissions",
                          value: "total2",
                          disabled: "",
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "Other Emissions",
                          value: "other2",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                _vm._l(_vm.subscopes2, function (scope) {
                  return _c("v-checkbox", {
                    key: `scope${scope.id}`,
                    attrs: { label: scope.name, value: scope, dense: "" },
                    model: {
                      value: _vm.selectedSubScopes2,
                      callback: function ($$v) {
                        _vm.selectedSubScopes2 = $$v
                      },
                      expression: "selectedSubScopes2",
                    },
                  })
                }),
                1
              ),
              _c("v-divider"),
              _c("div", { staticClass: "text-h6" }, [_vm._v("Scope 3")]),
              _c(
                "div",
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.scope3TotalRest,
                        callback: function ($$v) {
                          _vm.scope3TotalRest = $$v
                        },
                        expression: "scope3TotalRest",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Total Emissions",
                          value: "total3",
                          disabled: "",
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "Other Emissions",
                          value: "other3",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                _vm._l(_vm.subscopes3, function (scope) {
                  return _c("v-checkbox", {
                    key: `scope${scope.id}`,
                    attrs: { label: scope.name, value: scope, dense: "" },
                    model: {
                      value: _vm.selectedSubScopes3,
                      callback: function ($$v) {
                        _vm.selectedSubScopes3 = $$v
                      },
                      expression: "selectedSubScopes3",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.cancelSelection },
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.saveSelection },
                },
                [_vm._v(" Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }