
import { Component, Vue } from "vue-property-decorator";
import { COUNTRIES } from "@/constants/ISOCountries";
import { BASE_YEARS, TARGET_YEARS } from "@/constants/Constants";
import ISOCountry from "@/models/ISOCountry";
import NACESector from "@/models/NACESector";
import Provider from "@/models/Provider";
import { PROVIDERS } from "@/constants/Providers";
import { SSPs } from "@/constants/SSPs";
import { EXTENSION_2100 } from "@/constants/Extension2100s";
import Config from "@/constants/Config";

@Component
export default class BasicInformation extends Vue {
  targetYears = TARGET_YEARS;
  baseYears = BASE_YEARS.reverse();
  countries = COUNTRIES;
  providerList = PROVIDERS;
  sspList = SSPs;
  extension2100List = EXTENSION_2100;

  companyName = this.$store.state.companyName;
  countrySelection = this.$store.state.country;
  baseYearSelection = this.$store.state.baseYear;
  targetYearSelection = this.$store.state.targetYear;
  naceSector = this.$store.state.naceSector;
  providerSelection = this.$store.state.provider;
  sspSelection = this.$store.state.ssp;
  extension2100Selection = this.$store.state.extension2100;

  get targetYearFeatureToggle(): boolean {
    return Config.TargetYearFeatureToggle;
  }

  get naceList(): NACESector[] {
    return this.$store.state.naceSectors;
  }

  mounted(): void {
    this.$store.dispatch("fetchNaceSectors");
  }

  setCompanyName(newName: string): void {
    this.$store.commit("changeCompanyName", newName);
    this.saveSession();
  }

  setBaseYearSelection(newBaseYear: number): void {
    if (this.$store.state.baseYear !== newBaseYear) {
      this.$store.commit("changeBaseYear", newBaseYear);
      this.recalculateXDC();
    }
  }

  setTargetYearSelection(newYear: number): void {
    if (this.$store.state.targetYear !== newYear) {
      this.$store.commit("changeTargetYear", newYear);
      this.recalculateXDC();
    }
  }

  setCountrySelection(newCountry: ISOCountry): void {
    if (this.$store.state.country !== newCountry) {
      this.$store.commit("changeCountry", newCountry);
      this.recalculateXDC();
    }
  }

  setNaceSector(newValue: NACESector): void {
    this.$store.commit(
      "sessionStore/setShowNaceMissingAlert",
      !newValue || newValue.code === ""
    );

    if (this.$store.state.naceSector !== newValue) {
      this.$store.commit(
        "changeNaceSector",
        newValue ?? new NACESector("", "")
      );
      this.recalculateXDC();
    }
  }

  setProvider(newValue: Provider): void {
    if (this.$store.state.provider !== newValue) {
      this.$store.commit("changeProvider", newValue);
      this.recalculateXDC();
    }
  }

  setSSP(newValue: Provider): void {
    if (this.$store.state.ssp !== newValue) {
      this.$store.commit("changeSSP", newValue);
      this.recalculateXDC();
    }
  }

  setExtension2100(newValue: Provider): void {
    if (this.$store.state.ssp !== newValue) {
      this.$store.commit("changeExtension2100", newValue);
      this.recalculateXDC();
    }
  }

  recalculateXDC(): void {
    this.$store.dispatch("makeXDCRequest", this.$store.state.activities);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  saveSession(): void {
    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("sessionStore/saveSession");
    }
  }

  searchNace(item: NACESector, queryText: string): boolean {
    return item.full.toLowerCase().includes(queryText.toLowerCase());
  }
}
