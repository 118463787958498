import HighchartEeiCurveWrapper from "@/models/highcharts/HighchartEeiCurveWrapper";
import { READY } from "@/constants/RequestStatus";

export default class HighChartEei {
  emissionIntensityCurves: Array<HighchartEeiCurveWrapper>;
  status: string;
  constructor(
    emissionIntensityCurves: Array<HighchartEeiCurveWrapper> = [],
    status: string = READY
  ) {
    this.emissionIntensityCurves = emissionIntensityCurves;
    this.status = status;
  }
}
