
import { Component, Vue } from "vue-property-decorator";
import { ALL_SUBSCOPE1 } from "@/constants/Subscope1";
import { ALL_SUBSCOPE2 } from "@/constants/Subscope2";
import {
  ALL_SUBSCOPE3_DOWNSTREAM,
  ALL_SUBSCOPE3_UPSTREAM,
} from "@/constants/Subscope3";

// [SubScopeFeatureToggle] remove whole component and all its uses if subScope are not re-enabled
@Component
export default class SubscopeSelection extends Vue {
  showDialog = false;
  subscopes1 = ALL_SUBSCOPE1;
  subscopes2 = ALL_SUBSCOPE2;
  subscopes3 = ALL_SUBSCOPE3_UPSTREAM.concat(ALL_SUBSCOPE3_DOWNSTREAM);

  selectedSubScopes1 = this.$store.state.selectedSubScopes1;
  selectedSubScopes2 = this.$store.state.selectedSubScopes2;
  selectedSubScopes3 = this.$store.state.selectedSubScopes3;

  get scope1TotalRest(): string {
    return this.selectedSubScopes1.length === 0 ? "total1" : "other1";
  }

  get scope2TotalRest(): string {
    return this.selectedSubScopes2.length === 0 ? "total2" : "other2";
  }

  get scope3TotalRest(): string {
    return this.selectedSubScopes3.length === 0 ? "total3" : "other3";
  }

  cancelSelection(): void {
    this.selectedSubScopes1 = this.$store.state.selectedSubScopes1;
    this.selectedSubScopes2 = this.$store.state.selectedSubScopes2;
    this.selectedSubScopes3 = this.$store.state.selectedSubScopes3;
    this.showDialog = false;
  }

  saveSelection(): void {
    this.$store.commit("updateSubscope1List", this.selectedSubScopes1);
    this.$store.commit("updateSubscope2List", this.selectedSubScopes2);
    this.$store.commit("updateSubscope3List", this.selectedSubScopes3);
    this.showDialog = false;
  }
}
