
import { Component, Vue } from "vue-property-decorator";
import { ExportableState, getExportState } from "@/store/main/state";
import SessionItem from "@/models/session/SessionItem";
import SessionItemEntry from "@/pages/sessions/components/SessionItemEntry.vue";
import SessionImportParser from "@/services/SessionImportParser";

@Component({
  components: { SessionItemEntry },
})
export default class SessionsPage extends Vue {
  file = null;
  showLoadDialog = false;
  showDeleteDialog = false;
  selectedSessionId = "";

  get allSessions(): SessionItem[] {
    return this.$store.state.sessionStore.sessionsList;
  }

  get currentSessionId(): string {
    return this.$store.state.sessionStore.currentSessionId;
  }

  get activities(): string {
    return this.$store.state.activities;
  }

  selectSession(id: string): void {
    this.selectedSessionId = id;

    if (this.currentSessionId !== id) {
      this.showLoadDialog = true;
    }
  }

  exportCurrentSession(): void {
    const exportStore = getExportState();
    const jsonStr = JSON.stringify(exportStore);
    const sessionName = this.$store.state.companyName;

    this.exportSession(sessionName, jsonStr);
  }

  exportSession(sessionName: string, sessionJsonString: string): void {
    const filename = `${sessionName}-${new Date().toDateString()}-data.right`;

    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(sessionJsonString)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  onImportClick(): void {
    (this.$refs.fileUpload as HTMLElement).click();
  }

  importSession(event: Event): void {
    const reader = new FileReader();
    const file = this.getFile(event);

    if (!file.name.toLowerCase().endsWith(".right")) {
      this.$store.commit(
        "routeStore/setErrorMessage",
        "Please upload a valid file with <strong>.right</strong> extension"
      );

      return;
    }

    reader.onload = async () => {
      const fileText = reader.result;

      const jsonString = fileText?.toString();

      this.setNewState(jsonString);

      this.file = null;
    };

    reader.readAsText(file);
  }

  private getFile(event: Event): any {
    const target = event.target as HTMLInputElement;
    const files = target.files as any;

    return files[0];
  }

  loadSession(): void {
    const session = this.allSessions.find(
      (session) => session.sessionId === this.selectedSessionId
    );

    this.setNewState(session?.session);
  }

  setNewState(session: string | undefined): void {
    if (session) {
      const newState: ExportableState =
        SessionImportParser.parseLoadedSessionObject(session);
      this.$store.commit("uploadState", newState);

      if (newState.naceSector.code !== "") {
        this.$store.commit("sessionStore/setShowNaceMissingAlert", false);
      }
    }

    this.$store.commit("sessionStore/setNewSessionId", this.selectedSessionId);
    this.$store.dispatch("makeXDCRequest", this.activities);
    this.$store.commit("curvesNeedReload", true);
    this.showLoadDialog = false;
    this.selectedSessionId = "";
    this.$router.push("Company");
  }

  deleteSession(): void {
    this.$store.dispatch("sessionStore/deleteSession", this.selectedSessionId);
    this.showDeleteDialog = false;
    this.selectedSessionId = "";
  }

  mounted(): void {
    this.$nextTick(() => {
      this.$store.dispatch("sessionStore/loadAllSessions");
    });
  }

  get errorMessage(): string {
    return this.$store.state.routeStore.errorMessage;
  }

  hasErrorMessage(): boolean {
    return this.$store.state.routeStore.errorMessage !== "";
  }
}
