import { GetterTree } from "vuex";
import { State } from "@/store/main/state";
import Activity from "@/models/activity/Activity";
import Strategy from "@/models/strategy/Strategy";
import { ERROR, SERVER_NOT_AVAILABLE } from "@/constants/RequestStatus";

export const getters: GetterTree<State, State> = {
  activityName:
    (state: State) =>
    (id: number): string => {
      const found = state.activities.find((a: Activity) => a.id === id);

      return found ? found.name : "";
    },
  strategyName:
    (state: State) =>
    (activityId: number, strategyId: number): string => {
      const activityFound = state.activities.find(
        (a: Activity) => a.id === activityId
      );

      if (activityFound) {
        const strategyFound = activityFound.strategies.find(
          (s: Strategy) => s.id === strategyId
        );

        return strategyFound ? strategyFound.name : "";
      }

      return "";
    },
  trajectoriesWithError: (state: State) => (): number => {
    let count = 0;
    state.businessActivityCurves.forEach(
      (bac) => (count += bac.amountOfErrorStates())
    );

    return count;
  },
  pathwaysWithError: (state: State) => (): number => {
    return state.highChartEei.emissionIntensityCurves.filter(
      (eic) => eic.status === ERROR || eic.status === SERVER_NOT_AVAILABLE
    ).length;
  },
  activityById:
    (state: State) =>
    (id: number): Activity | undefined => {
      return state.activities.find((activity) => activity.id === id);
    },
};
