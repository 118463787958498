
import { Component, Prop, Vue } from "vue-property-decorator";
import FetchCurvesMixin from "@/mixins/FetchCurvesMixin";
import { mixins } from "vue-class-component";

@Component
export default class ReloadButton extends mixins(Vue, FetchCurvesMixin) {
  @Prop({ required: true, default: "Reload" }) reloadTooltipText!: string;
  @Prop({ required: true, default: "curves" }) type!: string;

  clickHandler(): void {
    if (this.type == "curves") {
      if (!this.$store.state.ongoingChartsRequest) {
        this.dispatchCurvesFetch();
      }
    } else if (this.type === "xdc") {
      this.dispatchCurvesFetch();
    }
  }
}
