var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _vm.isAuthenticated
        ? _c("Navigation", { attrs: { "is-visible": _vm.drawer } })
        : _vm._e(),
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "primary", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "ml-1",
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("div", { staticClass: "d-flex align-center ml-6" }, [
            _c("div", { staticClass: "text-h5" }, [
              _vm._v("right. XDC Scenario Explorer"),
            ]),
          ]),
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: {
                                href: "mailto:se-support@right-basedonscience.de",
                                target: "_blank",
                                text: "",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("span", [_vm._v("Contact")])]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(" Click here to open your email software "),
                _c("br"),
                _vm._v("of choice and send us a message! "),
              ]),
            ]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2",
                              attrs: { text: "" },
                              on: { click: _vm.logout },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-logout-variant")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Logout")])]
          ),
        ],
        1
      ),
      _c("v-main", [_c("router-view", { key: _vm.$route.fullPath })], 1),
      _c(
        "v-alert",
        {
          staticClass: "alert-session-save",
          attrs: { border: "left", type: "success" },
          model: {
            value: _vm.showAlert,
            callback: function ($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert",
          },
        },
        [_vm._v(" Session Saved! ")]
      ),
      _vm.isAuthenticated
        ? _c(
            "v-alert",
            {
              staticClass: "alert-data-error",
              attrs: { type: "warning" },
              model: {
                value: _vm.isNegativeEbitda,
                callback: function ($$v) {
                  _vm.isNegativeEbitda = $$v
                },
                expression: "isNegativeEbitda",
              },
            },
            [
              _vm._v(" Growth-projections cannot be computed with a "),
              _c("b", [_vm._v("negative EBITDA")]),
              _vm._v("! "),
            ]
          )
        : _vm._e(),
      _vm.isAuthenticated
        ? _c(
            "v-alert",
            {
              staticClass: "alert-data-error",
              attrs: { type: "error" },
              model: {
                value: _vm.showNaceMissingAlert,
                callback: function ($$v) {
                  _vm.showNaceMissingAlert = $$v
                },
                expression: "showNaceMissingAlert",
              },
            },
            [
              _c("b", [_vm._v("Set NACE Sector")]),
              _vm._v(" for XDC to be computed "),
            ]
          )
        : _vm._e(),
      _c("AppUpdateAlert"),
      _c("AppUpdateOverlay"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }