import Config from "@/constants/Config";
import AuthGateway from "@/auth/services/AuthGateway";
import { successfulResponse } from "@/services/SuccesfulResponse";
import { ExportableState } from "@/store/main/state";
import SessionItem from "@/models/session/SessionItem";

export default class SessionGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v3/se/session";

  static listSessions(): Promise<SessionItem[]> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "get",
      "",
      {},
      {}
    ).then((response) => {
      if (response === undefined || !successfulResponse(response)) {
        return [];
      }

      return response.data as SessionItem[];
    });
  }

  static saveSession(
    companyName: string,
    sessionId: string,
    exportState: ExportableState
  ): Promise<string> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "",
      {},
      {
        companyName: companyName,
        sessionId: sessionId,
        session: JSON.stringify(exportState),
      }
    ).then((response) => {
      if (response === undefined || !successfulResponse(response)) {
        return "";
      }

      return response.data as string;
    });
  }

  static deleteSession(sessionId: string): Promise<boolean> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "delete",
      `/${sessionId}`,
      {},
      {}
    ).then((response) => {
      return !(response === undefined || !successfulResponse(response));
    });
  }
}
