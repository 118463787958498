var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "text-h5" },
                [
                  _vm._v(" Emission Pathways "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        to: {
                          path: "/faq",
                          query: { q: "howEmissionScopeCounted" },
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-information-outline")])],
                    1
                  ),
                ],
                1
              ),
              _c("Assumptions"),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex reload" },
            [
              _c("ReloadButton", {
                attrs: {
                  type: "curves",
                  "reload-tooltip-text": "Reload pathways",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "text-h6" },
              [
                _c(
                  "v-tabs",
                  { attrs: { "fixed-tabs": "" } },
                  [_c("v-tab", [_vm._v("References")])],
                  1
                ),
              ],
              1
            ),
            _c("table", { staticClass: "curves-wrapper" }, [
              _c(
                "tbody",
                _vm._l(_vm.allSectorCurves, function (curve, idx) {
                  return _c(
                    "tr",
                    {
                      key: `${idx}-sector`,
                      staticClass: "d-flex align-center",
                    },
                    [
                      _c("td", { staticClass: "flex-grow-1" }, [
                        _vm._v(_vm._s(curve.name)),
                      ]),
                      curve.isReady
                        ? _c(
                            "td",
                            [
                              _c("CheckboxWithColor", {
                                attrs: {
                                  "is-eei": "",
                                  curve: curve.eeiPathway,
                                },
                              }),
                            ],
                            1
                          )
                        : _c("td", [
                            _c("div", { staticClass: "not-calculable" }, [
                              _vm._v("Not calculable"),
                            ]),
                          ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("v-col", [
            _c(
              "div",
              { staticClass: "text-h6" },
              [
                _c(
                  "v-tabs",
                  { attrs: { "fixed-tabs": "" } },
                  [_c("v-tab", [_vm._v("Company")])],
                  1
                ),
              ],
              1
            ),
            _c("table", { staticClass: "curves-wrapper" }, [
              _c(
                "tbody",
                [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "section-header text-overline",
                        attrs: { colspan: "2" },
                      },
                      [_vm._v(" Scenarios ")]
                    ),
                  ]),
                  _c("v-divider"),
                  _vm._l(_vm.allScenarioCurves, function (curve, idx) {
                    return _c(
                      "tr",
                      {
                        key: `${idx}-scenario`,
                        staticClass: "d-flex align-center",
                      },
                      [
                        _c("td", { staticClass: "flex-grow-1" }, [
                          _vm._v(_vm._s(curve.name)),
                        ]),
                        curve.isReady
                          ? _c(
                              "td",
                              [
                                curve.status
                                  ? _c("CheckboxWithColor", {
                                      attrs: {
                                        "is-eei": "",
                                        curve: curve.eeiPathway,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("td", [
                              _c("div", { staticClass: "not-calculable" }, [
                                _vm._v("Not calculable"),
                              ]),
                            ]),
                      ]
                    )
                  }),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "section-header text-overline",
                        attrs: { colspan: "2" },
                      },
                      [_vm._v("Target")]
                    ),
                  ]),
                  _c("v-divider"),
                  _vm._l(_vm.allCompanyCurves, function (curve, idx) {
                    return _c(
                      "tr",
                      {
                        key: `${idx}-companyTarget`,
                        staticClass: "d-flex align-center",
                      },
                      [
                        _c("td", { staticClass: "flex-grow-1" }, [
                          _vm._v(_vm._s(curve.name)),
                        ]),
                        curve.isReady
                          ? _c(
                              "td",
                              [
                                curve.status
                                  ? _c("CheckboxWithColor", {
                                      attrs: {
                                        "is-eei": "",
                                        curve: curve.eeiPathway,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("td", [
                              _c("div", { staticClass: "not-calculable" }, [
                                _vm._v("Not calculable"),
                              ]),
                            ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.selectedActivityGroup,
                    callback: function ($$v) {
                      _vm.selectedActivityGroup = $$v
                    },
                    expression: "selectedActivityGroup",
                  },
                },
                _vm._l(_vm.allActivityGroups, function (curves, key) {
                  return _c("v-tab", { key: key }, [
                    _vm._v(" " + _vm._s(_vm.nameForId(Number(key))) + " "),
                  ])
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "curves-wrapper",
                  model: {
                    value: _vm.selectedActivityGroup,
                    callback: function ($$v) {
                      _vm.selectedActivityGroup = $$v
                    },
                    expression: "selectedActivityGroup",
                  },
                },
                _vm._l(_vm.allActivityGroups, function (curves, key) {
                  return _c("v-tab-item", { key: key }, [
                    _c("table", [
                      _c(
                        "tbody",
                        _vm._l(curves, function (curve, idx) {
                          return _c(
                            "tr",
                            {
                              key: `${idx}-activity`,
                              staticClass: "d-flex align-center",
                            },
                            [
                              _c("td", { staticClass: "flex-grow-1" }, [
                                _vm._v(_vm._s(curve.name)),
                              ]),
                              curve.isReady
                                ? _c(
                                    "td",
                                    [
                                      curve.status
                                        ? _c("CheckboxWithColor", {
                                            attrs: {
                                              "is-eei": "",
                                              curve: curve.eeiPathway,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "not-calculable" },
                                      [_vm._v("Not calculable")]
                                    ),
                                  ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: true,
            value:
              _vm.isNaceFilled ||
              _vm.isLoadingCurves ||
              _vm.hasBigEmissionsError,
          },
        },
        [
          _vm.isNaceFilled
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.HEADING) + " "),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART1) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.MISSING_INFORMATION.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm.isLoadingCurves
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [_vm._v("Loading all curves...")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "progress-info" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 3,
                                  color: "primary",
                                  indeterminate: "",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "text progress-info-text" },
                                [
                                  _vm._v(" Getting another "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.curveFetchCount)),
                                  ]),
                                  _vm._v(
                                    " curve " +
                                      _vm._s(
                                        _vm.curveFetchCount === 1
                                          ? "group"
                                          : "groups"
                                      ) +
                                      " for you "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.hasBigEmissionsError
            ? _c(
                "v-card",
                { attrs: { light: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "Pathways could not be calculated for the given input"
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.BIG_EMISSIONS.PART1) + " "),
                    _c("br"),
                    _vm._v(" > " + _vm._s(_vm.BIG_EMISSIONS.PART2) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }