var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-overlay",
    { attrs: { value: true } },
    [
      _c(
        "v-card",
        { staticClass: "elevation-12", attrs: { light: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _vm.isUpdatePasswordRoute
                ? _c("v-toolbar-title", [_vm._v(" Update your password ")])
                : _c("v-toolbar-title", [_vm._v("Set your password")]),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.isUpdatePasswordRoute
                ? _c("div", [
                    _vm._v(
                      " For security reasons, please choose a new password. "
                    ),
                  ])
                : _vm._e(),
              _c(
                "v-form",
                {
                  ref: "form",
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.isFormValid && _vm.formOk()
                    },
                  },
                  model: {
                    value: _vm.isFormValid,
                    callback: function ($$v) {
                      _vm.isFormValid = $$v
                    },
                    expression: "isFormValid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      id: "newPassword",
                      "prepend-icon": "mdi-lock",
                      name: "newPassword",
                      label: "New Password",
                      type: "password",
                      rules: [
                        _vm.isPasswordNotEmpty,
                        _vm.isPasswordAboveMinimumLength,
                        _vm.isPasswordBelowMaximumLength,
                        _vm.isUppercaseIncluded,
                        _vm.isLowercaseIncluded,
                        _vm.isNumberIncluded,
                        _vm.isNonAlphaNumericCharacterIncluded,
                      ],
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function ($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      id: "repeatPassword",
                      "prepend-icon": "mdi-lock",
                      name: "repeatPassword",
                      label: "Repeat Password",
                      type: "password",
                      rules: [
                        _vm.isRepeatPasswordNotEmpty,
                        _vm.arePasswordsEqual,
                      ],
                    },
                    model: {
                      value: _vm.repeatPassword,
                      callback: function ($$v) {
                        _vm.repeatPassword = $$v
                      },
                      expression: "repeatPassword",
                    },
                  }),
                ],
                1
              ),
              _vm.hasMessage
                ? _c("div", { staticClass: "message red--text text-center" }, [
                    _vm._v(" " + _vm._s(_vm.message) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.isFormValid,
                    "data-testid": "set-password-button",
                  },
                  on: { click: _vm.formOk },
                },
                [_vm._v("Set Password ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }