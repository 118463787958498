
import { Component, Prop, Vue } from "vue-property-decorator";
import SubscopeEmissions from "@/models/activity/SubscopeEmissions";
import Strategy from "@/models/strategy/Strategy";
import RangeConfiguration from "@/pages/strategy/components/RangeConfiguration.vue";
import StrategyInfoTableRangeRow from "@/pages/strategy/components/StrategyInfoTableRangeRow.vue";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { StrategyInfoTableRangeRow, RangeConfiguration, VueNumeric },
})
export default class StrategyInfoTable extends Vue {
  @Prop({ required: true }) strategy!: Strategy;
  @Prop({ required: true }) activityId!: number;
  @Prop({ required: true }) listIndex!: number;
  @Prop({ required: true }) isPercentGrowth!: boolean;

  get startYear(): number {
    return this.$store.state.baseYear + 1;
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope1Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes1;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope2Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes2;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get scope3Subscopes(): SubscopeEmissions[] {
    return this.$store.state.selectedSubScopes3;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes1Size(): number {
    return this.scope1Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes2Size(): number {
    return this.scope2Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get subscopes3Size(): number {
    return this.scope3Subscopes.length;
  }

  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  get totalSubscopesCount(): number {
    return this.subscopes1Size + this.subscopes2Size + this.subscopes3Size;
  }

  showMenu = false;
  x = 0;
  y = 0;

  showContextMenu(e: MouseEvent, idx: number): void {
    e.preventDefault();

    if (!this.isBaseline(idx)) {
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }

  private isBaseline(idx: number): boolean {
    return idx === 0;
  }

  // [SubScopeFeatureToggle] rewrite to use only total if subscopes not re-enabled
  get totalOrRestLabel1(): string {
    return this.strategy.scope1Rate[0].subscopeRates.length === 0
      ? "Total"
      : "Other";
  }

  // [SubScopeFeatureToggle] rewrite to use only total if subscopes not re-enabled
  get totalOrRestLabel2(): string {
    return this.strategy.scope2Rate[0].subscopeRates.length === 0
      ? "Total"
      : "Other";
  }

  // [SubScopeFeatureToggle] rewrite to use only total if subscopes not re-enabled
  get totalOrRestLabel3(): string {
    return this.strategy.scope3Rate[0].subscopeRates.length === 0
      ? "Total"
      : "Other";
  }

  copyStrategyIntoActivity(): void {
    this.$store.commit("copyStrategyIntoActivity", this.strategy);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  copyStrategyToAll(): void {
    this.$store.commit("copyStrategyToAll", this.strategy);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  removeStrategy(): void {
    this.$store.commit("removeStrategy", this.strategy);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  mounted(): void {
    if (!this.isBaseline(this.listIndex)) {
      this.$store.dispatch("makeStrategyXDCRequest", this.strategy);
    }
  }

  saveSession(): void {
    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("sessionStore/saveSession");
    }
  }
}
