import SessionItem from "@/models/session/SessionItem";
import SessionGateway from "@/services/SessionGateway";
import { getExportState, State } from "@/store/main/state";
import SessionStoreState from "@/store/session/SessionStoreState";

export default {
  namespaced: true,
  state: {
    sessionsList: Array<SessionItem>(),
    currentSessionId: "",
    showSavedAlert: false,
    showNaceMissingAlert: false,
  },
  mutations: {
    setNewSessionId(state: SessionStoreState, sessionId: string): void {
      state.currentSessionId = sessionId;
    },
    setListOfSessions(state: SessionStoreState, items: SessionItem[]): void {
      state.sessionsList = items;
    },
    setShowAlert(state: SessionStoreState): void {
      state.showSavedAlert = true;

      setTimeout(() => {
        state.showSavedAlert = false;
      }, 2000);
    },
    setShowNaceMissingAlert(
      state: SessionStoreState,
      naceMissing: boolean
    ): void {
      state.showNaceMissingAlert = naceMissing;
    },
  },
  actions: {
    saveSession({
      rootState,
      state,
      dispatch,
      commit,
    }: {
      rootState: State;
      state: SessionStoreState;
      dispatch: any;
      commit: any;
    }): void {
      SessionGateway.saveSession(
        rootState.companyName,
        state.currentSessionId,
        getExportState()
      ).then(() => {
        // Refresh the list
        dispatch("loadAllSessions");
        commit("setShowAlert");
      });
    },
    loadAllSessions({ commit }: { commit: any }): void {
      SessionGateway.listSessions().then((sessionItems) => {
        commit("setListOfSessions", sessionItems);
      });
    },
    deleteSession({ dispatch }: { dispatch: any }, sessionId: string): void {
      SessionGateway.deleteSession(sessionId).then((success) => {
        if (success) {
          dispatch("loadAllSessions");
        }
      });
    },
  },
};
