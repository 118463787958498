import Subscope from "@/models/Subscope";

export const VEHICLES_AND_EQUIPMENT = new Subscope(
  "1.1",
  "Vehicles and Equipment"
);
export const STATIONARY_SOURCES = new Subscope("1.2", "Stationary Sources");
export const FUGITIVE_EMISSIONS = new Subscope("1.3", "Fugitive Emissions");

export const ALL_SUBSCOPE1 = [
  VEHICLES_AND_EQUIPMENT,
  STATIONARY_SOURCES,
  FUGITIVE_EMISSIONS,
];
