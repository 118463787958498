
import { Component, Prop, Vue } from "vue-property-decorator";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { VueNumeric },
})
export default class NumericField extends Vue {
  @Prop({ required: true }) value!: number;
  @Prop({ required: false, default: false }) readonly!: boolean;

  localValue = this.value;
  oldValue: number | undefined;

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  storeCurrentValue(): void {
    this.oldValue = this.localValue;
  }

  onBlurHandler(e: Event): void {
    //Update only if value changed
    if (this.oldValue !== this.localValue) {
      this.$emit("update:value", this.localValue);
      this.$emit("blur", e);
    }
  }
}
