export interface SERoute {
  path: string;
  name: string;
}

export const FallbackRoute: SERoute = {
  path: "/*",
  name: "Fallback",
};

export const CompanyRoute: SERoute = {
  path: "/company",
  name: "Company",
};

export const StrategyRoute: SERoute = {
  path: "/strategy",
  name: "Strategy",
};

export const ScenarioRoute: SERoute = {
  path: "/scenarios",
  name: "Scenarios",
};

export const EmissionAndGvaRoute: SERoute = {
  path: "/emissionsAndGva",
  name: "EmissionsAndGva",
};

export const PathwaysRoute: SERoute = {
  path: "/pathways",
  name: "Pathways",
};

export const XDCRoute: SERoute = {
  path: "/xdcs",
  name: "XDCs",
};

export const DataRoute: SERoute = {
  path: "/data",
  name: "Data",
};

export const SessionsRoute: SERoute = {
  path: "/sessions",
  name: "Sessions",
};

export const PreferencesRoute: SERoute = {
  path: "/preferences",
  name: "Preferences",
};

export const FAQRoute: SERoute = {
  path: "/faq",
  name: "FAQ",
};

export const LoginRoute: SERoute = {
  path: "/login",
  name: "Login",
};

export const ChangePasswordRoute: SERoute = {
  path: "/change-password",
  name: "Change Password",
};

export const ForgotPasswordRoute: SERoute = {
  path: "/forgot-password",
  name: "Forgot Password",
};

export const UpdatePasswordRoute: SERoute = {
  path: "/update-password",
  name: "Update Password",
};
