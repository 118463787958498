
import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep, last, sortBy } from "lodash";
import YearRange from "@/models/strategy/YearRange";
import RangeUpdate from "@/models/RangeUpdate";
import Strategy from "@/models/strategy/Strategy";

@Component
export default class RangeConfiguration extends Vue {
  @Prop({ required: true }) ranges!: YearRange[];
  @Prop({ required: true }) strategyId!: number;
  @Prop({ required: true }) activityId!: number;

  rangesCopy = cloneDeep(this.ranges);
  showDialog = false;
  startYear = this.$store.state.baseYear + 1;
  targetYear = this.$store.state.targetYear;
  selectedIdx = 0;

  showMenu = false;
  x = 0;
  y = 0;

  showContextMenu(e: MouseEvent, clickedIdx: number): void {
    e.preventDefault();
    this.selectedIdx = clickedIdx;
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }

  get shouldBtnBeAdded(): boolean {
    return (
      this.rangesCopy[this.rangesCopy.length - 1].startYear !== this.targetYear
    );
  }

  updateRanges(val: number, idx: number): void {
    this.rangesCopy[idx].startYear = Number(val);

    if (this.isEqualToAnotherStart(val, idx)) {
      this.replaceOtherRange(val, idx);
    }

    this.rangesCopy = sortBy(this.rangesCopy, (range) => range.startYear);

    for (let i = 0; i < this.rangesCopy.length - 1; i++) {
      this.rangesCopy[i].endYear = this.rangesCopy[i + 1].startYear - 1;
    }

    this.fixLastElement();
  }

  private isEqualToAnotherStart(val: number, idx: number): boolean {
    return (
      this.rangesCopy.findIndex((r, indx) => {
        return r.startYear === Number(val) && Number(idx) !== Number(indx);
      }) !== -1
    );
  }

  private replaceOtherRange(val: number, idx: number): void {
    const existingRangeIdx = this.rangesCopy.findIndex((r, indx) => {
      return r.startYear === Number(val) && Number(idx) !== Number(indx);
    });

    // Replace the existing value with the one the user just changed (end year values will be adjusted in next step)
    this.$set(
      this.rangesCopy,
      existingRangeIdx,
      new YearRange(
        this.rangesCopy[idx].id,
        Number(val),
        this.rangesCopy[idx].startYear
      )
    );

    //Remove the one we used to replace the other one (no duplicates)
    this.rangesCopy.splice(idx, 1);
  }

  private fixLastElement(): void {
    // Fix last element (just in case it is needed)
    const lastIdx = this.rangesCopy.length - 1;
    this.$set(
      this.rangesCopy,
      lastIdx,
      new YearRange(
        this.rangesCopy[lastIdx].id,
        this.rangesCopy[lastIdx].startYear,
        this.targetYear
      )
    );
  }

  addRange(): void {
    const lastRange = last(this.rangesCopy);

    if (lastRange) {
      lastRange.endYear = lastRange.startYear;
      this.rangesCopy.push(
        new YearRange(
          lastRange.id + 1,
          lastRange.startYear + 1,
          this.targetYear
        )
      );
    }
  }

  removeRange(idx: number): void {
    this.rangesCopy[idx - 1].endYear = this.rangesCopy[idx].endYear;
    this.rangesCopy.splice(idx, 1);
  }

  cancelSelection(): void {
    this.rangesCopy = cloneDeep(this.ranges);
    this.showDialog = false;
  }

  saveSelection(): void {
    this.$store.commit(
      "updateRanges",
      new RangeUpdate(this.activityId, this.strategyId, this.rangesCopy)
    );
    this.$store.commit("curvesNeedReload", true);
    const strategy = this.$store.getters
      .activityById(this.activityId)
      .strategies.find((s: Strategy) => s.id === this.strategyId);

    if (strategy) {
      this.$store.dispatch("makeStrategyXDCRequest", strategy);
    }

    this.showDialog = false;

    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("sessionStore/saveSession");
    }
  }
}
