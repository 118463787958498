export default class ScenarioConfig {
  id: number;
  name: string;
  selectedActivityToStrategyMap: Record<number, number>;

  constructor(
    id: number,
    name: string,
    selectedActivityToStrategyMap: Record<number, number>
  ) {
    this.id = id;
    this.name = name;
    this.selectedActivityToStrategyMap = selectedActivityToStrategyMap;
  }

  getStrategyIdOf(activityId: number): number | undefined {
    return this.selectedActivityToStrategyMap[activityId];
  }

  upsertStrategyIdOf(activityId: number, strategyId: number): void {
    this.selectedActivityToStrategyMap[activityId] = strategyId;
  }

  addNewActivity(activityId: number): void {
    this.upsertStrategyIdOf(activityId, 1);
  }

  removeActivity(activityId: number): void {
    delete this.selectedActivityToStrategyMap[activityId];
  }
}
