
import { Component, Vue } from "vue-property-decorator";
import { SUPPORTED_LOCALES } from "@/constants/Locales";
import LocaleSetting from "@/models/preferences/LocaleSetting";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { VueNumeric },
})
export default class PreferencesPage extends Vue {
  allAvailableLocales = SUPPORTED_LOCALES;

  created(): void {
    this.$store.dispatch("configStore/getXDCVersion");
  }

  get autoSave(): boolean {
    return this.$store.state.configStore.autoSave;
  }

  set autoSave(newValue: boolean) {
    this.$store.commit("configStore/setAutoSave", newValue);
    this.$store.dispatch("configStore/saveConfig");
  }

  get selectedLocale(): LocaleSetting {
    return this.$store.state.configStore.locale;
  }

  set selectedLocale(newValue: LocaleSetting) {
    this.$store.commit("configStore/setLocale", newValue);
    this.$store.dispatch("configStore/saveConfig");
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  get engineVersion(): string {
    return this.$store.state.configStore.engineVersion;
  }
}
