import Config from "@/constants/Config";
import AuthGateway from "@/auth/services/AuthGateway";
import { successfulResponse } from "@/services/SuccesfulResponse";
import EeiCurveDto from "@/models/pathways/EeiCurveDto";
import EeiBaselineResponse from "@/models/pathways/EeiBaselineResponse";
import ActivitySummary from "@/services/server-models/ActivitySummary";
import ActivityStrategyCombo from "@/models/pathways/ActivityStrategyCombo";
import {
  BIG_EMISSIONS_ERROR,
  ERROR,
  SERVER_NOT_AVAILABLE,
} from "@/constants/RequestStatus";

export default class PathwayGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v3/se/emission-pathways";

  static getEmissionIntensityBaselinePathways(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    ssp: string,
    activities: ActivitySummary[]
  ): Promise<EeiBaselineResponse> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/baseline",
      {},
      {
        baseYear: baseYear,
        countryCode: countryCode,
        naceCode: naceCode,
        provider: provider,
        sspCode: ssp,
        activities: activities,
      }
    ).then((response) => {
      if (response === undefined) {
        // Error due to server down?
        const curve = new EeiBaselineResponse([], [], []);
        curve.status = SERVER_NOT_AVAILABLE;

        return curve;
      }

      if (!successfulResponse(response) || response.data.error) {
        const curve = new EeiBaselineResponse([], [], []);

        if (
          response.data.message &&
          response.data.message.toLowerCase().includes("fair crashed")
        ) {
          curve.status = BIG_EMISSIONS_ERROR;
        } else {
          curve.status = ERROR;
        }

        return curve;
      }

      const data = response.data as EeiBaselineResponse;

      return new EeiBaselineResponse(data.sector, data.company, data.activity);
    });
  }

  static getEmissionIntensityPathways(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    sspCode: string,
    extension2100: string,
    activityStrategyCombinations: ActivityStrategyCombo[]
  ): Promise<EeiCurveDto> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/user-defined",
      {},
      {
        baseYear: baseYear,
        naceCode: naceCode,
        countryCode: countryCode,
        provider: provider,
        sspCode: sspCode,
        extension2100: extension2100,
        activityStrategyCombinations: activityStrategyCombinations,
      }
    ).then((response) => {
      if (response === undefined) {
        // Error due to server down?
        const curve = new EeiCurveDto("", []);
        curve.status = SERVER_NOT_AVAILABLE;

        return curve;
      }

      if (!successfulResponse(response) || response.data.error) {
        const curve = new EeiCurveDto("", []);
        curve.status = ERROR;

        return curve;
      }

      const data = response.data as EeiCurveDto;

      return new EeiCurveDto(data.name, data.totalScopeEeiPathway);
    });
  }
}
