
import { Component, Vue } from "vue-property-decorator";
import BasicInformation from "@/pages/company/components/BasicInformation.vue";
import ActivityInfoRow from "@/pages/company/components/ActivityInfoRow.vue";
import ActivityInfoTable from "@/pages/company/components/ActivityInfoTable.vue";
import SubscopeSelection from "@/pages/company/components/SubscopeSelection.vue";
import Config from "@/constants/Config";

@Component({
  components: {
    SubscopeSelection,
    ActivityInfoTable,
    ActivityInfoRow,
    BasicInformation,
  },
})
export default class CompanyPage extends Vue {
  // [SubScopeFeatureToggle]
  get subScopeSelectionFeatureToggle(): boolean {
    return Config.SubScopeFeatureToggle;
  }
}
