export default class SSP {
  name: string;
  display: string;
  full: string;

  constructor(name: string, display: string, full: string) {
    this.name = name;
    this.display = display;
    this.full = full;
  }
}
