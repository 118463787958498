
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FAQPage extends Vue {
  openTab = -1;

  mounted(): void {
    const query = this.$route.query.q as string;

    const target = this.$refs[query] as Vue[];

    if (target) {
      this.openTab = Object.keys(this.$refs).indexOf(query);

      setTimeout(() => {
        this.$vuetify.goTo(target[0]);
      }, 500);
    }
  }
}
