import Config from "@/constants/Config";
import Gateway from "@/services/Gateway";
import { NACESectorFromAPI } from "@/services/server-models/NACESectorFromAPI";
import NACESector from "@/models/NACESector";

export default class NACESectorGateway extends Gateway {
  static get baseUrl(): string {
    return Config.ApiGatewayUrl + "/api/v1/ci";
  }

  static async fetchNaceSectors(): Promise<Array<NACESector>> {
    const response = await this.makeGetRequest<Array<NACESectorFromAPI>>(
      "/calculable-sectors"
    );

    return [
      new NACESector("", ""),
      ...response.data.map((sector) => {
        return new NACESector(sector.naceCode, sector.description, false);
      }),
    ];
  }
}
