import EeiCurveDto from "@/models/pathways/EeiCurveDto";
import EeiActivityCurve from "@/models/pathways/EeiActivityCurve";
import { READY } from "@/constants/RequestStatus";

export default class EeiBaselineResponse {
  sector: EeiCurveDto[];
  company: EeiCurveDto[];
  activity: EeiActivityCurve[];
  status: string;

  constructor(
    sector: EeiCurveDto[],
    company: EeiCurveDto[],
    activity: EeiActivityCurve[],
    status: string = READY
  ) {
    this.sector = sector;
    this.company = company;
    this.activity = activity;
    this.status = status;
  }
}
