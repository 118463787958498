
import { Component, Prop, Vue } from "vue-property-decorator";
import BusinessActivityCurvesGroup from "@/models/trajectory/BusinessActivityCurvesGroup";
import CheckboxWithColor from "@/pages/shared/components/CheckboxWithColor.vue";

@Component({
  components: {
    CheckboxWithColor,
  },
})
export default class TrajectoriesSelectionTable extends Vue {
  @Prop({ required: true }) curveGroup!: BusinessActivityCurvesGroup;

  get parentStrategyName(): string {
    return this.$store.getters.strategyName(
      this.curveGroup.parentActivityId,
      this.curveGroup.parentStrategyId
    );
  }
}
