import RouteStoreState from "@/store/route/RouteStoreState";

export default {
  namespaced: true,
  state: {
    previousRoute: "",
    errorMessage: "",
  },
  mutations: {
    setPreviousRoute(state: RouteStoreState, currentRoute: string): void {
      state.previousRoute = currentRoute;
    },
    setErrorMessage(state: RouteStoreState, errorMessage: string): void {
      state.errorMessage = errorMessage;
    },
  },
  actions: {},
};
