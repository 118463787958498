import Activity from "@/models/activity/Activity";

export default class ActivitySummary {
  id: string;
  gva: number;
  scope1: number;
  scope2: number;
  scope3: number;

  constructor(activity: Activity) {
    this.id = `${activity.id}`;
    this.gva = activity.gva.total();
    this.scope1 = activity.scope1.sumTotal();
    this.scope2 = activity.scope2.sumTotal();
    this.scope3 = activity.scope3.sumTotal();
  }
}
