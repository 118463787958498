import { ActionTree } from "vuex";
import { State } from "@/store/main/state";
import XDCGateway from "@/services/XDCGateway";
import Activity from "@/models/activity/Activity";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import Strategy from "@/models/strategy/Strategy";
import BusinessActivityCurvesGroup from "@/models/trajectory/BusinessActivityCurvesGroup";
import { BASELINE } from "@/constants/Constants";
import { sumBy } from "lodash";
import Vue from "vue";
import TrajectoryGateway from "@/services/TrajectoryGateway";
import PathwayGateway from "@/services/EmissionPathwayGateway";
import ActivitySummary from "@/services/server-models/ActivitySummary";
import EeiBaselineResponse from "@/models/pathways/EeiBaselineResponse";
import HighchartEeiCurveWrapper from "@/models/highcharts/HighchartEeiCurveWrapper";
import ActivityStrategyCombo from "@/models/pathways/ActivityStrategyCombo";
import EeiCurveDto from "@/models/pathways/EeiCurveDto";
import CurveGroupDto from "@/models/trajectory/CurveGroupDto";
import { LOADING } from "@/constants/RequestStatus";
import EeiActivityCurve from "@/models/pathways/EeiActivityCurve";
import ScenarioConfig from "@/models/ScenarioConfig";
import GroupedXDC from "@/models/xdc/GroupedXDC";
import NACESectorGateway from "@/services/NACESectorGateway";

export const actions: ActionTree<State, State> = {
  async fetchNaceSectors({ state }) {
    state.naceSectors = await NACESectorGateway.fetchNaceSectors();
  },

  makeXDCRequest({ state, commit, dispatch }, activities: Activity[]) {
    activities.forEach((activity) => {
      activity.baselineXDC.status = LOADING;
      state.totalActivityXDC.status = LOADING;

      XDCGateway.getCompanyActivityBaseline(
        state.baseYear,
        state.country.code,
        activity.gva.total(),
        state.naceSector.code,
        state.provider.title,
        state.ssp.name,
        activity.scope1.sumTotal(),
        activity.scope2.sumTotal(),
        activity.scope3.sumTotal()
      ).then((baselineXDC: BaselineXDC) => {
        commit("updateActivityXDC", { activity, baselineXDC });
      });

      activity.strategies.forEach((strategy: Strategy) => {
        dispatch("makeStrategyXDCRequest", strategy);
      });
    });

    // request for the "company" (total activities)
    XDCGateway.getCompanyActivityBaseline(
      state.baseYear,
      state.country.code,
      sumBy(state.activities, (a) => a.gva.total()),
      state.naceSector.code,
      state.provider.title,
      state.ssp.name,
      sumBy(state.activities, (a) => a.scope1.sumTotal()),
      sumBy(state.activities, (a) => a.scope2.sumTotal()),
      sumBy(state.activities, (a) => a.scope3.sumTotal())
    ).then((baselineXDC: BaselineXDC) => {
      state.totalActivityXDC = baselineXDC;
    });
  },

  makeStrategyXDCRequest({ state, getters }, strategy: Strategy) {
    strategy.baselineXDC.status = LOADING;

    const activity = getters.activityById(strategy.parentActivityId);

    if (activity) {
      XDCGateway.getStrategyXDC(
        state.baseYear,
        state.country.code,
        state.naceSector.code,
        state.provider.title,
        activity.gva.ebitda,
        activity.gva.personnelCosts,
        activity.scope1.other,
        activity.scope2.other,
        activity.scope3.other,
        state.ssp.name,
        state.extension2100.name,
        strategy.growthProjections
      ).then((xdc) => {
        strategy.baselineXDC = xdc;
      });
    }
  },

  recalculateActivityStrategiesInitialValues(store, activity: Activity) {
    activity.strategies.forEach((strategy) => {
      strategy.recalculateInitialValues(activity);
    });
  },

  recalculateStrategyInitialValues({ getters }, strategy: Strategy) {
    const activity = getters.activityById(strategy.parentActivityId);

    if (activity) {
      strategy.recalculateInitialValues(activity);
    }
  },

  makeCompanyTrajectoriesRequest({ state, commit }) {
    state.activities.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        commit("addRequestForActivityTrajectories");

        if (strategy.name === BASELINE) {
          TrajectoryGateway.getCompanyActivityBaselineTrajectories(
            state.baseYear,
            state.country.code,
            state.naceSector.code,
            state.provider.title,
            state.ssp.name,
            activity.gva.total(),
            activity.scope1.sumTotal(),
            activity.scope2.sumTotal(),
            activity.scope3.sumTotal()
          ).then((curveGroup) => {
            handleTrajectoryResponse(
              commit,
              activity,
              strategy,
              state,
              curveGroup,
              true
            );
          });
        } else {
          const combo = new ActivityStrategyCombo(activity, strategy);

          TrajectoryGateway.getCompanyActivityTrajectories(
            state.baseYear,
            state.country.code,
            state.naceSector.code,
            state.provider.title,
            combo.initialEbitda,
            combo.initialPersonnelCost,
            activity.scope1.other,
            activity.scope2.other,
            activity.scope3.other,
            // can be removed when refactoring is done
            state.ssp.name,
            state.extension2100.name,
            strategy.growthProjections
          ).then((curveGroup) => {
            handleTrajectoryResponse(
              commit,
              activity,
              strategy,
              state,
              curveGroup
            );
          });
        }
      });
    });
  },

  makeIntensityPathwaysRequest({ state, commit, dispatch }) {
    // Make request for baseline case
    commit("addRequestForEmissionPathways");
    commit("addRequestForPathwaysXDC"); // This is so the loader is correctly shown in the XDCs page
    PathwayGateway.getEmissionIntensityBaselinePathways(
      state.baseYear,
      state.country.code,
      state.naceSector.code,
      state.provider.title,
      state.ssp.name,
      state.activities.map((a) => new ActivitySummary(a))
    ).then((response: EeiBaselineResponse) => {
      commit("receivedRequestForEmissionPathways");
      handleSectorCurves(state, response.sector);

      handleCompanyCurves(state, response.company);

      handleActivityCurves(state, response.activity);

      state.highChartEei.status = response.status;

      commit("receivedRequestForPathwaysXDC"); // Clear to avoid mistakes while doing the actual XDC requests
      dispatch("makePathwaysXDCRequest");
    });

    // Make request for scenarios
    state.scenarios.forEach((scenario) => {
      if (!scenario.name.includes(BASELINE)) {
        const combos = prepareActivityStrategyCombos(state, scenario);

        // NON-BASELINE CASE
        commit("addRequestForEmissionPathways");
        PathwayGateway.getEmissionIntensityPathways(
          state.baseYear,
          state.country.code,
          state.naceSector.code,
          state.provider.title,
          state.ssp.name,
          state.extension2100.name,
          combos
        ).then((curve: EeiCurveDto) => {
          commit("receivedRequestForEmissionPathways");
          const curveWrapper = new HighchartEeiCurveWrapper(
            Vue.prototype.$uuid.v4(),
            Number(scenario.id),
            state.naceSector,
            "",
            state.baseYear,
            scenario.name,
            curve.totalScopeEeiPathway,
            curve.status,
            false,
            false,
            false,
            true
          );

          curveWrapper.applyNewPaint(Vue.prototype.$curvePainter);
          Vue.prototype.$curvePainter.finishedGroup();

          state.highChartEei.emissionIntensityCurves.push(curveWrapper);
        });
      }
    });
  },

  makePathwaysXDCRequest({ state, commit }) {
    // Make request for scenarios
    state.scenarios.forEach((scenario) => {
      if (!scenario.name.includes(BASELINE)) {
        const combos = prepareActivityStrategyCombos(state, scenario);

        // NON-BASELINE CASE
        commit("addRequestForPathwaysXDC");
        XDCGateway.getUserDefinedXDC(
          state.baseYear,
          state.country.code,
          state.naceSector.code,
          state.provider.title,
          state.ssp.name,
          state.extension2100.name,
          combos
        ).then((xdc: GroupedXDC) => {
          commit("receivedRequestForPathwaysXDC");
          xdc.name = scenario.name;

          state.emissionIntensityXDCs.push(xdc);
        });
      }
    });
  },
};

function handleTrajectoryResponse(
  commit: any,
  activity: Activity,
  strategy: Strategy,
  state: State,
  curveGroup: CurveGroupDto[],
  isBaselineCase = false
): void {
  commit("receivedRequestForActivityTrajectories");
  const bacg = new BusinessActivityCurvesGroup(
    activity.id,
    strategy.id,
    activity.name,
    strategy.name,
    state.baseYear,
    curveGroup
  );

  bacg.curves.forEach((curve) =>
    curve.applyNewPaint(Vue.prototype.$curvePainter)
  );

  if (isBaselineCase) {
    //Always put in the beginning
    state.businessActivityCurves.unshift(bacg);
  } else {
    state.businessActivityCurves.push(bacg);
  }
}

function handleSectorCurves(state: State, sectorCurves: EeiCurveDto[]): void {
  sectorCurves.forEach((sectorCurve) => {
    const curveWrapper = new HighchartEeiCurveWrapper(
      Vue.prototype.$uuid.v4(),
      0,
      state.naceSector,
      "",
      state.baseYear,
      sectorCurve.name,
      sectorCurve.totalScopeEeiPathway,
      sectorCurve.status,
      true,
      false,
      false,
      false
    );

    curveWrapper.applyNewPaint(Vue.prototype.$curvePainter);

    state.highChartEei.emissionIntensityCurves.push(curveWrapper);
  });

  Vue.prototype.$curvePainter.finishedGroup();
}

function handleCompanyCurves(state: State, companyCurves: EeiCurveDto[]): void {
  companyCurves.forEach((companyCurve) => {
    const curveWrapper = new HighchartEeiCurveWrapper(
      Vue.prototype.$uuid.v4(),
      0,
      state.naceSector,
      "",
      state.baseYear,
      companyCurve.name,
      companyCurve.totalScopeEeiPathway,
      companyCurve.status,
      false,
      !companyCurve.name.includes(BASELINE),
      false,
      companyCurve.name.includes(BASELINE)
    );

    curveWrapper.applyNewPaint(Vue.prototype.$curvePainter);

    state.highChartEei.emissionIntensityCurves.push(curveWrapper);
  });

  Vue.prototype.$curvePainter.finishedGroup();
}

function handleActivityCurves(
  state: State,
  activityCurves: EeiActivityCurve[]
): void {
  activityCurves.forEach((activityGroup) => {
    const found = state.activities.find(
      (a: Activity) => a.id === Number(activityGroup.id)
    );

    const activityName = found ? found.name : "Activity";
    activityGroup.curves.forEach((activityGroupCurve: EeiCurveDto) => {
      const curveWrapper = new HighchartEeiCurveWrapper(
        Vue.prototype.$uuid.v4(),
        Number(activityGroup.id),
        state.naceSector,
        activityName,
        state.baseYear,
        activityGroupCurve.name,
        activityGroupCurve.totalScopeEeiPathway,
        activityGroupCurve.status,
        false,
        false,
        true,
        false
      );

      curveWrapper.applyNewPaint(Vue.prototype.$curvePainter);

      state.highChartEei.emissionIntensityCurves.push(curveWrapper);
    });

    Vue.prototype.$curvePainter.finishedGroup();
  });
}

function prepareActivityStrategyCombos(
  state: State,
  scenario: ScenarioConfig
): ActivityStrategyCombo[] {
  const combos: ActivityStrategyCombo[] = [];

  for (const key in scenario.selectedActivityToStrategyMap) {
    const activityId = Number(key);
    const strategyId = scenario.selectedActivityToStrategyMap[activityId];

    const foundActivity = state.activities.find(
      (a: Activity) => a.id === activityId
    );

    if (foundActivity) {
      const foundStrategy = foundActivity.strategies.find(
        (s: Strategy) => s.id === strategyId
      );

      if (foundStrategy) {
        const combo = new ActivityStrategyCombo(foundActivity, foundStrategy);

        combos.push(combo);
      }
    }
  }

  return combos;
}
