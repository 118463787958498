import Extension from "@/models/Extension";

export const EXTENSION_2100: Extension[] = [
  new Extension("SSP1_BASELINE", "SSP1"),
  new Extension("SSP2_BASELINE", "SSP2"),
  new Extension("SSP2_RCP60", "SSP2 RCP60"),
  new Extension("SSP3_BASELINE", "SSP3"),
  new Extension("SSP4_BASELINE", "SSP4"),
  new Extension("SSP5_BASELINE", "SSP5"),
  new Extension("CONSTANT_EEI", "Constant EEI"),
  new Extension("CONSTANT_PERFORMANCE", "Constant Performance"),
  new Extension("CONSTANT_GROWTH", "Constant Growth"),
];
