import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import LoginRequest from "@/auth/models/LoginRequest";
import TokenStorage from "@/auth/services/TokenStorage";
import router from "@/router";
import { store } from "@/store/main";
import Config from "@/constants/Config";
import { successfulResponse } from "@/services/SuccesfulResponse";
import PasswordChangeErrorResponse from "@/auth/models/PasswordChangeErrorResponse";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";
type RequestMethod = typeof GET | typeof POST | typeof PUT | typeof DELETE;

// annotation for singleton
export default class AuthGateway {
  static AUTH_GATEWAY_BASE_URL = Config.AuthGatewayUrl;
  static AUTH_APPLICATION_ID = Config.AuthApplicationId;
  static AUTH_TENANT_ID = Config.AuthTenantId;
  static LoginPath = "/api/login";
  static ForgotPasswordPath = "/api/user/forgot-password";
  static JWTRefreshPath = "/api/jwt/refresh";
  static ChangePasswordPath = "/api/user/change-password/";

  static createRequest(
    headers: Record<string, any> = {},
    baseUrl = ""
  ): AxiosInstance {
    return axios.create({
      baseURL: baseUrl === "" ? this.AUTH_GATEWAY_BASE_URL : baseUrl,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json;  charset=utf-8",
        ...headers,
      },
    });
  }

  static _executeRequest(
    httpRequest: AxiosInstance,
    type: RequestMethod,
    url: string,
    body: Record<string, any>
  ): Promise<AxiosResponse> {
    const requests: Record<RequestMethod, () => Promise<AxiosResponse>> = {
      get: () => httpRequest.get(url),
      post: () => httpRequest.post(url, body),
      put: () => httpRequest.put(url, body),
      delete: () => httpRequest.delete(url),
    };

    return requests[type]()
      .then((response) => {
        if (response.status == 401 || response.status == 403) {
          store.commit("auth/setAuthState");
          router.push({ name: "Login" });
        }

        return response;
      })
      .catch((error: AxiosError) => {
        return error.response as AxiosResponse;
      });
  }

  static sendRequestWithAuth(
    baseUrl: string,
    type: RequestMethod,
    url: string,
    headers: Record<string, any> = {},
    data: Record<string, any> = {}
  ): Promise<AxiosResponse> {
    if (type === GET || type === POST) {
      headers["Content-Type"] = "application/json";
    }

    if (!TokenStorage.isTokenValid(TokenStorage.getToken())) {
      return AuthGateway.refreshToken()
        .then(() => {
          const httpRequest = this.createRequestWithHeader(headers, baseUrl);

          return AuthGateway._executeRequest(httpRequest, type, url, data);
        })
        .catch((error: AxiosResponse) => {
          store.commit("auth/setAuthState");
          router.push({ name: "Login" });

          return error;
        });
    }

    const httpRequest = this.createRequestWithHeader(headers, baseUrl);

    return AuthGateway._executeRequest(httpRequest, type, url, data);
  }

  private static createRequestWithHeader(
    headers: Record<string, any>,
    baseUrl: string
  ): AxiosInstance {
    const headersWithAuth: Record<string, any> = {
      ...headers,
      ...{ Authorization: "Bearer " + TokenStorage.getToken() },
    };

    return this.createRequest(headersWithAuth, baseUrl);
  }

  static login(loginRequest: LoginRequest): Promise<AxiosResponse> {
    return this.createRequest()
      .post(this.LoginPath, loginRequest)
      .then((response: AxiosResponse) => {
        // Fusionauth  response must be exactly 200 to login
        if (response.status !== 200) {
          if (response.status === 203) {
            return response;
          }

          throw { response } as AxiosError;
        }

        const loginJson: Record<string, any> = response && response.data;
        const { refreshToken, token } = loginJson;
        TokenStorage.storeToken(token);
        TokenStorage.storeRefreshToken(refreshToken);
        store.commit("auth/setAuthState");
        router.push({ name: "Company" });
        store.dispatch("makeXDCRequest", store.state.activities);

        return response;
      })
      .catch((error: AxiosError) => {
        const payload = this.getErrorMessageFromLoginErrorCode(
          error.response?.status
        );

        store.commit("routeStore/setErrorMessage", payload);

        return error.response as AxiosResponse;
      });
  }

  private static getErrorMessageFromLoginErrorCode(
    errorCode: number | undefined
  ): string | void {
    // codes from: https://fusionauth.io/docs/apis/login
    switch (errorCode) {
      case 202: {
        return "Login failed: It seems that you don't have a registration for this application. Please contact us.";
      }

      case 212: {
        return "Login failed: You must verify your email first";
      }

      case 404: {
        return "Login failed: Check your username and password and try again.";
      }

      case 410: {
        return "Login failed: Your user is expired.";
      }

      default: {
        return `Login failed: Please contact the support team( Error Code: ${errorCode}}}`;
      }
    }
  }

  static refreshToken(): Promise<AxiosResponse> {
    const token = TokenStorage.getRefreshToken();

    if (!token) {
      const response: AxiosResponse = {
        status: 401,
        statusText: "Undefined or empty refresh token",
        data: [],
        headers: {},
        config: {},
      };

      return Promise.resolve(response);
    }

    return this.createRequest({
      "X-FusionAuth-TenantId": this.AUTH_TENANT_ID,
    })
      .post(this.JWTRefreshPath, TokenStorage.getRefreshHeader())
      .then((response: AxiosResponse) => {
        if (!successfulResponse(response)) {
          throw { response } as AxiosError;
        }

        const tokenJson: Record<string, any> = response.data;
        const { refreshToken, token } = tokenJson;
        TokenStorage.storeRefreshToken(refreshToken);
        TokenStorage.storeToken(token);
        store.commit("auth/setAuthState");

        return response;
      })
      .catch((error: AxiosError) => {
        TokenStorage.clear();
        store.commit("auth/setAuthState");
        router.push({ name: "Login" }, () => {
          store.commit(
            "routeStore/setErrorMessage",
            "Error: last login is to long ago. Please sign in again."
          );
        });

        return error.response as AxiosResponse;
      });
  }

  static logout(): void {
    TokenStorage.clear();
    store.commit("auth/setAuthState");

    if (router.currentRoute.name !== "Login") {
      router.push({ name: "Login" });
    }
  }

  static forgotPassword(email: string): Promise<void> {
    TokenStorage.clear();
    store.commit("auth/setAuthState");

    const body = {
      loginId: email,
      sendForgotPasswordEmail: true,
      applicationId: this.AUTH_APPLICATION_ID,
    };

    const headers = {
      "Content-Type": "application/json",
      "X-FusionAuth-TenantId": this.AUTH_TENANT_ID,
    };

    return AuthGateway.createRequest(headers)
      .post(this.ForgotPasswordPath, body)
      .then((response: AxiosResponse) => {
        if (!successfulResponse(response)) {
          throw { response } as AxiosError;
        }
      })
      .catch((error: AxiosError) => {
        throw Error(`Server error: ${error.response?.statusText}`);
      });
  }

  static changePassword(
    password: string,
    changePasswordId: string
  ): Promise<void> {
    TokenStorage.clear();
    store.commit("auth/setAuthState");

    const headers = {
      "Content-Type": "application/json",
      "X-FusionAuth-TenantId": this.AUTH_TENANT_ID,
    };

    const finalPath = this.ChangePasswordPath + changePasswordId;

    return AuthGateway.createRequest(headers)
      .post(finalPath, { password })
      .then((response: AxiosResponse) => {
        if (!successfulResponse(response)) {
          throw { response } as AxiosError;
        }

        router.push({ name: "Login" });
      })
      .catch((error: AxiosError) => {
        const responseErrorMessage = generateErrorMessageFromAxios(error);

        alert(
          `Error while creating new password. Details: ${responseErrorMessage}`
        );

        throw Error(`Server error: ${error.response?.statusText}`);
      });
  }
}

function generateErrorMessageFromAxios(error: AxiosError): string {
  let responseErrorMessage = error.response?.statusText;

  if (error.response?.statusText === undefined) {
    responseErrorMessage = "Server Error. Please try again later.";
  }

  const passwordErrorMessage = (
    error.response?.data as PasswordChangeErrorResponse
  ).fieldErrors.password[0].message;

  if (passwordErrorMessage != undefined) {
    responseErrorMessage = passwordErrorMessage;
  }

  return <string>responseErrorMessage;
}
