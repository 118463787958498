import Subscope from "@/models/Subscope";

export const UPSTREAM_PURCHASED_GOODS_AND_SERVICES = new Subscope(
  "3.1",
  "Upstream: Purchased goods and services"
);
export const UPSTREAM_CAPITAL_GOODS = new Subscope(
  "3.2",
  "Upstream: Capital goods"
);
export const UPSTREAM_FUEL_AND_ENERGY_RELATED_ACTIVITIES = new Subscope(
  "3.3",
  "Upstream: Fuel and energy related activities"
);
export const UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION = new Subscope(
  "3.4",
  "Upstream: Transportation and distribution"
);
export const UPSTREAM_WASTE_GENERATED_IN_OPERATIONS = new Subscope(
  "3.5",
  "Upstream: Waste generated in operations"
);
export const UPSTREAM_BUSINESS_TRAVEL = new Subscope(
  "3.6",
  "Upstream: Business travel"
);
export const UPSTREAM_EMPLOYEE_COMMUTING = new Subscope(
  "3.7",
  "Upstream: Employee commuting"
);
export const UPSTREAM_LEASED_ASSETS = new Subscope(
  "3.8",
  "Upstream: Leased assets"
);

export const DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION = new Subscope(
  "3.9",
  "Downstream: Transportation and distribution"
);
export const DOWNSTREAM_PROCESSING_OF_SOLD_ASSETS = new Subscope(
  "3.10",
  "Downstream: Processing of sold assets"
);
export const DOWNSTREAM_USE_OF_SOLD_GOODS = new Subscope(
  "3.11",
  "Downstream: Use of sold goods"
);
export const DOWNSTREAM_END_OF_LIFE_TREATMENT_OF_SOLD_GOODS = new Subscope(
  "3.12",
  "Downstream: End-of-life treatment of sold goods"
);
export const DOWNSTREAM_LEASED_ASSETS = new Subscope(
  "3.13",
  "Downstream: Leased assets"
);
export const DOWNSTREAM_FRANCHISES = new Subscope(
  "3.14",
  "Downstream: Franchises"
);
export const DOWNSTREAM_INVESTMENTS = new Subscope(
  "3.15",
  "Downstream: Investments (usually for fin. instit.)"
);

export const ALL_SUBSCOPE3_DOWNSTREAM = [
  DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION,
  DOWNSTREAM_PROCESSING_OF_SOLD_ASSETS,
  DOWNSTREAM_USE_OF_SOLD_GOODS,
  DOWNSTREAM_END_OF_LIFE_TREATMENT_OF_SOLD_GOODS,
  DOWNSTREAM_LEASED_ASSETS,
  DOWNSTREAM_FRANCHISES,
  DOWNSTREAM_INVESTMENTS,
];

export const ALL_SUBSCOPE3_UPSTREAM = [
  UPSTREAM_PURCHASED_GOODS_AND_SERVICES,
  UPSTREAM_CAPITAL_GOODS,
  UPSTREAM_FUEL_AND_ENERGY_RELATED_ACTIVITIES,
  UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION,
  UPSTREAM_WASTE_GENERATED_IN_OPERATIONS,
  UPSTREAM_BUSINESS_TRAVEL,
  UPSTREAM_EMPLOYEE_COMMUTING,
  UPSTREAM_LEASED_ASSETS,
];
