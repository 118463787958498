import Config from "@/constants/Config";
import AuthGateway from "@/auth/services/AuthGateway";
import { successfulResponse } from "@/services/SuccesfulResponse";
import CurveGroupDto from "@/models/trajectory/CurveGroupDto";
import {
  BIG_EMISSIONS_ERROR,
  ERROR,
  SERVER_NOT_AVAILABLE,
} from "@/constants/RequestStatus";
import { AxiosResponse } from "axios";
import GrowthProjection from "@/models/strategy/GrowthProjection";

export default class TrajectoryGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v3/se/activity-trajectories";

  static getCompanyActivityBaselineTrajectories(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    ssp: string,
    gvaValue: number,
    scope1Value: number,
    scope2Value: number,
    scope3Value: number
  ): Promise<CurveGroupDto[]> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/baseline",
      {},
      {
        assetId: "baseline emissions trajectory",
        baseYear: baseYear,
        countryCode: countryCode,
        gva: gvaValue,
        naceCode: naceCode,
        provider: provider,
        scope1: scope1Value,
        scope2: scope2Value,
        scope3: scope3Value,
        sspCode: ssp,
      }
    ).then((response) => {
      return this.handleResponse(response);
    });
  }

  static getCompanyActivityTrajectories(
    baseYear: number,
    countryCode: string,
    naceCode: string,
    provider: string,
    initialEbitda: number,
    initialPersonnelCost: number,
    initialScope1: number,
    initialScope2: number,
    initialScope3: number,
    ssp: string,
    extension2100: string,
    growthProjections: GrowthProjection[]
  ): Promise<CurveGroupDto[]> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      "post",
      "/user-defined",
      {},
      {
        assetId: "user-defined emissions trajectory",
        baseYear: baseYear,
        countryCode: countryCode,
        naceCode: naceCode,
        provider: provider,
        initialEbitda: initialEbitda,
        initialPersonnelCost: initialPersonnelCost,
        initialScope1: initialScope1,
        initialScope2: initialScope2,
        initialScope3: initialScope3,
        sspCode: ssp,
        extension2100: extension2100,
        growthsScenarios: growthProjections,
      }
    ).then((response) => {
      return this.handleResponse(response);
    });
  }

  static handleResponse(response: AxiosResponse): CurveGroupDto[] {
    if (response === undefined) {
      // Error due to server down?
      const curveGroup = new CurveGroupDto(
        "",
        undefined,
        [],
        undefined,
        undefined,
        undefined
      );
      curveGroup.status = SERVER_NOT_AVAILABLE;

      return [curveGroup];
    }

    if (!successfulResponse(response)) {
      const curveGroup = new CurveGroupDto(
        "",
        undefined,
        [],
        undefined,
        undefined,
        undefined
      );

      if (
        response.data &&
        response.data.toLowerCase().includes("fair crashed")
      ) {
        curveGroup.status = BIG_EMISSIONS_ERROR;
      } else {
        curveGroup.status = ERROR;
      }

      return [curveGroup];
    }

    const data = response.data as CurveGroupDto[];
    const responseList: CurveGroupDto[] = [];
    for (const key in data) {
      if (data[key].status == "ERROR") {
        const curveGroup = new CurveGroupDto(
          data[key].name,
          undefined,
          [],
          undefined,
          undefined,
          undefined
        );
        curveGroup.status = ERROR;
        responseList.push(curveGroup);
      } else {
        const c = new CurveGroupDto(
          data[key].name,
          data[key].gvaPoints,
          data[key].totalEmissionPoints,
          data[key].scope1EmissionPoints,
          data[key].scope2EmissionPoints,
          data[key].scope3EmissionPoints
        );
        responseList.push(c);
      }
    }

    return responseList;
  }
}
