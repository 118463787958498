var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [_c("v-col", [_c("div", { staticClass: "text-h5" }, [_vm._v("FAQ")])])],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-expansion-panels",
                { attrs: { value: _vm.openTab } },
                [
                  _c(
                    "v-expansion-panel",
                    { ref: "naceSectors" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" What are the NACE sectors? "),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " The “Nomenclature statistique des Activités économiques dans la Communauté Européenne” (NACE) is the European standard system for classifying industries. It applies four levels (sections - identified by letters A-U; divisions, groups, and classes - each identified by 2-, 3- or 4-digit numbers, respectively). An overview of all NACE classifications can be found on the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://ec.europa.eu/eurostat/ramon/nomenclatures/index.cfm?TargetUrl=LST_NOM_DTL&StrNom=NACE_REV2&StrLanguageCode=EN&IntPcKey=&StrLayoutCode=HIERARCHIC&IntCurrentPage=1",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              ' Eurostat "Reference And Management Of Nomenclatures" website '
                            ),
                          ]
                        ),
                        _vm._v(". "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "ssp" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          " What are the SSPs and how are they used for Baseline XDC calculation? "
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          ' The Shared Socioeconomic Pathways (SSPs) are a set of five scenarios that "describe plausible major global developments that together would lead in the future to different challenges for mitigation and adaptation to climate change." (Source: '
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://unece.org/fileadmin/DAM/energy/se/pdfs/CSE/PATHWAYS/2019/ws_Consult_14_15.May.2019/supp_doc/SSP2_Overview.pdf",
                              target: "_blank",
                            },
                          },
                          [_vm._v("UNECE")]
                        ),
                        _vm._v(
                          ') One of these scenarios, SSP2, is described as the "Middle of the Road" or "Current Trends Continue" pathway. It is used for our baseline projections of the company\'s GVA and emission development as well as for the global GVA development. '
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " Source: (2017) Riahi, K. / van Vuuren, D. et al. "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://doi.org/10.1016/j.gloenvcha.2016.05.009",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "The Shared Socioeconomic Pathways and their energy, land use, and greenhouse gas emissions implications: An overview"
                            ),
                          ]
                        ),
                        _vm._v(
                          ". In: Global Environmental Change, Volume 42, pp. 153-168. "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "whatAreTheScenarios" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          " What are the different scenario providers and how do I use them? "
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " You can choose which scenarios you prefer as a benchmark. The possible options are IEA, NGFS or OECM. For further information: "
                        ),
                        _c("ul", [
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://iea.blob.core.windows.net/assets/28f365da-f9cb-4a87-9992-566821924beb/NetZeroby2050-ARoadmapfortheGlobalEnergySector.pdf",
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " Net Zero by 2050 - A Roadmap for the Global Energy Sector "
                                ),
                              ]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.ngfs.net/sites/default/files/medias/documents/820184_ngfs_scenarios_final_version_v6.pdf",
                                  target: "_blank",
                                },
                              },
                              [_vm._v(" NGFS Scenarios ")]
                            ),
                          ]),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.unepfi.org/publications/aoapublication/sectoral-pathways-to-net-zero-emissions/",
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " Sectoral Pathways to Net-Zero Emissions – United Nations Environment – Finance Initiative (unepfi.org) "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "whatIfIdontHaveData" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          " What do I do if I do not have data until 2100? "
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " In the company data tab you choose, how you want to extend the data to 2100. There are the following options: "
                        ),
                        _c("ul", [
                          _c("li", [_vm._v("SSP Scenarios")]),
                          _c("li", [_vm._v("Constant EEI")]),
                          _c("li", [_vm._v("Constant Performance")]),
                          _c("li", [_vm._v("Constant Growth")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "emissionsScopesCounted" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          " How are emissions scopes counted? How do you deal with double-counting of emissions? "
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " Since emissions in Scopes 2 and 3 for one entity are also part of the Scope 1 emissions for another entity (e.g. the energy provider) and since other entities also create GVA with these emissions, there is a risk of double-counting. To account for this, the XDC calculations use 100% of the Scope 1 emissions, but only 50% each for Scopes 2 and 3. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " In the plots of the emissions pathways, all three scopes are shown at 100%. This is done to allow you to compare the pathways with the input data from the Company Data and Strategies tabs. reductions to make the transition to a <2°C future possible "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "noTempPathway" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          ' I received the error message "No Temperature Target Pathway found". What does this mean? '
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " This error message may appear for one of two reasons: "
                        ),
                        _c("ol", [
                          _c(
                            "li",
                            [
                              _vm._v(
                                " The entity is starting out with a very high EEI in the base year. In this case it may not be possible to reduce emissions sufficiently in the time remaining to reach the "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path: "/faq",
                                      query: { q: "accelerator" },
                                    },
                                  },
                                },
                                [_vm._v(" Accelerator Target Pathway ")]
                              ),
                              _vm._v(
                                " , even if future negative emissions are considered. This is because the Earth system responds slowly. Emissions generated earlier (even before the base year) have a stronger warming effect than emissions reductions at a later date can compensate for. Similarly, the cooling impact of removing emissions from the atmosphere is slow to take effect. For such an entity, the "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path: "/faq",
                                      query: { q: "frontrunner" },
                                    },
                                  },
                                },
                                [_vm._v(" Frontrunner Target Pathway ")]
                              ),
                              _vm._v(" provides a viable alternative. "),
                            ],
                            1
                          ),
                          _c("li", [
                            _vm._v(
                              " The pathway could not be calculated due to computational limitations on our side. If you believe that this is causing the error, please reach out to our "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:se-support@right-basedonscience.de",
                                  target: "_blank",
                                  text: "",
                                },
                              },
                              [_c("span", [_vm._v("support team")])]
                            ),
                            _vm._v(". "),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "newIn2-1" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" When am I paris-aligned? "),
                      ]),
                      _c("v-expansion-panel-content", [
                        _c("b", [_vm._v("Paris")]),
                        _vm._v(
                          " Alignment is dependent on the entity’s XDC being 1.74°C or below . "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "newIn2-1" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" What does XDC mean? "),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " How much global warming could we expect if the entire world exhibited the same "
                        ),
                        _c("b", [_vm._v("climate performance")]),
                        _vm._v(
                          " as the company, building or portfolio under consideration? "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "newIn2-1" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Which scenarios can I choose? "),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(" Selection of one mitigation scenario "),
                        _c("b", [_vm._v("provider")]),
                        _vm._v(
                          " (IEA, NGFS or OECM) with all target temperatures available from that provider integrated "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { ref: "terminology" },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Terminology")]),
                      _c("v-expansion-panel-content", [
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Acronym / Term")]),
                              _c("th", [_vm._v("Description")]),
                            ]),
                          ]),
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [_vm._v("2DS")]),
                              _c("td", [
                                _vm._v(
                                  ' The "2 Degrees Scenario" by the International Energy Agency (IEA), aiming at max. 2°C global warming above pre-industrial levels. '
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("B2DS")]),
                              _c("td", [
                                _vm._v(
                                  ' The "Beyond 2 Degrees Scenario" by the International Energy Agency (IEA), aiming at max. 1.75°C global warming above pre-industrial levels. '
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Baseline")]),
                              _c("td", [
                                _vm._v(
                                  " A scenario with no further climate policies (beyond those which are already firmly planned). "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Carbon budget")]),
                              _c("td", [
                                _vm._v(
                                  " Emissions budget is the cumulative emissions associated with a climate target (e.g. 2°C temperature target). It is the upper limit of total emissions for staying below a climate target. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("CO2e")]),
                              _c("td", [
                                _vm._v(
                                  " CO2 equivalents. A metric that enables the comparison between different greenhouse gases by converting them into a common unit (using global warming potential). "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("EBITDA")]),
                              _c("td", [
                                _vm._v(
                                  " Earnings before interest, taxes, depreciation, and amortization. An indicator used in financial accounting to measure a companies' profitability / “profits”. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("EEI")]),
                              _c("td", [
                                _vm._v(
                                  " Economic Emission Intensity: emissions divided by GVA. For our purposes this is always provided as tons of CO2e / million EUR. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Emissions budget")]),
                              _c("td", [_vm._v("Same as carbon budget.")]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("GDP")]),
                              _c("td", [
                                _vm._v(
                                  " Gross domestic product. Metric for the final value of produced goods and services in a specific country within one year. It is the sum of all sectors' GVA plus product taxes minus subsidies. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("GHG")]),
                              _c("td", [_vm._v("Greenhouse Gases.")]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Globally upscaled emissions")]),
                              _c("td", [
                                _vm._v(
                                  " The EEI of an entity multiplied by world GVA, giving the total emissions that would occur if all entities operated with the same EEI as this particular entity. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("GVA")]),
                              _c("td", [
                                _vm._v(
                                  " Gross value added. Metric for the value of produced goods and services in a certain area / sector. It is calculated as the value of the output minus the intermediate costs. On a company level, GVA can be estimated by the sum of personnel costs and EBITDA. However, this is not a standard metric in financial accounting. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("IEA")]),
                              _c("td", [
                                _vm._v("International Energy Agency."),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("IEA Curves")]),
                              _c("td", [
                                _vm._v(
                                  " The emission pathways published by the IEA outlining the tolerated quantity of emissions per sector to be Paris-compliant. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("IEA sector")]),
                              _c("td", [
                                _vm._v(
                                  "Sector definition used in the IEA’s projections."
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("NACE")]),
                              _c("td", [
                                _vm._v(" “"),
                                _c("b", [_vm._v("N")]),
                                _vm._v("omenclature statistique des "),
                                _c("b", [_vm._v("A")]),
                                _vm._v("ctivités économiques dans la "),
                                _c("b", [_vm._v("C")]),
                                _vm._v("ommunauté "),
                                _c("b", [_vm._v("E")]),
                                _vm._v(
                                  "uropéenne” = Statistical Classification of Economic Activities in the European Community. European standard system for classifying industries. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("NAICS")]),
                              _c("td", [
                                _vm._v(" “"),
                                _c("b", [_vm._v("N")]),
                                _vm._v("orth "),
                                _c("b", [_vm._v("A")]),
                                _vm._v("merican "),
                                _c("b", [_vm._v("I")]),
                                _vm._v("ndustry "),
                                _c("b", [_vm._v("C")]),
                                _vm._v("lassification "),
                                _c("b", [_vm._v("S")]),
                                _vm._v(
                                  "ystem”. A classification system for companies based on economic activity. Currently only used when ordering private company emission data. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Pathway")]),
                              _c("td", [
                                _vm._v(
                                  "A specified future progression of a variable."
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("PBE")]),
                              _c("td", [
                                _vm._v(
                                  " Production-based emissions is the standard measurement for country emissions, considers all emissions that have been “produced” within a country. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Personnel costs")]),
                              _c("td", [
                                _vm._v(
                                  " Companies' expenditures for their employees including taxes and social security contributions. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Portfolio")]),
                              _c("td", [
                                _vm._v(
                                  " A collection of assets held by a company, institution or a private individual (including e.g. stocks, sovereign bonds or corporate bonds). "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Projection")]),
                              _c("td", [
                                _vm._v(
                                  " The future progression of a variable resulting from a particular scenario. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Region")]),
                              _c("td", [
                                _vm._v(
                                  " Region is a selection criteria that refers to the region the company has its headquarters in. XDC Model 2.1 offers five regions: : OECD, Asia, Middle East & Africa, Latin America, and Reforming Economies. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Scenario")]),
                              _c("td", [
                                _vm._v(
                                  " A potential and plausible future development, generally consisting of one or more pathways. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Scope 1")]),
                              _c("td", [
                                _vm._v(
                                  " Direct Greenhouse Gas Emissions from sources that are owned or controlled by the reporting entity. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Scope 2")]),
                              _c("td", [
                                _vm._v(
                                  " Indirect Greenhouse Gas Emissions from the generation of purchased electricity, steam, and heating/cooling. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Scope 3")]),
                              _c("td", [
                                _vm._v(
                                  " Indirect Greenhouse Gas Emissions from the upstream and downstream value chain. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Sector")]),
                              _c("td", [
                                _vm._v(
                                  "A set of companies which have the same NACE code."
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Sector EEI")]),
                              _c("td", [
                                _vm._v(
                                  " The EEI which best represents a given sector (sector median). "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Self-reported data")]),
                              _c("td", [
                                _vm._v(
                                  " Data that are provided directly to us by a customer. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("SSPs")]),
                              _c("td", [
                                _vm._v(
                                  " Shared Socioeconomic Pathways. A set of 5 scenarios that outline alternative societal trends until 2100. One of them is the SSP2, referred to as the “Middle of the road” scenario as it continues historic trends. "
                                ),
                              ]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("XDC")]),
                              _c("td", [_vm._v("X-Degree Compatibility")]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }