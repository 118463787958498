import axios, { AxiosResponse } from "axios";

export default class Gateway {
  static get baseUrl(): string {
    throw new Error("Fatal: Set baseUrl for inherited Gateway!");
  }

  static async makePostRequest<RequestType, ResponseType>(
    path: string,
    requestPayload: RequestType,
    headers = {}
  ): Promise<AxiosResponse<ResponseType>> {
    return await axios.post(this.baseUrl + path, requestPayload, {
      headers: {
        "Content-Type": "application/json;  charset=utf-8",
        ...headers,
      },
    });
  }

  static async makeGetRequest<ResponseType>(
    path: string,
    queryParams: Record<string, string> = {},
    headers = {}
  ): Promise<AxiosResponse<ResponseType>> {
    const query = this.objectToQueryString(queryParams);

    return await axios.get(this.baseUrl + path + (query ? "?" + query : ""), {
      headers: {
        "Content-Type": "application/json;  charset=utf-8",
        ...headers,
      },
    });
  }

  static objectToQueryString(obj: Record<string, string>): string {
    return Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&");
  }
}
