import HighchartCurve from "@/models/highcharts/HighchartCurve";
import CurvePainter from "@/services/CurvePainter";
import { READY } from "@/constants/RequestStatus";

export default class HighchartCurveGroup {
  name: string;
  gvaPoints: HighchartCurve | undefined;
  totalEmissionPoints: HighchartCurve;
  scope1EmissionPoints: HighchartCurve | undefined;
  scope2EmissionPoints: HighchartCurve | undefined;
  scope3EmissionPoints: HighchartCurve | undefined;
  status: string;

  constructor(
    baseId: string,
    baseYear: number,
    name: string,
    gvaPoints: number[] | undefined = undefined,
    totalEmissionPoints: number[],
    scope1EmissionPoints: number[] | undefined = undefined,
    scope2EmissionPoints: number[] | undefined = undefined,
    scope3EmissionPoints: number[] | undefined = undefined,
    status: string
  ) {
    this.name = name;
    this.status = status;
    this.gvaPoints = gvaPoints
      ? new HighchartCurve(
          `${baseId}-${name}-GVA`,
          `${name} | GVA`,
          0,
          this.getDataPoints(gvaPoints, baseYear)
        )
      : undefined;
    this.totalEmissionPoints = new HighchartCurve(
      `${baseId}-${name}-TotalEmissions`,
      `${name} | Total Emissions`,
      1,
      this.getDataPoints(totalEmissionPoints, baseYear)
    );
    this.scope1EmissionPoints = scope1EmissionPoints
      ? new HighchartCurve(
          `${baseId}-${name}-Scope1`,
          `${name} | Scope1`,
          1,
          this.getDataPoints(scope1EmissionPoints, baseYear)
        )
      : undefined;
    this.scope2EmissionPoints = scope2EmissionPoints
      ? new HighchartCurve(
          `${baseId}-${name}-Scope2`,
          `${name} | Scope2`,
          1,
          this.getDataPoints(scope2EmissionPoints, baseYear)
        )
      : undefined;
    this.scope3EmissionPoints = scope3EmissionPoints
      ? new HighchartCurve(
          `${baseId}-${name}-Scope3`,
          `${name} | Scope3`,
          1,
          this.getDataPoints(scope3EmissionPoints, baseYear)
        )
      : undefined;
  }

  private getDataPoints(
    gvaPoints: number[],
    baseYear: number
  ): Record<string, unknown>[] {
    const basisPoint = gvaPoints[0];

    return gvaPoints.map((point, idx) => {
      return {
        x: baseYear + idx,
        y: point,
        percent: (point / basisPoint) * 100,
      };
    });
  }

  applyNewPaint(painter: CurvePainter): void {
    if (this.gvaPoints) {
      this.gvaPoints.color = painter.nextColor();
    }

    this.totalEmissionPoints.color = painter.nextColor();

    if (this.scope1EmissionPoints) {
      this.scope1EmissionPoints.color = painter.nextColor();
    }

    if (this.scope2EmissionPoints) {
      this.scope2EmissionPoints.color = painter.nextColor();
    }

    if (this.scope3EmissionPoints) {
      this.scope3EmissionPoints.color = painter.nextColor();
    }

    painter.finishedGroup();
  }

  updateCurvesChartLegend(activityName: string, strategyName: string): void {
    if (this.gvaPoints) {
      this.gvaPoints.updateName(
        `${activityName} | ${strategyName} | ${this.name} | GVA`
      );
    }

    this.totalEmissionPoints.updateName(
      `${activityName} | ${strategyName} | ${this.name} | Total Emissions`
    );

    if (this.scope1EmissionPoints) {
      this.scope1EmissionPoints.updateName(
        `${activityName} | ${strategyName} | ${this.name} | Scope 1`
      );
    }

    if (this.scope2EmissionPoints) {
      this.scope2EmissionPoints.updateName(
        `${activityName} | ${strategyName} | ${this.name} | Scope 2`
      );
    }

    if (this.scope3EmissionPoints) {
      this.scope3EmissionPoints.updateName(
        `${activityName} | ${strategyName} | ${this.name} | Scope 3`
      );
    }
  }

  get isReady(): boolean {
    return this.status === READY;
  }
}
