import ActivityStrategyData from "@/models/data-tab/ActivityStrategyData";

export default class ScenarioData {
  scenarioId: number;
  activityStrategyDataList: ActivityStrategyData[];

  constructor(scenarioId: number) {
    this.scenarioId = scenarioId;
    this.activityStrategyDataList = [];
  }

  addActivityStrategyData(data: ActivityStrategyData): void {
    this.activityStrategyDataList.push(data);
  }
}
