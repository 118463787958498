
import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/pages/main/components/Navigation.vue";
import AppUpdateAlert from "@/pages/main/components/AppUpdateAlert.vue";
import Activity from "@/models/activity/Activity";
import AppUpdateOverlay from "@/pages/main/components/AppUpdateOverlay.vue";

@Component({
  components: { AppUpdateOverlay, AppUpdateAlert, Navigation },
})
export default class App extends Vue {
  drawer = true;

  get showAlert(): true {
    return this.$store.state.sessionStore.showSavedAlert;
  }

  get showNaceMissingAlert(): boolean {
    return this.$store.state.sessionStore.showNaceMissingAlert;
  }

  get isNaceMissing(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  get isNegativeEbitda(): boolean {
    return this.$store.state.activities.some(
      (activity: Activity) => activity.gva.ebitda < 0
    );
  }

  get isAuthenticated(): boolean {
    return this.$store.state.auth.authenticated;
  }

  logout(): void {
    if (this.isAuthenticated) {
      this.$store.dispatch("auth/logout");
      this.$router.push("Login");
    }
  }

  mounted(): void {
    if (!this.isNaceMissing) {
      this.$store.commit("sessionStore/setShowNaceMissingAlert", false);
      this.$store.dispatch("makeXDCRequest", this.$store.state.activities);
    } else {
      this.$store.commit("sessionStore/setShowNaceMissingAlert", true);
    }

    this.$store.commit(
      "sessionStore/setNewSessionId",
      Vue.prototype.$uuid.v4()
    );
    this.$store.dispatch("sessionStore/loadAllSessions");

    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("configStore/loadConfig");
    }
  }
}
