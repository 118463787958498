
import { Component, Prop, Vue } from "vue-property-decorator";
import Activity from "../../../models/activity/Activity";
import ScenarioConfig from "@/models/ScenarioConfig";
import ActivityToStrategyRow from "@/pages/scenario/components/ActivityToStrategyRow.vue";

@Component({
  components: {
    ScenarioBlock,
    ActivityToStrategyRow,
  },
})
export default class ScenarioBlock extends Vue {
  @Prop({ required: true }) scenarioConfig!: ScenarioConfig;

  localScenarioName = this.scenarioConfig.name;

  showMenu = false;
  x = 0;
  y = 0;

  showContextMenu(e: MouseEvent): void {
    e.preventDefault();
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }

  get activities(): Activity[] {
    return this.$store.state.activities;
  }

  updateScenarioName(): void {
    this.scenarioConfig.name = this.localScenarioName;
  }

  deleteThisScenario(): void {
    this.$store.commit("removeScenario", this.scenarioConfig);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  copyThisScenario(): void {
    this.$store.commit("copyScenario", this.scenarioConfig);
    this.$store.commit("curvesNeedReload", true);
    this.saveSession();
  }

  saveSession(): void {
    if (this.$store.state.configStore.autoSave) {
      this.$store.dispatch("sessionStore/saveSession");
    }
  }
}
