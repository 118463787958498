var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    { attrs: { app: "", value: _vm.isVisible } },
    [
      _c(
        "v-list-item",
        [
          _c("v-img", {
            staticClass: "my-3",
            attrs: {
              src: require("../../../assets/logo_right_2024.svg"),
              contain: "",
              height: "40",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c("v-subheader", [_vm._v("Input")]),
          _c(
            "v-list-item",
            {
              attrs: {
                "data-testid": "company-data-nav-link",
                link: "",
                to: "company",
              },
            },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-domain")])], 1),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Company Data")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              attrs: {
                "data-testid": "strategies-nav-link",
                link: "",
                to: "strategy",
              },
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-strategy")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(" Strategies ")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "scenarios" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-notebook")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Scenarios")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-subheader", [_vm._v("Analytics")]),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "emissionsAndGva" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-chart-sankey")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Emissions & GVA")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "pathways" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-view-dashboard")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Pathways")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "xdcs" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-temperature-celsius")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("XDCs")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "data" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-file-table")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Data")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c("v-subheader", [_vm._v("Session")]),
          _c(
            "v-list-item",
            { attrs: { link: "" }, on: { click: _vm.confirmNew } },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-plus")])], 1),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("New")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "" }, on: { click: _vm.saveCurrentSession } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-content-save")])],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _vm._v(" Save "),
                      _vm.autoSaveOn
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                },
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" mdi-autorenew ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                865604971
                              ),
                            },
                            [_c("span", [_vm._v("Auto-Save is ON")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "sessions" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-folder-upload")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Load")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c("v-subheader", [_vm._v("Settings")]),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "preferences" } },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-cog")])], 1),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("Preferences")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { attrs: { link: "", to: "faq" } },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v("mdi-message-question")])],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v("FAQ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.showNewConfirmationDialog,
            callback: function ($$v) {
              _vm.showNewConfirmationDialog = $$v
            },
            expression: "showNewConfirmationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(" Are you sure you want a new Session? "),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " If you confirm you will loose all unsaved Data on the current session! "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showNewConfirmationDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.newSession },
                    },
                    [_vm._v(" NEW ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }