
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ERROR,
  LOADING,
  READY,
  SERVER_NOT_AVAILABLE,
} from "@/constants/RequestStatus";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { VueNumeric },
})
export default class XDCValueDisplay extends Vue {
  @Prop({ required: true }) xdcValue!: number;
  @Prop({ required: false, default: READY }) status!: string;

  LOADING = LOADING;
  ERROR = ERROR;
  SERVER_NOT_AVAILABLE = SERVER_NOT_AVAILABLE;

  get isNaceMissing(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }

  reload(): void {
    if (!this.isNaceMissing) {
      this.$store.dispatch("makeXDCRequest", this.$store.state.activities);
    }
  }
}
