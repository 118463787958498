var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h5" }, [_vm._v("Preferences")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h6" }, [_vm._v("Saving")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-switch", {
                attrs: {
                  inset: "",
                  label: `Auto-save is ${_vm.autoSave ? "ON" : "OFF"}`,
                },
                model: {
                  value: _vm.autoSave,
                  callback: function ($$v) {
                    _vm.autoSave = $$v
                  },
                  expression: "autoSave",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " Please note that by turning this feature ON your data will be stored on the server. The data will be fully "
              ),
              _c("strong", [_vm._v("encrypted")]),
              _vm._v(". "),
            ]),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "settings-divider" }),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v("Locale Preferences"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "" },
                  model: {
                    value: _vm.selectedLocale,
                    callback: function ($$v) {
                      _vm.selectedLocale = $$v
                    },
                    expression: "selectedLocale",
                  },
                },
                _vm._l(_vm.allAvailableLocales, function (locale) {
                  return _c("v-radio", {
                    key: locale.shortCode,
                    attrs: { label: locale.displayName, value: locale },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v("The current Locale formats numbers to:"),
            ]),
            _c(
              "b",
              [
                _c("vue-numeric", {
                  attrs: {
                    value:
                      _vm.decimalSeparator === "."
                        ? "10500999.12"
                        : "10500999,12",
                    "read-only": "",
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    precision: 2,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-footer",
        { attrs: { padless: "", absolute: "" } },
        [
          _c("v-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
            _vm._v(
              " Powered by the XDC Engine " + _vm._s(_vm.engineVersion) + " — "
            ),
            _c("strong", [_vm._v("right. based on science")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }