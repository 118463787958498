var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { staticClass: "d-inline-flex justify-center" },
        [
          _c("div", { staticClass: "activity-name" }, [
            _vm._v(_vm._s(_vm.activity.name)),
          ]),
          _c("v-icon", { staticClass: "arrow" }, [_vm._v(" mdi-arrow-right")]),
          _c(
            "div",
            { staticClass: "strategy-choice" },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.activity.strategies,
                  "item-text": "name",
                  "return-object": "",
                  label: "Strategy",
                  dense: "",
                  disabled: _vm.readOnly,
                },
                model: {
                  value: _vm.initialStrategy,
                  callback: function ($$v) {
                    _vm.initialStrategy = $$v
                  },
                  expression: "initialStrategy",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }