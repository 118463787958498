import Subscope from "@/models/Subscope";

export const PURCHASED_ELECTRICITY = new Subscope(
  "2.1",
  "Purchased Electricity"
);
export const PURCHASED_HEATING_AND_COOLING = new Subscope(
  "2.2",
  "Purchased Heating And Cooling"
);
export const PURCHASED_STEAM = new Subscope("2.3", "Purchased Steam");

export const ALL_SUBSCOPE2 = [
  PURCHASED_ELECTRICITY,
  PURCHASED_HEATING_AND_COOLING,
  PURCHASED_STEAM,
];
