var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-overlay",
    { attrs: { absolute: true, value: _vm.isShown } },
    [
      _c(
        "v-card",
        { attrs: { light: "" } },
        [
          _c("v-card-title", [_vm._v("App Updated")]),
          _c("v-card-text", [
            _vm._v(
              " The Scenario Explorer got an update. It's now running on version " +
                _vm._s(_vm.appVersion) +
                "."
            ),
            _c("br"),
            _vm._v(" Please find the release here: "),
            _c(
              "a",
              {
                attrs: {
                  href: "http://5087718.hs-sites.com/anstehendes-release-am-27.-juni-/-xdc-modell-version-3.4",
                  target: "_blank",
                },
              },
              [_vm._v(" Release Note ")]
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.close },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }