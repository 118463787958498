
import { Component, Vue } from "vue-property-decorator";
import AuthGateway from "@/auth/services/AuthGateway";
import { UpdatePasswordRoute } from "@/router/Routes";
import router from "@/router";

@Component
export default class ChangePasswordPage extends Vue {
  newPassword = "";
  repeatPassword = "";
  isFormValid = false;
  message = "";

  get isUpdatePasswordRoute(): boolean {
    return router.currentRoute.path === UpdatePasswordRoute.path;
  }

  get hasMessage(): boolean {
    return this.message.length > 0;
  }

  isPasswordNotEmpty(): boolean | string {
    return this.newPassword !== "" || "This field must be filled";
  }

  isRepeatPasswordNotEmpty(): boolean | string {
    return this.repeatPassword !== "" || "This field must be filled";
  }

  arePasswordsEqual(): boolean | string {
    return (
      this.newPassword === this.repeatPassword || "Passwords do not match!"
    );
  }

  isPasswordAboveMinimumLength(): boolean | string {
    return this.newPassword.length >= 8 || "You need at least 8 characters";
  }

  isPasswordBelowMaximumLength(): boolean | string {
    return this.newPassword.length <= 256 || "Maximum length is 256 characters";
  }

  isUppercaseIncluded(): boolean | string {
    return (
      /[A-Z]/.test(this.newPassword) ||
      "Password needs to contain at least one upper case letter"
    );
  }

  isLowercaseIncluded(): boolean | string {
    return (
      /[a-z]/.test(this.newPassword) ||
      "Password needs to contain at least one lower case letter"
    );
  }

  isNumberIncluded(): boolean | string {
    return (
      /\d/.test(this.newPassword) ||
      "Password needs to contain at least one number"
    );
  }

  isNonAlphaNumericCharacterIncluded(): boolean | string {
    return (
      !/^[a-zA-Z0-9]+$/.test(this.newPassword) ||
      "Password needs to contain at least one non-alphanumeric character"
    );
  }

  formOk(): void {
    this.message = "";
    const allValid = (
      this.$refs.form as Vue & {
        validate: () => boolean;
      }
    ).validate();

    if (allValid) {
      this.setNewPassword();
    }
  }

  setNewPassword(): void {
    const cpID = this.$route.query.cpid;

    if (typeof cpID === "string") {
      AuthGateway.changePassword(this.newPassword, cpID as string);
    } else {
      this.message =
        "There is a problem with the URL. Please re-start the process";
    }
  }
}
