import SubscopeEmissions from "@/models/activity/SubscopeEmissions";
import Subscope from "@/models/Subscope";

export default class ScopeEmissions {
  // [SubScopeFeatureToggle] either re-enable or remove subscopes and rename other to total
  other: number;
  subscopes: Array<SubscopeEmissions>;

  constructor(otherEmissions: number, subscopes: Array<SubscopeEmissions>) {
    this.other = otherEmissions;
    this.subscopes = subscopes;
  }

  // [SubScopeFeatureToggle] remove if subScopes not re-enabled
  addSubscope(scope: Subscope): void {
    const existingIndex = this.subscopes.findIndex((s) => s.id === scope.id);

    if (existingIndex === -1) {
      this.subscopes.push(new SubscopeEmissions(scope.id, scope.name, 0));
    }
  }

  // [SubScopeFeatureToggle] remove if subScopes not re-enabled
  removeSubscope(scope: Subscope): void {
    this.subscopes = this.subscopes.filter((s) => s.id !== scope.id);
  }

  // [SubScopeFeatureToggle] remove if subScopes not re-enabled
  getSubscopeValue(scopeId: string): number {
    const subscope = this.subscopes.find((s) => s.id === scopeId);

    return subscope ? subscope.value : 0;
  }

  // [SubScopeFeatureToggle] remove if subScopes not re-enabled, where used 'total' should be used instead
  sumTotal(): number {
    let sum = 0;
    this.subscopes.forEach((emission) => (sum += Number(emission.value)));

    return Number(sum) + Number(this.other);
  }
}
