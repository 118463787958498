var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { vertical: "", timeout: "-1", right: "", light: "" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.close } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close")]
              ),
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { text: "", color: "primary", dark: "" },
                    on: { click: _vm.reloadPage },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Refresh now! ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showAppUpdateSnackbar,
        callback: function ($$v) {
          _vm.showAppUpdateSnackbar = $$v
        },
        expression: "showAppUpdateSnackbar",
      },
    },
    [
      _c("h3", { staticClass: "green--text" }, [
        _vm._v("Software Update Available!"),
      ]),
      _c("p", [
        _vm._v(" Click refresh now to be up to date."),
        _c("br"),
        _vm._v(" Remember to save your current session before refreshing. "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }