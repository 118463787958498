import HighchartCurveGroup from "@/models/highcharts/HighchartCurveGroup";

export default class ActivityStrategyData {
  activityId: number;
  strategyId: number;
  yearRange: number[];
  gva: number[];
  totalEmissions: number[];
  scope1: number[];
  scope2: number[];
  scope3: number[];

  constructor(
    activityId: number,
    strategyId: number,
    curve: HighchartCurveGroup
  ) {
    this.activityId = activityId;
    this.strategyId = strategyId;
    this.yearRange = curve.gvaPoints?.data.map((point: any) => point.x);
    this.gva = curve.gvaPoints?.data.map((point: any) => point.y);
    this.totalEmissions = curve.totalEmissionPoints.data.map(
      (point: any) => point.y
    );
    this.scope1 = curve.scope1EmissionPoints?.data.map((point: any) => point.y);
    this.scope2 = curve.scope2EmissionPoints?.data.map((point: any) => point.y);
    this.scope3 = curve.scope3EmissionPoints?.data.map((point: any) => point.y);
  }
}
