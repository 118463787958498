var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-speed-dial",
    {
      attrs: {
        bottom: true,
        right: true,
        direction: "top",
        transition: "slide-y-reverse-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "primary",
                                    dark: "",
                                    fab: "",
                                  },
                                  model: {
                                    value: _vm.showFab,
                                    callback: function ($$v) {
                                      _vm.showFab = $$v
                                    },
                                    expression: "showFab",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm.showFab
                                ? _c("v-icon", [_vm._v(" mdi-close")])
                                : _c("v-icon", [_vm._v(" mdi-download")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Export this Scenario Data")])]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showFab,
        callback: function ($$v) {
          _vm.showFab = $$v
        },
        expression: "showFab",
      },
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "download-excel",
                    {
                      attrs: {
                        type: "csv",
                        name: `${_vm.scenarioName}.csv`,
                        fetch: _vm.exportData,
                        escapeCsv: false,
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                fab: "",
                                dark: "",
                                small: "",
                                color: "indigo",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-file-delimited")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Export as CSV")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }