
import { Component, Prop, Vue } from "vue-property-decorator";
import ActivityStrategyData from "@/models/data-tab/ActivityStrategyData";

@Component
export default class DataExportFab extends Vue {
  @Prop({ required: true }) scenarioName!: string;
  @Prop({ required: true }) activityStrategyData!: ActivityStrategyData[];

  showFab = false;

  exportData = (): Record<string, any>[] => {
    const data = this.activityStrategyData;

    const exportList: Record<string, any>[] = [];

    const listSize = data[0].yearRange.length;

    for (let idx = 0; idx < listSize; idx++) {
      const exportData: Record<string, any> = {};

      exportData["Year"] = data[0].yearRange[idx];
      data.forEach((activityStrategyData) => {
        const activityName = this.activityName(activityStrategyData.activityId);
        const strategyName = this.strategyName(
          activityStrategyData.activityId,
          activityStrategyData.strategyId
        );

        exportData[`${activityStrategyData.activityId} - Activity Name`] =
          activityName;
        exportData[`${activityStrategyData.activityId} - Strategy Name`] =
          strategyName;
        exportData[`${activityStrategyData.activityId} - GVA`] =
          activityStrategyData.gva[idx];
        exportData[`${activityStrategyData.activityId} - Total Emissions`] =
          activityStrategyData.totalEmissions[idx];
        exportData[`${activityStrategyData.activityId} - Scope1`] =
          activityStrategyData.scope1[idx];
        exportData[`${activityStrategyData.activityId} - Scope2`] =
          activityStrategyData.scope2[idx];
        exportData[`${activityStrategyData.activityId} - Scope3`] =
          activityStrategyData.scope3[idx];
        exportData[" ".repeat(activityStrategyData.activityId)] = " ";
        exportData[" ".repeat(activityStrategyData.activityId + 1)] = "  ";
      });
      exportList.push(exportData);
    }

    return exportList;
  };

  activityName(id: number): string {
    return this.$store.getters.activityName(id);
  }

  strategyName(activityId: number, strategyId: number): string {
    return this.$store.getters.strategyName(activityId, strategyId);
  }
}
