
import { Component, Prop, Vue } from "vue-property-decorator";
import ActivityStrategyData from "@/models/data-tab/ActivityStrategyData";
import VueNumeric from "@/pages/shared/components/VueNumeric.vue";

@Component({
  components: { VueNumeric },
})
export default class ActivityStrategyComboData extends Vue {
  @Prop({ required: true }) activityStrategyData!: ActivityStrategyData[];

  selectedCombo = null;

  activityName(id: number): string {
    return this.$store.getters.activityName(id);
  }

  strategyName(activityId: number, strategyId: number): string {
    return this.$store.getters.strategyName(activityId, strategyId);
  }

  get decimalSeparator(): string {
    return this.$store.state.configStore.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.$store.state.configStore.separator;
  }
}
