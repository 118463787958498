import Activity from "@/models/activity/Activity";
import Strategy from "@/models/strategy/Strategy";
import GrowthProjection from "@/models/strategy/GrowthProjection";

export default class ActivityStrategyCombo {
  initialEbitda: number;
  initialPersonnelCost: number;
  initialScope1: number;
  initialScope2: number;
  initialScope3: number;
  // [SubScopeFeatureToggle] should SubScopes be re-enabled GrowthProjection has to be modified
  growthProjections: GrowthProjection[];

  constructor(activity: Activity, strategy: Strategy) {
    this.initialEbitda = activity.gva.ebitda;
    this.initialPersonnelCost = activity.gva.personnelCosts;

    this.initialScope1 = activity.scope1.other;
    this.initialScope2 = activity.scope2.other;
    this.initialScope3 = activity.scope3.other;
    this.growthProjections = strategy.growthProjections;
  }
}
