var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "tr",
    { attrs: { "data-testid": "strategy-info-table-range-row" } },
    [
      _vm.isBaseline
        ? [
            _c("td", { staticClass: "input-column" }),
            _c("td", [_vm._v(_vm._s(_vm.startYear))]),
            _c("td", [_vm._v(_vm._s(_vm.targetYear))]),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("v-text-field", {
                  staticClass: "center-input",
                  attrs: { value: _vm.ssp, disabled: "" },
                }),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "input-column" },
              [
                _c("v-text-field", {
                  staticClass: "center-input",
                  attrs: { value: _vm.ssp, disabled: "" },
                }),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("v-text-field", {
                  staticClass: "center-input",
                  attrs: { value: _vm.ssp, disabled: "" },
                }),
              ],
              1
            ),
            _vm._l(_vm.scope1SubscopeRates(0), function (subscope) {
              return _c(
                "td",
                { key: subscope.id, staticClass: "input-column" },
                [
                  _c("v-text-field", {
                    staticClass: "center-input",
                    attrs: { value: _vm.ssp, disabled: "" },
                  }),
                ],
                1
              )
            }),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("v-text-field", {
                  staticClass: "center-input",
                  attrs: { value: _vm.ssp, disabled: "" },
                }),
              ],
              1
            ),
            _vm._l(_vm.scope2SubscopeRates(0), function (subscope) {
              return _c(
                "td",
                { key: subscope.id, staticClass: "input-column" },
                [
                  _c("v-text-field", {
                    staticClass: "center-input",
                    attrs: { value: _vm.ssp, disabled: "" },
                  }),
                ],
                1
              )
            }),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("v-text-field", {
                  staticClass: "center-input",
                  attrs: { value: _vm.ssp, disabled: "" },
                }),
              ],
              1
            ),
            _vm._l(_vm.scope3SubscopeRates(0), function (subscope) {
              return _c(
                "td",
                { key: subscope.id, staticClass: "input-column" },
                [
                  _c("v-text-field", {
                    staticClass: "center-input",
                    attrs: { value: _vm.ssp, disabled: "" },
                  }),
                ],
                1
              )
            }),
            _c("td", { staticClass: "border-left xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
          ]
        : [
            _c("td", { staticClass: "input-column" }),
            _c("td", [
              _vm._v(_vm._s(_vm.strategy.yearRanges[_vm.rangeIndex].startYear)),
            ]),
            _c("td", [
              _vm._v(_vm._s(_vm.strategy.yearRanges[_vm.rangeIndex].endYear)),
            ]),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    currency: _vm.isPercentGrowth ? "%" : "",
                    "currency-symbol-position": "suffix",
                    precision: _vm.isPercentGrowth ? 2 : 0,
                    minus: true,
                  },
                  on: {
                    blur: _vm.recalculateXDC,
                    focus: _vm.beforeRecalculateXDC,
                  },
                  model: {
                    value: _vm.ebitdaRate,
                    callback: function ($$v) {
                      _vm.ebitdaRate = $$v
                    },
                    expression: "ebitdaRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "input-column" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    currency: _vm.isPercentGrowth ? "%" : "",
                    "currency-symbol-position": "suffix",
                    precision: _vm.isPercentGrowth ? 2 : 0,
                    minus: true,
                  },
                  on: {
                    blur: _vm.recalculateXDC,
                    focus: _vm.beforeRecalculateXDC,
                  },
                  model: {
                    value: _vm.personnelCostsRate,
                    callback: function ($$v) {
                      _vm.personnelCostsRate = $$v
                    },
                    expression: "personnelCostsRate",
                  },
                }),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    currency: _vm.isPercentGrowth ? "%" : "",
                    "currency-symbol-position": "suffix",
                    precision: _vm.isPercentGrowth ? 2 : 0,
                    minus: true,
                  },
                  on: {
                    blur: _vm.recalculateXDC,
                    focus: _vm.beforeRecalculateXDC,
                  },
                  model: {
                    value: _vm.scope1OtherRate,
                    callback: function ($$v) {
                      _vm.scope1OtherRate = $$v
                    },
                    expression: "scope1OtherRate",
                  },
                }),
              ],
              1
            ),
            _vm._l(
              _vm.scope1SubscopeRates(_vm.rangeIndex),
              function (subscope) {
                return _c(
                  "td",
                  { key: subscope.id, staticClass: "input-column" },
                  [
                    _c("SubScopeRateField", {
                      attrs: {
                        "is-percent-growth": _vm.isPercentGrowth,
                        "subscope-rate": subscope,
                        "number-of-years": _vm.numberOfYears,
                      },
                      on: {
                        blur: _vm.recalculateXDC,
                        focus: _vm.beforeRecalculateXDC,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    currency: _vm.isPercentGrowth ? "%" : "",
                    "currency-symbol-position": "suffix",
                    precision: _vm.isPercentGrowth ? 2 : 0,
                    minus: true,
                  },
                  on: {
                    blur: _vm.recalculateXDC,
                    focus: _vm.beforeRecalculateXDC,
                  },
                  model: {
                    value: _vm.scope2OtherRate,
                    callback: function ($$v) {
                      _vm.scope2OtherRate = $$v
                    },
                    expression: "scope2OtherRate",
                  },
                }),
              ],
              1
            ),
            _vm._l(
              _vm.scope2SubscopeRates(_vm.rangeIndex),
              function (subscope) {
                return _c(
                  "td",
                  { key: subscope.id, staticClass: "input-column" },
                  [
                    _c("SubScopeRateField", {
                      attrs: {
                        "is-percent-growth": _vm.isPercentGrowth,
                        "subscope-rate": subscope,
                        "number-of-years": _vm.numberOfYears,
                      },
                      on: {
                        blur: _vm.recalculateXDC,
                        focus: _vm.beforeRecalculateXDC,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            _c(
              "td",
              { staticClass: "border-left input-column" },
              [
                _c("vue-numeric", {
                  staticClass: "number-input",
                  attrs: {
                    "decimal-separator": _vm.decimalSeparator,
                    separator: _vm.thousandSeparator,
                    currency: _vm.isPercentGrowth ? "%" : "",
                    "currency-symbol-position": "suffix",
                    precision: _vm.isPercentGrowth ? 2 : 0,
                    minus: true,
                  },
                  on: {
                    blur: _vm.recalculateXDC,
                    focus: _vm.beforeRecalculateXDC,
                  },
                  model: {
                    value: _vm.scope3OtherRate,
                    callback: function ($$v) {
                      _vm.scope3OtherRate = $$v
                    },
                    expression: "scope3OtherRate",
                  },
                }),
              ],
              1
            ),
            _vm._l(
              _vm.scope3SubscopeRates(_vm.rangeIndex),
              function (subscope) {
                return _c(
                  "td",
                  { key: subscope.id, staticClass: "input-column" },
                  [
                    _c("SubScopeRateField", {
                      attrs: {
                        "is-percent-growth": _vm.isPercentGrowth,
                        "subscope-rate": subscope,
                        "number-of-years": _vm.numberOfYears,
                      },
                      on: {
                        blur: _vm.recalculateXDC,
                        focus: _vm.beforeRecalculateXDC,
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            _c("td", { staticClass: "border-left xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
            _c("td", { staticClass: "xdc-number-column" }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }