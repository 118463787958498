var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "tr",
    { staticClass: "hoverable", on: { contextmenu: _vm.showContextMenu } },
    [
      _c(
        "td",
        { staticClass: "input-column", attrs: { colspan: "2" } },
        [
          _c("v-text-field", {
            model: {
              value: _vm.activity.name,
              callback: function ($$v) {
                _vm.$set(_vm.activity, "name", $$v)
              },
              expression: "activity.name",
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "border-left input-column" },
        [
          _c("NumericField", {
            attrs: { value: _vm.activity.gva.ebitda },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.activity.gva, "ebitda", $event)
              },
              blur: _vm.recalculateXDC,
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "input-column" },
        [
          _c("NumericField", {
            attrs: { value: _vm.activity.gva.personnelCosts },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.activity.gva, "personnelCosts", $event)
              },
              blur: _vm.recalculateXDC,
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "border-left input-column" },
        [
          _c("NumericField", {
            attrs: { value: _vm.activity.scope1.other },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.activity.scope1, "other", $event)
              },
              blur: _vm.recalculateXDC,
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.scope1Subscopes, function (subscope) {
        return _c(
          "td",
          {
            key: `${_vm.activity.id}-${subscope.id}-act-info1-row`,
            staticClass: "input-column",
          },
          [
            _c("NumericField", {
              attrs: { value: subscope.value },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(subscope, "value", $event)
                },
                blur: _vm.recalculateXDC,
              },
            }),
          ],
          1
        )
      }),
      _c(
        "td",
        { staticClass: "border-left input-column" },
        [
          _c("NumericField", {
            attrs: { value: _vm.activity.scope2.other },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.activity.scope2, "other", $event)
              },
              blur: _vm.recalculateXDC,
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.scope2Subscopes, function (subscope) {
        return _c(
          "td",
          {
            key: `${_vm.activity.id}-${subscope.id}-act-info2-row`,
            staticClass: "input-column",
          },
          [
            _c("NumericField", {
              attrs: { value: subscope.value },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(subscope, "value", $event)
                },
                blur: _vm.recalculateXDC,
              },
            }),
          ],
          1
        )
      }),
      _c(
        "td",
        { staticClass: "border-left input-column" },
        [
          _c("NumericField", {
            attrs: { value: _vm.activity.scope3.other },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.activity.scope3, "other", $event)
              },
              blur: _vm.recalculateXDC,
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.scope3Subscopes, function (subscope) {
        return _c(
          "td",
          {
            key: `${_vm.activity.id}-${subscope.id}-act-info3-row`,
            staticClass: "input-column",
          },
          [
            _c("NumericField", {
              attrs: { value: subscope.value },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(subscope, "value", $event)
                },
                blur: _vm.recalculateXDC,
              },
            }),
          ],
          1
        )
      }),
      _c(
        "td",
        { staticClass: "border-left xdc-number-column" },
        [
          _c("XDCValueDisplay", {
            attrs: {
              "xdc-value": _vm.activity.baselineXDC.xdcTotal,
              status: _vm.activity.baselineXDC.status,
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "xdc-number-column" },
        [
          _c("XDCValueDisplay", {
            attrs: {
              "xdc-value": _vm.activity.baselineXDC.xdcScope1,
              status: _vm.activity.baselineXDC.status,
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "xdc-number-column" },
        [
          _c("XDCValueDisplay", {
            attrs: {
              "xdc-value": _vm.activity.baselineXDC.xdcScope2,
              status: _vm.activity.baselineXDC.status,
            },
          }),
        ],
        1
      ),
      _c(
        "td",
        { staticClass: "xdc-number-column" },
        [
          _c("XDCValueDisplay", {
            attrs: {
              "xdc-value": _vm.activity.baselineXDC.xdcScope3,
              status: _vm.activity.baselineXDC.status,
            },
          }),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.x,
            "position-y": _vm.y,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.copyThisActivity } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-content-copy")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Copy Activity")]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.deleteThisActivity } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-delete")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Delete Activity")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }