var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.selectedCombo,
                callback: function ($$v) {
                  _vm.selectedCombo = $$v
                },
                expression: "selectedCombo",
              },
            },
            _vm._l(_vm.activityStrategyData, function (combo) {
              return _c(
                "v-tab",
                { key: `${combo.activityId}${combo.strategyId}-combo` },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.activityName(combo.activityId)) +
                      " - " +
                      _vm._s(
                        _vm.strategyName(combo.activityId, combo.strategyId)
                      ) +
                      " "
                  ),
                ]
              )
            }),
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.selectedCombo,
                callback: function ($$v) {
                  _vm.selectedCombo = $$v
                },
                expression: "selectedCombo",
              },
            },
            _vm._l(_vm.activityStrategyData, function (combo) {
              return _c(
                "v-tab-item",
                { key: `${combo.activityId}${combo.strategyId}-combo-data` },
                [
                  _c("v-simple-table", {
                    attrs: {
                      dense: "",
                      "fixed-header": "",
                      id: "scenario-data-table",
                      height: "calc(100vh - 275px)",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v("Year"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("GVA"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("Total Emissions"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("Scope1 Emissions"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("Scope2 Emissions"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("Scope3 Emissions"),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(combo.yearRange, function (year, idx) {
                                  return _c("tr", { key: year }, [
                                    _c("td", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(year)),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _c("vue-numeric", {
                                          attrs: {
                                            value: combo.gva[idx],
                                            "read-only": "",
                                            "decimal-separator":
                                              _vm.decimalSeparator,
                                            separator: _vm.thousandSeparator,
                                            precision: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("vue-numeric", {
                                          attrs: {
                                            value: combo.totalEmissions[idx],
                                            "read-only": "",
                                            "decimal-separator":
                                              _vm.decimalSeparator,
                                            separator: _vm.thousandSeparator,
                                            precision: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("vue-numeric", {
                                          attrs: {
                                            value: combo.scope1[idx],
                                            "read-only": "",
                                            "decimal-separator":
                                              _vm.decimalSeparator,
                                            separator: _vm.thousandSeparator,
                                            precision: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("vue-numeric", {
                                          attrs: {
                                            value: combo.scope2[idx],
                                            "read-only": "",
                                            "decimal-separator":
                                              _vm.decimalSeparator,
                                            separator: _vm.thousandSeparator,
                                            precision: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c("vue-numeric", {
                                          attrs: {
                                            value: combo.scope3[idx],
                                            "read-only": "",
                                            "decimal-separator":
                                              _vm.decimalSeparator,
                                            separator: _vm.thousandSeparator,
                                            precision: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }