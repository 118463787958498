import HighchartCurve from "@/models/highcharts/HighchartCurve";
import CurvePainter from "@/services/CurvePainter";
import NACESector from "@/models/NACESector";
import { READY } from "@/constants/RequestStatus";

export default class HighchartEeiCurveWrapper {
  name: string;
  naceSector: NACESector;
  parentActivityName: string;
  parentId: number;
  eeiPathway: HighchartCurve;
  isSectorCurve: boolean;
  isCompanyCurve: boolean;
  isActivityCurve: boolean;
  isScenarioCurve: boolean;
  status: string;

  constructor(
    uniqueId: string,
    parentId: number,
    naceSector: NACESector,
    parentActivityName = "",
    baseYear: number,
    name: string,
    eeiPathway: number[],
    status: string,
    isSectorCurve = false,
    isCompanyCurve = false,
    isActivityCurve = false,
    isScenarioCurve = false
  ) {
    this.name = name;
    this.naceSector = naceSector;
    this.parentActivityName = parentActivityName;
    this.parentId = parentId;
    this.status = status;
    this.isSectorCurve = isSectorCurve;
    this.isCompanyCurve = isCompanyCurve;
    this.isActivityCurve = isActivityCurve;
    this.isScenarioCurve = isScenarioCurve;

    const curveDisplayName = this.getCurveName();

    this.eeiPathway = new HighchartCurve(
      uniqueId,
      curveDisplayName,
      0,
      eeiPathway.map((point, idx) => [baseYear + idx, point])
    );
  }

  private getCurveName(): string {
    if (this.isSectorCurve) {
      return `NACE ${this.naceSector.code} | ${this.name}`;
    } else if (this.isScenarioCurve) {
      return `Scenario | ${this.name}`;
    } else if (this.isActivityCurve) {
      return `${this.parentActivityName} | ${this.name}`;
    }

    return this.name;
  }

  applyNewPaint(painter: CurvePainter): void {
    this.eeiPathway.color = painter.nextColor();
  }

  get isReady(): boolean {
    return this.status === READY;
  }
}
