import ScopeEmissions from "@/models/activity/ScopeEmissions";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import Gva from "@/models/activity/Gva";
import Strategy from "@/models/strategy/Strategy";

export default class Activity {
  id: number;
  name: string;
  gva: Gva;
  scope1: ScopeEmissions;
  scope2: ScopeEmissions;
  scope3: ScopeEmissions;
  baselineXDC: BaselineXDC;
  strategies: Array<Strategy>;

  constructor(
    id: number,
    name: string,
    gva: Gva,
    scope1: ScopeEmissions,
    scope2: ScopeEmissions,
    scope3: ScopeEmissions,
    strategies: Array<Strategy>
  ) {
    this.id = id;
    this.name = name;
    this.gva = gva;
    this.scope1 = scope1;
    this.scope2 = scope2;
    this.scope3 = scope3;
    this.baselineXDC = new BaselineXDC();
    this.strategies = strategies;
  }

  setNewBaselineXDC(baselineXDC: BaselineXDC): void {
    this.baselineXDC = baselineXDC;
    this.strategies[0].baselineXDC = baselineXDC;
  }
}
