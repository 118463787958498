
import { Component, Vue } from "vue-property-decorator";
import AuthGateway from "@/auth/services/AuthGateway";
import { mixins } from "vue-class-component";
import CheckEmailMixin from "@/auth/components/CheckEmailMixin";

@Component
export default class ForgotPasswordPage extends mixins(Vue, CheckEmailMixin) {
  message = "";
  valid = true;
  isMailSent = false;

  get hasMessage(): boolean {
    return this.message.length > 0;
  }

  formOk(): void {
    const allValid = (
      this.$refs.form as Vue & {
        validate: () => boolean;
      }
    ).validate();

    if (allValid) {
      this.startProcess();
    }
  }

  async startProcess(): Promise<void> {
    await AuthGateway.forgotPassword(this.email);
    this.message =
      "Password reset started. " +
      "Please check your email mailbox for further instructions.";
    this.isMailSent = true;
  }
}
